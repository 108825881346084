<div class="navbar">
  <img *ngIf="showLogo" src="../../../../favicon.ico">
  <div class="content">
    <div class="navigation">
      <ng-container *ngFor="let nav of navigation">
        <!-- Regular navigation -->
        <div class="fade-in" *ngIf="!nav.children">
          <a [ngClass]="nav.highlight ? 'highlight' : ''" (click)="navigate(nav.url)">
            <mat-icon *ngIf="nav.icon">{{nav.icon}}</mat-icon>
            {{nav.title | translate}}
          </a>
        </div>

        <!-- Drop down navigation -->
        <ng-container *ngIf="nav.children">
          <ng-container *ngTemplateOutlet="subMenu; context:{nav: nav}"></ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="settings">

      <div class="profile">
        <button mat-button *ngIf="(user$ | async); let user" [matMenuTriggerFor]="profile">
          <mat-icon>person</mat-icon>{{user.FirstName | titlecase}}
        </button>
        <mat-menu #profile="matMenu">
            <button mat-menu-item routerLink="iota/profile" routerLinkActive="active"><mat-icon>person</mat-icon>{{'Profile' | translate}}</button>
            <button mat-menu-item (click)="logout();"><mat-icon>logout</mat-icon>{{'Logout' | translate }}</button>
        </mat-menu>
      </div>

      <div class="language">
        <button mat-button [matMenuTriggerFor]="language">
          <mat-icon>language</mat-icon>{{_translate.currentLang | uppercase}}
        </button>
        <mat-menu #language="matMenu">
            <button mat-menu-item (click)="changeLang('en');">{{'English' | translate}}</button>
            <button mat-menu-item (click)="changeLang('sr');">{{'Serbian' | translate}}</button>
            <button mat-menu-item (click)="changeLang('es');">{{'Spanish' | translate }}</button>
        </mat-menu>
      </div>
    </div>

  </div>
</div>

<div class="progress fade-in" *ngIf="(loading$ | async)">
  <mat-progress-bar mode="indeterminate" color="#fff"></mat-progress-bar>
</div>

<!-- TODO: Add sub menu's for deeper levels -->
<ng-template #subMenu let-nav="nav">
  <div class="dropdown fade-in">
    <button class="dropbtn" [ngClass]="nav.highlight ? 'highlight' : ''" (click)="navigate(nav.url)">
      <mat-icon *ngIf="nav.icon">{{nav.icon}}</mat-icon>
      {{nav.title | translate}}
      <div class="expand">
        <mat-icon>expand_more</mat-icon>
      </div>
    </button>

    <div class="dropdown-content">
      <div class="dropdown_1" *ngFor="let level_1 of nav.children">
        <a [ngClass]="level_1.highlight ? 'highlight' : ''"
            (click)="navigate(level_1.url)">
            <mat-icon>{{level_1.icon}}</mat-icon>
            {{level_1.title | translate}}
            <div class="expand" *ngIf="level_1.children">
            <mat-icon style="transform: rotate(270deg)">expand_more</mat-icon>
            </div>
        </a>

        <div *ngIf="level_1 && level_1.children" class="dropdown-content fade_in">
            <a *ngFor="let level_2 of level_1.children" [ngClass]="level_2.highlight ? 'highlight' : ''"
            (click)="navigate(level_2.url)">
            <mat-icon>{{level_2.icon}}</mat-icon>
            {{level_2.title | translate}}
            <div class="expand" *ngIf="level_2.children">
                <mat-icon style="transform: rotate(270deg)">expand_more</mat-icon>
            </div>
            </a>
        </div>
    </div>
    </div>
  </div>
</ng-template>