import { IUser } from '@shared/models/user.interface';

export interface ImageUpload {
  widget: CloudinaryResponse | null;
  type: MediaSection;
  user: IUser | null;
}
export interface CloudinaryResponse {
  asset_id: string;
  public_id: string;
  version: number;
  version_id: string;
  signature: string;
  width: number;
  height: number;
  format: string;
  resource_type: string;
  created_at: Date;
  tags: any[];
  bytes: number;
  type: string;
  etag: string;
  placeholder: boolean;
  url: string;
  secure_url: string;
  folder: string;
  original_filename: string;
}

export enum MediaResourceType {
  Image,
  Video,
}

export enum MediaSection {
  Profile,
  Cover,
  Home,
  Event,
  Other,
}

export enum IUploadStatus {
  Ready,
  WidgetOpen,
  InProgress,
  Finish,
}

export type CloudinaryResourceType = 'auto' | 'image' | 'video' | 'raw';
