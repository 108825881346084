import {
  ChangeTheme,
  SaveConfig,
  GetConfig,
  GetSupportSession,
  SetThemeStyle,
  SupportMessage,
  SupportSwitch,
  SetQuery,
  PendingSave,
  SetChatMode,
  ChatEnableSwitch,
} from './chat-support.actions';
import { IChatConfig } from '@app/features/back-office/interfaces/chat-config.interface';
import { DefaultThemes, IChatTheme } from '@app/features/back-office/interfaces/chat-style.interface';
import { IChatMessage, IChatSupport, ILocaleCRUD, IQueryString } from '../../models/chat-support.interface';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiChatSupportService } from '../../api/api.chat.service';
import { SnackService } from '@app/shared/services/snack.service';
import { IChatMode } from '../../models/chat-mode.interface';
import { CHAT_CONFIG } from '@app/core/config/chat.config';
import { Injectable } from '@angular/core';
import { single } from 'rxjs';

export class ChatSupportStateModel {
  session: IChatSupport | undefined;
  interaction: boolean | undefined;
  enable: boolean | undefined;
  open: boolean | undefined;
  config: IChatConfig = CHAT_CONFIG[IChatMode.Public];
  theme: IChatTheme = {} as IChatTheme;
  query: IQueryString | undefined;
  pendingSave: boolean = false;
  mode: IChatMode = IChatMode.Public; 
}

@State<ChatSupportStateModel>({
  name: 'chatSupportState',
  defaults: {
    session: undefined,
    interaction: false,
    enable: true,
    open: false,
    pendingSave: false,
    config: CHAT_CONFIG[IChatMode.Public],
    theme: CHAT_CONFIG[IChatMode.Public].Themes[0],
    query: undefined,
    mode: IChatMode.Public,
  },
})
@Injectable()
export class ChatSupportState {
  constructor(private _api: ApiChatSupportService, private _snack: SnackService) {}

  @Selector()
  public static getSupportSession(state: ChatSupportStateModel): IChatSupport | undefined {
    return state.session;
  }

  @Selector()
  public static getSwitch(state: ChatSupportStateModel): boolean | undefined {
    return state.open;
  }

  @Selector()
  public static getConfig(state: ChatSupportStateModel): IChatConfig | undefined {
    return state.config;
  }

  @Selector()
  public static pendingSave(state: ChatSupportStateModel): boolean {
    return state.pendingSave;
  }

  @Selector()
  public static getQuery(state: ChatSupportStateModel): IQueryString | undefined {
    return state.query;
  }

  @Selector()
  public static selectMode(state: ChatSupportStateModel): IChatMode {
    return state.mode;
  }

  @Selector()
  public static selectTheme(state: ChatSupportStateModel): IChatTheme | undefined {
    return state.theme;
  }

  @Selector()
  public static selectChatEnable(state: ChatSupportStateModel): boolean | undefined {
    return state.enable;
  }

  @Action(SupportSwitch)
  public openChat(ctx: StateContext<ChatSupportStateModel>, { open }: SupportSwitch): void {
    ctx.patchState({ open: open });
  }

  @Action(GetSupportSession)
  public getSupportSession(ctx: StateContext<ChatSupportStateModel>, { userId }: GetSupportSession): void {
    const config: IChatConfig | undefined = ctx.getState().config ?? CHAT_CONFIG[ctx.getState().mode];
    const query: IQueryString | undefined = ctx.getState().query;
    const supportSessionLocale: string | null = this.locale(ILocaleCRUD.Read, null, query?.userId);

    if (!query?.userId && supportSessionLocale) {
      const session: IChatSupport = this.reduceNumberOfMessages(
        JSON.parse(supportSessionLocale),
        config,
      );
      ctx.patchState({ session: session });
    } else {
      if (userId && query?.userId) {
        this._api.getSupportSession(userId, query.userId).subscribe((messages: IChatMessage[]) => {
          if (messages.length) {
            let supportSession: IChatSupport | undefined = ctx.getState().session;

            if (supportSession) {
              supportSession.Messages = messages;
            }

            if (!supportSession) {
              supportSession = this.createSession(config, userId, query.userId, messages);
            }
            ctx.patchState({
              session: { ...this.reduceNumberOfMessages(supportSession, config) },
              interaction: false,
            });
          } else {
            ctx.patchState({ session: this.createSession(config, userId, query.userId, []) });
          }
        });
      } else {
        ctx.patchState({ session: this.createSession(config, userId, query?.userId, []) });
      }
    }
  }

  @Action(SupportMessage)
  public supportMessage(ctx: StateContext<ChatSupportStateModel>, { message, userId }: SupportMessage): void {
    let supportSession: IChatSupport | undefined = ctx.getState().session;
    const query: IQueryString | undefined = ctx.getState().query;
    const config: IChatConfig = ctx.getState().config ?? CHAT_CONFIG[ctx.getState().mode];

    if (supportSession) {
      supportSession.Messages = supportSession.Messages.length ? supportSession.Messages : [];
      supportSession.Messages.push(message);
      supportSession = this.reduceNumberOfMessages(supportSession, config);

      ctx.patchState({ session: supportSession, interaction: true });
      this.locale(ILocaleCRUD.Update, supportSession, query?.userId);

      if (message.role === 'assistant') {
        if (userId && query?.userId) {
          this._api.updateSupportSession(supportSession.Messages, userId, query.userId).pipe(single()).subscribe();
        }
      }
    }
  }

  @Action(GetConfig)
  public getConfig(ctx: StateContext<ChatSupportStateModel>, { user }: GetConfig): void {
    if (user) {
      this._api.getConfig(user.Id).subscribe((config: IChatConfig) => {
        if (config) {
          if (config.Themes && ctx.getState().mode === IChatMode.Integrated) {
            config.Themes.forEach((theme: any) => {
              Object.keys(DefaultThemes[0]).forEach((key: string) => {
                if (theme[key] === undefined) {
                  theme[key] = undefined;
                }
              });
            });

            const query: IQueryString | undefined = ctx.getState().query;
            const theme: IChatTheme =
              config.Themes.find((x: IChatTheme) => x.Name === query?.theme) ?? config.Themes[0];

            ctx.patchState({
              config: config,
              theme: theme,
            });
          } else {
            ctx.patchState({
              config: config,
              theme: CHAT_CONFIG[ctx.getState().mode].Themes[0]
            });
          }
        } else {
          ctx.patchState({
            config: CHAT_CONFIG[ctx.getState().mode],
            theme: CHAT_CONFIG[ctx.getState().mode].Themes[0]
          });
        }
      });
    } else {
      ctx.patchState({
        config: CHAT_CONFIG[IChatMode.Public],
        theme: CHAT_CONFIG[ctx.getState().mode].Themes[0]
      });
    }
  }

  @Action(SaveConfig)
  public saveConfig(ctx: StateContext<ChatSupportStateModel>, { config, userId }: SaveConfig): void {
    config.Themes = [ctx.getState().theme]; // TODO: Set array of themes instead of one theme (current one)

    this._api.updateConfig(config, userId).subscribe((updated: boolean) => {
      if (updated) {
        sessionStorage.removeItem(userId);

        ctx.patchState({
          pendingSave: false,
        });
      }
    });
  }

  @Action(PendingSave)
  public pendingSave(ctx: StateContext<ChatSupportStateModel>): void {
    ctx.patchState({
      pendingSave: true,
    });
  }

  @Action(SetQuery)
  public setQuery(ctx: StateContext<ChatSupportStateModel>, { query }: SetQuery): void {
    ctx.patchState({
      query: query,
    });

    if (query.theme) {
      const theme: IChatTheme | undefined = ctx
        .getState()
        .config.Themes.find((x: IChatTheme) => x.Name === query.theme);

      if (theme) {
        ctx.patchState({
          theme: theme,
        });
      }
    }

    if (query.open) {
      ctx.patchState({
        open: query.open === 'true' ? true : false,
      });
    }
  }

  @Action(ChangeTheme)
  public changeTheme(ctx: StateContext<ChatSupportStateModel>, { theme }: ChangeTheme): void {
    const themes: IChatTheme[] | undefined = ctx.getState().config?.Themes ?? DefaultThemes;
    const selectedTheme: IChatTheme | undefined = themes.find((x: IChatTheme) => x.Name === theme.Name);

    if (selectedTheme) {
      ctx.patchState({
        theme: selectedTheme,
      });
    }
  }

  @Action(SetThemeStyle)
  public setThemeStyle(ctx: StateContext<ChatSupportStateModel>, { theme }: SetThemeStyle): void {
    const themes: IChatTheme[] = ctx.getState().config?.Themes ?? DefaultThemes;
    const i: number = themes.findIndex((x: IChatTheme) => x.Name === theme.Name);
    themes[i] = theme;

    ctx.patchState({
      theme: theme,
    });
  }

  @Action(SetChatMode)
  public SetChatMode(ctx: StateContext<ChatSupportStateModel>, { mode }: SetChatMode): void {
    ctx.patchState({
      mode: mode,
    });
  }

  @Action(ChatEnableSwitch)
  public chatEnableSwitch(ctx: StateContext<ChatSupportStateModel>, { enable }: ChatEnableSwitch): void {
    ctx.patchState({
      enable: enable
    });
  }

  private createSession(
    config: IChatConfig,
    userId: string | undefined,
    endUserId: string | undefined,
    messages: IChatMessage[],
  ): IChatSupport {
    const supportSession: any = this.locale(ILocaleCRUD.Read, null, endUserId);
    let support: IChatSupport = {} as IChatSupport;
    const gender: string | undefined = config.BotGender === 'Male' ? 'Male' : config.BotGender === 'Female' ? 'Female' : undefined;

    if (messages.length === 0) {
      const today: string = new Date().toISOString();
      messages.push({
        role: 'break',
        content: today,
      });
    }

    if (supportSession) {
      support = JSON.parse(supportSession);
    } else {
      support.Messages = messages;
      support.UserId = userId;
      support.Started = new Date().toISOString();
      support.Gender = gender ?? Math.floor(Math.random() * 2) < 1 ? 'Female' : 'Male';
      support.Image = `${support.Gender.charAt(0)}${Math.floor(Math.random() * 45)}.png`;
      this.locale(ILocaleCRUD.Update, support, endUserId);
    }

    return support;
  }

  private locale(operation: ILocaleCRUD, payload: any, endUserId?: string): any {
    const storage: Storage = !endUserId ? localStorage : sessionStorage;
    const sessionName: string = !endUserId ? 'support' : endUserId;

    switch (operation) {
      case ILocaleCRUD.Read:
        return storage.getItem(sessionName);
      case ILocaleCRUD.Create:
        return storage.setItem(sessionName, JSON.stringify(payload));
      case ILocaleCRUD.Update:
        return storage.setItem(sessionName, JSON.stringify(payload));
      case ILocaleCRUD.Delete:
        return storage.removeItem(sessionName);
    }
  }

  private reduceNumberOfMessages(support: IChatSupport, config: IChatConfig): IChatSupport {   
    //#region Fixed number of messages
    if (support.Messages.length > config.MaxMessages) {
      support.Messages = support.Messages.slice(-config.MaxMessages);
    }
    //#endregion

    //#region Get last break message
    if (support?.Messages?.length) {
      const breakMessages: IChatMessage[] | undefined = support.Messages.filter(
        (x: IChatMessage) => x.role === 'break',
      );

      if (breakMessages?.length) {
        const lastBreak: string = breakMessages[breakMessages.length - 1]?.content;

        const lastBreakDate: string = new Date(lastBreak).toISOString().split('T')[0];
        const todayBreak: string = new Date().toISOString().split('T')[0];

        if (lastBreakDate === todayBreak) {
          support.Started = breakMessages[breakMessages.length - 1]?.content;
        } else {
          const today: string = new Date().toISOString();
          support.Started = today;
          support.Messages.push({
            role: 'break',
            content: today,
          });
        }
      } else {
        const today: string = new Date().toISOString();
        support.Started = today;
        support.Messages.push({
          role: 'break',
          content: today,
        });
      }
    }
    //#endregion

    //#region Calculate today usage

    const breakIndex: number = support.Messages.findIndex(
      (x: IChatMessage) => x.content === support.Started.toString(),
    );

    const supportTodayMessages: number =
      support.Messages.slice(breakIndex).filter((x: IChatMessage) => x.role === 'assistant').length ?? 0;

    support.Usage = (supportTodayMessages / config.DailyLimit) * 100;
    //#endregion

    return support;
  }
}
