import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InternetStatusService {
  private internetStatusSubject: Subject<boolean> = new Subject<boolean>();
  public internetStatus$: Observable<boolean> = this.internetStatusSubject.asObservable();

  constructor() {
    window.addEventListener('online', () => this.internetStatusSubject.next(true));
    window.addEventListener('offline', () => this.internetStatusSubject.next(false));
  }

  public isOnline(): boolean {
    return navigator.onLine;
  }
}
