import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { InternetStatusService } from '@interceptor/Internet-status.service';
import { FirestoreCollection } from '@config/firestore.collections.config';
import { catchError, first, from, map, Observable, of } from 'rxjs';
import { URLService } from '@shared/services/url.service';
import { IMedia } from '../models/media.interface';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ApiMediaService {
  constructor(private db: AngularFirestore, private _url: URLService, private _net: InternetStatusService) {}

  public getMediaList(userId: string): Observable<IMedia[] | null> {
    if (this._net.isOnline()) {
      return this.db
        .collection(FirestoreCollection.Media, (ref: any) => ref.where('UserId', '==', userId))
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? x : null)));
    } else return of(null);
  }

  public uploadMedia(media: IMedia): Observable<boolean> {
    if (this._net.isOnline()) {
      const imageCollection: AngularFirestoreCollection<IMedia> = this.db.collection<IMedia>(FirestoreCollection.Media);

      return from(imageCollection.doc(media.Id.toString()).set(media)).pipe(
        map(() => true),
        catchError(() => of(false)),
      );
    } else return of(false);
  }

  public updateMediaOrder(media: IMedia, order: number): Observable<boolean> {
    if (this._net.isOnline()) {
      media.Order = order;

      return from(
        this.db
          .doc(`${FirestoreCollection.Media}/${media.Id}`)
          .update(media)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          }),
      );
    } else return of(false);
  }

  public deleteMedia(mediaId: string): Observable<boolean> {
    if (this._net.isOnline()) {
      return from(
        this.db
          .doc(`${FirestoreCollection.Media}/${mediaId}`)
          .delete()
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          }),
      );
    } else return of(false);
  }
}
