// https://cloudinary.com/documentation/upload_widget_reference#required_parameters
// https://cloudinary.com/documentation/upload_widget_reference
// https://demo.cloudinary.com/uw/#/

import { EnvironmentService } from '@environments/environment.service';
import { CloudinaryResourceType } from './cloudinary-response.interface';

export const CloudinaryConfig: CloudinaryConfig = {
  cloudName: 'eventbook',
  uploadPreset: 'eventbook',
  folder: 'Default',
  apiKey: '398138843593792',
  widgetScript: EnvironmentService.getProp('cloudinary|lib'),
  sources: [
    'local',
    'url',
    'camera',
    // 'image_search',
    // 'google_drive',
    // 'facebook',
    // 'dropbox',
    // 'instagram',
    // 'shutterstock',
    // 'getty',
    // 'istock',
    // 'unsplash',
  ],
  googleApiKey: '<image_search_google_api_key>',
  showAdvancedOptions: false,
  multiple: true,
  autoMinimize: true,
  cropping: false,
  showSkipCropButton: true,
  resourceType: 'auto',
  defaultSource: 'local',
  tags: [],
  maxFiles: 20,
  maxImageFileSize: 9000000, // In bytes (9mb)
  maxVideoFileSize: 40000000, // In bytes (40mb)
  showPoweredBy: false,
  styles: {
    palette: {
      window: '#FFFFFF',
      windowBorder: '#818D9C',
      tabIcon: '#F0394D',
      menuIcons: '#5A616A',
      textDark: '#818D9C',
      textLight: '#FFFFFF',
      link: '#F0394D',
      action: '#F0394D',
      inactiveTabIcon: '#748190',
      error: '#FF1100',
      inProgress: '#89C1FF',
      complete: '#28F3A7',
      sourceBg: '#F6F6F6',
    },
    fonts: {
      default: null,
      // eslint-disable-next-line prettier/prettier
      '\'IBM Plex Sans\', sans-serif': {
        url: 'https://fonts.googleapis.com/css?family=IBM+Plex+Sans',
        active: true,
      },
    },
  },
};

interface CloudinaryConfig {
  cloudName: string;
  uploadPreset: string;
  apiKey: string;
  widgetScript: string;
  sources?: string[];
  googleApiKey?: string;
  defaultSource?: string;
  styles?: any;
  folder?: string;
  showAdvancedOptions?: boolean;
  multiple: boolean;
  cropping: boolean;
  showSkipCropButton?: boolean;
  resourceType?: CloudinaryResourceType;
  tags?: string[];
  maxFiles: number;
  maxImageFileSize?: number;
  maxVideoFileSize?: number;
  autoMinimize?: boolean;
  showPoweredBy?: boolean; // Note: Supported only for paid Cloudinary accounts and requires some time for cache expiration.
}
