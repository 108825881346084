<div #phoneChat class="phone-chat">
    <img src="./../../../assets/template/img/app-landing/mockup/iphone.png" alt="" />
    <div class="chat-examples">
      <main class="fade-in">
        <div class="center">
          <div
            class="chat"
            [style.background-color]="theme.HeaderFooterBg"
            [style.border]="theme.ChatBorder ? '1px solid' : ''"
          >
            <div class="contact bar">
                <img [src]="config?.CompanyLogo || config?.BotAvatar || '../../../../assets/avatars/M8.png'" class="pic" />
      
              <div class="name" [style.color]="theme.HeaderText">{{ config?.BotName ?? 'Chat_TechnicalSupport' | translate }}</div>
              <div class="seen" *ngIf="support.Started" [style.color]="theme.HeaderSubText">
                {{ currentDate | date : _units.dateFormat }}
              </div>
              <div class="close">
                <mat-icon [style.color]="theme.CloseButton">cancel</mat-icon>
              </div>
            </div>
      
            <div
              #messages
              id="chat"
              class="messages"
              [style.background-color]="theme.MessagesBg"
              [style.border]="theme.MessagesBorder ? '1px solid ' + theme.MessagesBorder : 'none'"
            >
              <div class="scale-in-center-delay" *ngFor="let msg of support.Messages">
                <div
                  class="message user"
                  *ngIf="msg.role === 'user'"
                  [style.background-color]="theme.MessageUserBg"
                  [style.color]="theme.MessageUserText"
                  [style.border-color]="theme.MessageUserBorder"
                  [innerHTML]="msg.content | translate"
                ></div>
                <div
                  class="message support"
                  *ngIf="msg.role === 'assistant'"
                  [style.background-color]="theme.MessageAIBg"
                  [style.color]="theme.MessageAIText"
                  [style.border-color]="theme.MessageAIBorder"
                  [innerHTML]="msg.content | translate"
                ></div>
                <div
                  class="intro"
                  *ngIf="msg.role === 'break'"
                  [style.background-color]="theme.MessageSeparatorBg"
                  [style.color]="theme.MessageSeparatorText"
                >
                  <span *ngIf="msg.content === support.Started">{{ 'Chat_SupportIntro' | translate }}</span>
                  <span *ngIf="msg.content !== support.Started">{{ msg.content | date : _units.dateFormat }}</span>
                </div>
              </div>

              <div
              class="message support fade-in"
              *ngIf="typing"
              [style.background-color]="theme.MessageAIBg"
              [style.color]="theme.MessageAIText"
              [style.border-color]="theme.MessageAIBorder"
            >
              <div class="typing typing-1"></div>
              <div class="typing typing-2"></div>
              <div class="typing typing-3"></div>
            </div>
            </div>
            <div class="input">
              <mat-icon [style.color]="theme.FooterIcons">mail</mat-icon>
              <input #phoneChatInput
                [placeholder]="'Chat_EnterYourMessage' | translate"
                [readonly]="true"
                [value]="userPrompt"
                [style.background-color]="theme.InputBg"
                [style.color]="theme.InputText"
                [style.--placeholder-color]="theme.InputPlaceholder"
              />
              <mat-icon [style.color]="theme.FooterIcons">send</mat-icon>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>