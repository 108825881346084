<div *ngIf="show" id="dialog-bg" class="wrapper dialog-fade-in" [ngClass]="closeTrigger ? 'dialog-fade-out' : ''" (click)="bgClickHandler($event);">
  <div class="dialog dialog-scale-in-center" [ngClass]="closeTrigger ? 'dialog-scale-out-center' : ''"
    [style.width]="width" [style.height]="height" [style.minWidth]="minWidth" [style.minHeight]="minWidth"
    [style.maxWidth]="maxWidth" [style.maxHeight]="maxHeight">

    <div *ngIf="close" class="close" (click)="closeDialogStart();">
      <button class="dialog-rotate-center">✖</button>
    </div>

    <h2 *ngIf="header" [ngClass]="close ? 'extra-space' : ''">{{header | translate}}</h2>
    <ng-content></ng-content>
  </div>
</div>