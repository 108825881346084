import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

export class EnvironmentService {
  public static getProp(props: string): any {
    const propsArray: string[] = props.split('|');
    let value: any = { ...environment };

    // Production
    if (environment.production) {
      value = this.decryptObjectAES(environment.appHash);
    }
    // Development
    else value = environment.appHash;

    propsArray.forEach((prop: string) => {
      value = value[prop];
    });

    return value;
  }

  public static encryptEnvironment(): void {
    console.log('%c ★★★ Replace Environment prod <appHash> with hash below! ★★★', 'color: green;');
    console.log(`%c${this.encryptObjectAES(environment.appHash)}`, 'color: green;');
  }

  private static encryptObjectAES(object: any): string {
    //#region Crypto mode - the same for all methods
    const x: string = 'A43543FdGDFgD34534bD435Bd45GFbfG345';
    const y: string = '324fDFdGDh435345gFHf5464ngFHfg456nN';
    const _key: string = `BA00X${x.split('').reverse().toString().replace(/,/g, '')}YZ?!`;
    const _iv: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(y.split('').reverse().toString().replace(/,/g, ''));
    //#endregion

    const encrypt: any = (content: any) =>
      CryptoJS.AES.encrypt(JSON.stringify({ content }), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }).toString();

    return encrypt({ x: JSON.stringify(object) });
  }

  private static decryptObjectAES(encryptedObject: string): any {
    //#region Crypto mode - the same for all methods
    const x: string = 'A43543FdGDFgD34534bD435Bd45GFbfG345';
    const y: string = '324fDFdGDh435345gFHf5464ngFHfg456nN';
    const _key: string = `BA00X${x.split('').reverse().toString().replace(/,/g, '')}YZ?!`;
    const _iv: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(y.split('').reverse().toString().replace(/,/g, ''));
    //#endregion

    const decrypt: any = (encrypted: any) =>
      JSON.parse(
        CryptoJS.AES.decrypt(encrypted, _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8),
      ).content.x;

    return JSON.parse(decrypt(encryptedObject));
  }

  public static encryptTextAES(object: string): string {
    //#region Crypto mode - the same for all methods
    const x: string = 'A43543FdGDFgD34534bD435Bd45GFbfG345';
    const y: string = '324fDFdGDh435345gFHf5464ngFHfg456nN';
    const _key: string = `BA00X${x.split('').reverse().toString().replace(/,/g, '')}YZ?!`;
    const _iv: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(y.split('').reverse().toString().replace(/,/g, ''));
    //#endregion

    const encrypt: any = (content: any) =>
      CryptoJS.AES.encrypt(JSON.stringify({ content }), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }).toString();

    return encrypt({ x: object });
  }

  public static decryptTextAES(encryptedObject: string): string {
    //#region Crypto mode - the same for all methods
    const x: string = 'A43543FdGDFgD34534bD435Bd45GFbfG345';
    const y: string = '324fDFdGDh435345gFHf5464ngFHfg456nN';
    const _key: string = `BA00X${x.split('').reverse().toString().replace(/,/g, '')}YZ?!`;
    const _iv: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(y.split('').reverse().toString().replace(/,/g, ''));
    //#endregion

    const decrypt: any = (encrypted: any) =>
      JSON.parse(
        CryptoJS.AES.decrypt(encrypted, _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8),
      ).content.x;

    return decrypt(encryptedObject);
  }
}
