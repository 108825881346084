// https://app.elasticemail.com/marketing/settings/new/create-smtp
import { EnvironmentService } from 'src/environments/environment.service';
import { IEmail, IEmailStatus } from '../models/email.interface';
import { Injectable } from '@angular/core';

declare let Email: any;

@Injectable()
//** Do not call this service directly, instead call EmailService */
export class ElasticEmailService {
  private scriptLoaded: boolean = false;

  private loadScript(): void {
    // eslint-disable-next-line @typescript-eslint/typedef
    const node = document.createElement('script');
    node.src = EnvironmentService.getProp('email|elasticEmail|lib');
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    document.getElementsByTagName('app-root')[0].appendChild(node);
    this.scriptLoaded = true;
  }

  public sendEmail(email: IEmail): void {
    if (this.scriptLoaded) {
      try {
        Email.send({
          Host: EnvironmentService.getProp('email|elasticEmail|server'),
          Username: EnvironmentService.getProp('email|elasticEmail|username'),
          Password: EnvironmentService.getProp('email|elasticEmail|password'),
          From: EnvironmentService.getProp('email|elasticEmail|verifiedDomain'),
          To: email.to,
          Subject: email.subject,
          Body: email.body,
        }).then((msg: string) => {

          // eslint-disable-next-line @typescript-eslint/typedef
          const success = msg === 'OK' ? true : false;
          const status = new CustomEvent('emailStatus', {
            detail: { status: success ? IEmailStatus.Sent : IEmailStatus.Failed },
          });

          window.dispatchEvent(status);
          window.scrollTo({ top: 0, behavior: 'smooth' });

          if (!success) { console.error(msg); }

        });
      } 
      catch {
        const status = new CustomEvent('emailStatus', {
          detail: { status: IEmailStatus.Failed },
        });
        window.dispatchEvent(status);
      }
    } 
    else {
      this.loadScript();
      setTimeout(() => {
        this.sendEmail(email);
      }, 3000);
    }
  }
}

