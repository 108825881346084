import { EnvironmentService } from 'src/environments/environment.service';
import { IEmail, IEmailStatus } from '../models/email.interface';
import { Injectable } from '@angular/core';

declare let emailjs: any;

@Injectable()
//** Do not call this service directly, instead call EmailService */
export class EmailJSService {
  private scriptLoaded: boolean = false;

  private loadScript(): void {
    // Kreiranje skripte za učitavanje emailjs
    const node = document.createElement('script');
    node.src = EnvironmentService.getProp('email|emailJS|lib');
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    document.getElementsByTagName('app-root')[0].appendChild(node);
    this.scriptLoaded = true;
  }

  public sendEmail(email: IEmail): void {
    if (this.scriptLoaded) {
    //   try {
        const publicKey = EnvironmentService.getProp('email|emailJS|publicKey');
        emailjs.init(publicKey);
        
        // Slanje email-a putem emailjs
        emailjs.send(
          EnvironmentService.getProp('email|emailJS|serviceId'), // Service ID
          EnvironmentService.getProp('email|emailJS|templateId'), // Template ID
          {
            from: email.from ?? EnvironmentService.getProp('email|emailJS|support'),
            to: email.to,
            subject: email.subject,
            message: email.body,
          }
        ).then((response: any) => {
          const success = response.status === 200;
          const status = new CustomEvent('emailStatus', {
            detail: { status: success ? IEmailStatus.Sent : IEmailStatus.Failed },
          });

          window.dispatchEvent(status);
          window.scrollTo({ top: 0, behavior: 'smooth' });

          if (!success) { console.error(response.text); }

        }, (error: any) => {
          const status = new CustomEvent('emailStatus', {
            detail: { status: IEmailStatus.Failed },
          });
          window.dispatchEvent(status);
          console.error('FAILED...', error);
        });
      } 
    //   catch {
    //     const status = new CustomEvent('emailStatus', {
    //       detail: { status: IEmailStatus.Failed },
    //     });
    //     window.dispatchEvent(status);
    //   }
    // } 
    else {
      this.loadScript();
      setTimeout(() => {
        this.sendEmail(email);
      }, 3000);
    }
  }
}
