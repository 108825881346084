import { MobileBadgeComponent } from './mobile-badge.component';
import { PlatformModule } from '@angular/cdk/platform';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [MobileBadgeComponent],
  exports: [MobileBadgeComponent],
  imports: [CommonModule, PlatformModule, TranslateModule],
  providers: [],
})
export class MobileBadgeModule {}
