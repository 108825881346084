import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { IAutoForm } from './interface/auto-form.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { formatDate } from '@angular/common';

@Component({
  selector: 'auto-form',
  templateUrl: './auto-form.component.html',
  styleUrls: ['./auto-form.component.scss'],
})
export class AutoFormComponent implements OnInit, OnDestroy {
  // Real-time form changes
  @Input() public set object(data: any) {
    this.generateFormGroup();
    if (data && JSON.stringify(this.simpleObject()) !== JSON.stringify(data)) {
      const formData: any = this.formGroup.value;

      Object.keys(data).forEach((key: string) => {
        if (formData[key] !== data[key]) {
          if (this.formGroup.controls[key]) {
            this.formGroup.controls[key].setValue(data[key]);
          }
        }
      });

      this.formGroup.markAllAsTouched();
    }
  }

  // Disable all form elements
  @Input() public set disabled(value: boolean) {
    this.formDisabled = value;
    this.formDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  @Input() form: IAutoForm[] = [];
  @Input() disableSubmit: boolean = false; // Disable when user hit enter. User case is when we have multiple forms on the same page and user want to accept all of them at once.
  @Input() inline: boolean = false; // Align controls inline
  @Input() emitAlways: boolean = false; // Emit form changes on every input
  @Input() emitFirstChange: boolean = false; // By default first change is disabled (initially when form is in process of initialization) But sometimes we need init event
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() debounceTime: number = 100; // Timeout before emit any values
  @Input() search: string | undefined; // Search text that matches the label text, everything all will be hidden
  @Output() formChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() formSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formId: EventEmitter<string> = new EventEmitter<string>();

  public textAreaMinRows: number = window.innerWidth < 768 ? 1 : 5;
  public textAreaMaxRows: number = window.innerWidth < 768 ? 3 : 30;

  private firstChange = true;
  public form_Id: string = '';
  public formGroup: FormGroup = new FormGroup({});
  public formDisabled: boolean = false;
  public focusedElementId: string | undefined;
  private destroy$: Subject<void> = new Subject<void>();
  private dateFormat: any = {
    datetime: 'yyyy-MM-dd HH:mm',
    date: 'yyyy-MM-dd',
    time: 'HH:mm',
  };

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public _translate: TranslateService,
    public _platform: Platform,
  ) {}

  public ngOnInit(): void {
    if (!this.form_Id) {
      this.generateFormGroup();
    }
    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe(() => {
        this.saveChangesOnForm();
        this.valid.emit(this.formGroup.valid);
        
        if ((this.emitAlways || this.formGroup.valid) && (this.emitFirstChange || !this.firstChange)) {
          this.formChange.emit(this.simpleObject());
        }
        
        this.firstChange = false;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public formSubmitted(): void {
    if (this.formGroup.valid && !this.disableSubmit) {
      this.formSubmit.emit();
    }
  }

  private generateFormId(): void {
    const randomNum: number = Math.floor(Math.random() * 999999);
    const timeRef: number = new Date().getTime();
    this.form_Id = `${timeRef}${randomNum}`;
    this.formId.emit(this.form_Id);
  }

  private generateFormGroup(): void {
    this.generateFormId();
    const formGroup: any = {};
    this.form.map((element: IAutoForm) => {
      element.Type = element.Type ? element.Type : 'text';

      // #region DATE, DATE TIME, TIME
      if (element.Type === 'date' || element.Type === 'datetime' || element.Type === 'time') {
        if (element.Value) {
          element.Value = formatDate(new Date(element.Value.toString()), this.dateFormat[element.Type], this.locale);
        }

        //#region Validation

        const minSlice: number = element.Type === 'date' ? 0 : element.Type === 'datetime' ? 0 : 0;
        const maxSlice: number = element.Type === 'date' ? 10 : element.Type === 'datetime' ? 16 : 16;

        if (element.MinValue) {
          element.MinValue = new Date(element.MinValue).toISOString().slice(minSlice, maxSlice);
        }

        if (element.MaxValue) {
          element.MaxValue = new Date(element.MaxValue).toISOString().slice(minSlice, maxSlice);
        }
        //#endregion
      }
      // #endregion

      if (
        element.Type !== 'html' &&
        element.Type !== 'title' &&
        element.Type !== 'space' &&
        element.Type !== 'divider'
      ) {
        formGroup[element.Name] = new FormControl(
          { value: element.Value, disabled: this.formDisabled || element.Disabled ? true : false },
          element.Validators,
        );
      }
    });
    this.formGroup = new FormGroup(formGroup);
  }

  private saveChangesOnForm(): void {
    this.form.map((element: IAutoForm) => {
      element.Value = this.formGroup.get(element.Name)?.value;
    });
  }

  private simpleObject(): any {
    const result: any = {};
    Object.keys(this.formGroup.value).map((key: string) => {
      result[key] = this.formGroup.value[key];
    });

    return result;
  }

  public changePasswordType(input: HTMLInputElement, event: 'down' | 'up'): void {
    if (!(this._platform.ANDROID || this._platform.IOS)) {
      // WEB PLATFORM
      input.type = event === 'down' ? 'text' : 'password';
    } else {
      // MOBILE PLATFORM
      if (event === 'down') {
        input.type = input.type === 'text' ? 'password' : 'text';
      }
    }
  }

  public autoCompleteDisplay(option: string): string {
    if (!option) {
      return '';
    }

    return this._translate.instant(option);
  }

  public clearField(name: string): void {
    this.formGroup.get(name)?.setValue(undefined);
  }
}
