import { ApiSubscription as ApiSubscriptionService } from '../api/api.subscription.service';
import { ISubscription } from '../interfaces/subscription.interface';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import {
  GetSubDomainSubscription,
  GetSubscriptionPackage,
  UpdateSubscription,
  AddSubscription,
} from './subscription.actions';
import { SetChatMode } from '../../chat-support/store-chat/support/chat-support.actions';
import { IChatMode } from '../../chat-support/models/chat-mode.interface';

export class SubscriptionStateModel {
  /** User subscription */
  subscription: ISubscription | null | undefined;
  /** SubDomain Subscription - when random user who is not logged in ask for someone else subdomain we need to get subscription by subdomain url */
  subscriptionSubDomain: ISubscription | null | undefined;
}
@State<SubscriptionStateModel>({
  name: 'subscriptionState',
  defaults: {
    subscription: undefined,
    subscriptionSubDomain: undefined,
  },
})
@Injectable()
export class SubscriptionState {
  constructor(private _apiSubscription: ApiSubscriptionService) {}

  @Selector()
  public static getSubscription(state: SubscriptionStateModel): ISubscription | null | undefined {
    return state.subscription;
  }

  @Selector()
  public static getSubscriptionSubDomain(state: SubscriptionStateModel): ISubscription | null | undefined {
    return state.subscriptionSubDomain;
  }

  @Selector()
  public static isSubscriptionValid(state: SubscriptionStateModel): boolean | null | undefined {
    const currentDate: Date = new Date();
    if (state.subscription?.Id) {
      return currentDate < new Date(state.subscription.ValidUntil) ? true : false;
    } else return state.subscription === null ? null : undefined;
  }

  @Selector()
  public static isSubDomainSubscriptionValid(state: SubscriptionStateModel): boolean | null | undefined {
    const currentDate: Date = new Date();
    if (state.subscriptionSubDomain?.Id) {
      return currentDate < new Date(state.subscriptionSubDomain.ValidUntil) ? true : false;
    } else return state.subscriptionSubDomain === null ? null : undefined;
  }

  @Action(GetSubscriptionPackage)
  public getSubscriptionPackage(ctx: StateContext<SubscriptionStateModel>, { userId }: GetSubscriptionPackage): void {
    if (userId) {
      this._apiSubscription
        .getSubscription(userId)
        .pipe(take(1))
        .subscribe((x: ISubscription | null) => {
          if (x) {
            // User License check
            const diffInMs: number = new Date(x.ValidUntil).getTime() - new Date().getTime();

            if (diffInMs > 0) {
              ctx.dispatch(new SetChatMode(IChatMode.PowerUser));
            }
          }
          ctx.patchState({ subscription: x });
        });
    } else ctx.patchState({ subscription: undefined });
  }

  @Action(GetSubDomainSubscription)
  public getSubDomainSubscription(
    ctx: StateContext<SubscriptionStateModel>,
    { subDomain }: GetSubDomainSubscription,
  ): void {
    this._apiSubscription
      .getSubDomainSubscription(subDomain)
      .pipe(take(1))
      .subscribe((x: ISubscription | null) => {
        ctx.patchState({ subscriptionSubDomain: x });
      });
  }

  @Action(AddSubscription)
  public userSubscription(ctx: StateContext<SubscriptionStateModel>, { subscription }: AddSubscription): void {
    this._apiSubscription
      .addSubscription(subscription)
      .pipe(take(1))
      .subscribe((x: boolean) => {
        if (x) {
          ctx.patchState({ subscription: subscription });
        }
      });
  }

  @Action(UpdateSubscription)
  public updateSubscription(ctx: StateContext<SubscriptionStateModel>, { subscription }: UpdateSubscription): void {
    this._apiSubscription
      .updateSubscription(subscription)
      .pipe(take(1))
      .subscribe((x: boolean) => {
        if (x) {
          ctx.patchState({ subscription: subscription });
        }
      });
  }
}
