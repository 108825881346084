import { IUploadStatus } from '@app/core/extensions/cloudinary/cloudinary-response.interface';
import { IMedia } from '../../account/models/media.interface';

export class GetMedia {
  public static readonly type: string = '[GetMedia] Get Media';
  constructor(public userId: string) {}
}

export class UploadId {
  public static readonly type: string = '[UploadId] Upload Id set';
  constructor(public uploadId: string) {}
}

export class UploadStatus {
  public static readonly type: string = '[UploadStatus] Upload widget status';
  constructor(public status: IUploadStatus) {}
}

export class UploadMedia {
  public static readonly type: string = '[UploadMedia] Media URL stored in DB';
  constructor(public media: IMedia) {}
}

export class UploadMediaReorder {
  public static readonly type: string = '[UploadMediaReorder] Media reorder detected. DataBase sync started';
  constructor(public reorderMedia: IMedia[]) {}
}

export class DeleteMedia {
  public static readonly type: string = '[DeleteMedia] Delete media';
  constructor(public media: IMedia) {}
}
