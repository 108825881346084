<!-- FORM -->
<div *ngIf="_mail.status === 'ready' || _mail.status === 'sending'" class="fade-in">
  
  <!-- CONTACT FORM -->
  <ng-container *ngIf="!setMeeting">
    <h2>{{'ContactUs' | translate}}</h2>
    <h5>{{'WeWillBackToYou' | translate}}!</h5>
  
    <auto-form 
      [form]="contact_Form" 
      [disabled]="wait || _mail.status === 'sending'" 
      [disableSubmit]="wait || !formValid || _mail.status === 'sending'" 
      (valid)="formValid = $event;" 
      (formChange)="contact = $event;" 
      (formSubmit)="sendEmail();">
    </auto-form>
  </ng-container>

  <!-- SCHEDULE A MEETING FORM -->
  <ng-container *ngIf="setMeeting">
    <h2 style="margin-bottom: 0px;">💼 {{'BecomeOurPartner' | translate}}</h2>
  
    <auto-form 
      [form]="setMeeting_Form" 
      [disabled]="wait || _mail.status === 'sending'" 
      [disableSubmit]="wait || !formValid || _mail.status === 'sending'" 
      (valid)="formValid = $event;" 
      (formChange)="partnerContact = $event;" 
      (formSubmit)="scheduleMeetingEmail();">
    </auto-form>
  </ng-container>
  
  <!-- SENDING IN PROGRESS -->
  <button mat-raised-button color="primary" [disabled]="wait || !formValid || _mail.status === 'sending'" (click)="!setMeeting ? sendEmail() : scheduleMeetingEmail();">
    <div *ngIf="_mail.status === 'sending'"><mat-icon class="rotate-center">autorenew</mat-icon>{{'Sending' | translate}}...</div>
    <div *ngIf="_mail.status !== 'sending'"><mat-icon>send</mat-icon>{{'Send' | translate}}</div>
  </button>
</div>

<!-- SENDING RESULT -->
<div *ngIf="_mail.status === 'sent' || _mail.status === 'failed'" class="fade-in">
  <div class="email-result">
    <ng-container *ngIf="_mail.status === 'sent'">
      <h2>{{'ThankYouForContactUs' | translate}}</h2>
      <h5>{{'WeWillBackToYou' | translate}}!</h5>
      <mat-icon class="big success">check_circle</mat-icon>
      <h3>{{'EmailSent' | translate}}</h3>
      <button mat-raised-button color="primary" (click)="_url.setURL('Home');">{{'HomePage' | translate}}</button>
    </ng-container>

    <ng-container *ngIf="_mail.status === 'failed'">
      <h2>{{'UpsEmailNotSent' | translate}}</h2>
      <mat-icon class="big fail">cancel</mat-icon>
      <h3>{{'EmailSendingFailed' | translate}}</h3>
      <div class="actions">
        <button mat-raised-button color="primary" (click)="_url.setURL('Home');">{{'HomePage' | translate}}</button>
        <button mat-raised-button color="primary" (click)="sendEmail();">{{'TryAgain' | translate}}</button>
      </div>
    </ng-container>
  </div>
</div>
