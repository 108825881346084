import { TranslateService } from '@ngx-translate/core';
import { Injectable, Renderer2 } from '@angular/core';
import { SnackService } from './snack.service';
import { Observable, concat } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppService {
  public scriptsLoaded: boolean = false;

  constructor(private _translate: TranslateService, private _snack: SnackService) {}

  public removeUndefinedProperties(obj: { [key: string]: any }): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== '') {
        result[key] = obj[key];
      }
    }

    return result;
  }

  public removeUndefinedPropertiesFromArray(arr: { [key: string]: any }[]): { [key: string]: any }[] {
    return arr
      .map((obj: any) => {
        const result: { [key: string]: any } = {};

        for (const key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
            result[key] = obj[key];
          }
        }

        if (Object.keys(result).length > 0) {
          return result;
        }

        return null;
      })
      .filter((obj: any) => obj !== null) as { [key: string]: any }[];
  }

  public copyToClipboard(id: string): void {
    const element: any = document.createElement('textarea');
    element.value = document.getElementById(id)?.textContent;
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
    this._snack.success('CopiedToClipboard');
  }

  public loadScripts(prefix: string, renderer: Renderer2): void {
    const scriptFiles: string[] = [
      `${prefix}/template/js/jquery.js`,
      `${prefix}/template/js/popper.min.js`,
      `${prefix}/template/js/bootstrap.min.js`,
      `${prefix}/template/js/appear-2.js`,
      `${prefix}/template/js/owl.js`,
      `${prefix}/template/js/wow.min.js`,
      `${prefix}/template/js/aos.js`,
      `${prefix}/template/js/slick.js`,
      `${prefix}/template/js/pagenav.js`,
      `${prefix}/template/js/bxslider.js`,
      `${prefix}/template/js/jquery.barfiller.js`,
      `${prefix}/template/js/jquery.mCustomScrollbar.concat.min.js`,
      `${prefix}/template/js/parallax-scroll.js`,
      `${prefix}/template/js/swiper.min.js`,
      `${prefix}/template/js/typer-new.js`,
      `${prefix}/template/js/odometer.js`,
      `${prefix}/template/js/script.js`,
    ];

    // If scripts exist remove them
    const existingScripts: HTMLScriptElement[] = Array.from(document.getElementsByTagName('script'));
    const scriptsToRemove = existingScripts.filter(script => {
      const src = script.getAttribute('src');
      return src && scriptFiles.includes(src);
    });

    scriptsToRemove.forEach(script => {
      document.body.removeChild(script);
    });

    // Add scripts
    const scriptObservables = scriptFiles.map(file => {
        return this.loadScript(file, renderer);
    });

    concat(...scriptObservables)
        .subscribe({
            next: () => {},
            error: (err) => {
                console.error('Error loading script:', err);
            },
            complete: () => {
                console.log('All scripts are loaded successfully');
                this.scriptsLoaded = true;
            }
        });
  }

  private loadScript(file: string, renderer: Renderer2): Observable<void> {
    return new Observable(observer => {
        const script: HTMLScriptElement = renderer.createElement('script');
        renderer.setAttribute(script, 'src', file);
        script.onload = () => {
            observer.next();
            observer.complete();
        };
        script.onerror = (err) => observer.error(err);
        renderer.appendChild(document.body, script);
    });
  }

  public stringifyWithUTF8(obj: any): string {
    const utf8Encoder = new TextEncoder();
    const utf8Decoder = new TextDecoder('utf-8');
    const jsonStr = JSON.stringify(obj);
    const utf8Bytes = utf8Encoder.encode(jsonStr);
    const utf8String = utf8Decoder.decode(utf8Bytes);
    return utf8String;
  }
}
