import { IGeoLocation } from './interface/geo-location.interface';
import { Component, Input, OnDestroy } from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'geo-location',
  templateUrl: './geo-location.component.html',
  styleUrls: ['./geo-location.component.scss'],
})
export class GeoLocationComponent implements OnDestroy {
  @Input() width: string = '300px';
  @Input() height: string = '300px';

  @Input() public set address(x: string) {
    this.addressChanged.next(x);
  }

  @Input() public set geo(x: IGeoLocation) {
    this.geoChanged.next(x);
  }

  private destroy$: Subject<void> = new Subject<void>();
  private addressChanged: Subject<string> = new Subject<string>();
  private geoChanged: Subject<IGeoLocation> = new Subject<IGeoLocation>();
  public location: string = '';

  constructor() {
    this.addressChanged
      .pipe(takeUntil(this.destroy$))
      .pipe(debounceTime(1500))
      .subscribe((x: string) => {
        this.location = `https://www.google.com/maps?q=${x}&output=embed`;
      });

    this.geoChanged
      .pipe(takeUntil(this.destroy$))
      .pipe(debounceTime(1500))
      .subscribe((x: IGeoLocation) => {
        this.location = `https://www.google.com/maps?q=${x.Latitude}, ${x.Longitude}&output=embed`;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
