import { UploadId, UploadStatus, UploadMedia } from '@app/shared/components/media-upload/store/media-upload.actions';
import { UsersState, UsersStateModel } from '@app/shared/components/account/store-user/users.state';
import { MediaUploadState } from '@app/shared/components/media-upload/store/media-upload.state';
import { IMedia } from '@app/shared/components/account/models/media.interface';
import { Injectable, RendererFactory2, Renderer2 } from '@angular/core';
import { CloudinaryConfig as settings } from './cloudinary-config';
import { SnackService } from '@shared/services/snack.service';
import { TranslateService } from '@ngx-translate/core';
import { IUser } from '@shared/models/user.interface';
import { of, fromEvent, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import {
  CloudinaryResourceType,
  MediaSection,
  ImageUpload,
  IUploadStatus,
  MediaResourceType,
} from './cloudinary-response.interface';

declare let cloudinary: any;

@Injectable()
export class CloudinaryWidgetService {
  @Select(UsersState) user$!: Observable<UsersStateModel>;
  @Select(MediaUploadState.status) uploadStatus$!: Observable<IUploadStatus>;

  private user: IUser | null = null;
  private renderer: Renderer2;
  private widget: any;
  private image: ImageUpload = {} as ImageUpload;

  //#region Manual settings
  private uploadId: string = '';
  private resourceType: CloudinaryResourceType = 'image';
  private imageType: MediaSection = MediaSection.Other;
  private multiple: boolean = settings.multiple;
  private cropping: boolean = settings.cropping;
  public maxFiles: number = settings.maxFiles;
  //#endregion

  constructor(
    private _store: Store,
    private _snack: SnackService,
    private _rendererFactory: RendererFactory2,
    private _translate: TranslateService,
  ) {
    this.renderer = this._rendererFactory.createRenderer(null, null);

    this._translate.onLangChange.subscribe(() => {
      this.loadWidget(
        false,
        this.resourceType,
        this.uploadId,
        this.imageType,
        this.multiple,
        this.cropping,
        this.maxFiles,
      );
    });
    this.user$.subscribe((loggedUser: UsersStateModel) => {
      this.user = loggedUser.user;
    });
  }

  public openWidget(uploadId: string, imageType: MediaSection): void {
    if (this._store.selectSnapshot(MediaUploadState.status) === IUploadStatus.Ready) {
      if (this.widget) {
        this.image = {
          type: imageType,
          user: this.user,
          widget: null,
        };
        this.widget.open();
        this._store.dispatch(new UploadId(uploadId));
        this._store.dispatch(new UploadStatus(IUploadStatus.WidgetOpen));
      } else this._snack.error(this._translate.instant('ImageWidgetConfigNotSet'));
    } else {
      this._snack.error(this._translate.instant('UploadInProgress'));
    }
  }

  public loadWidget(
    openNow: boolean,
    resourceType: CloudinaryResourceType,
    uploadId: string,
    imageType: MediaSection,
    multiple: boolean,
    cropping: boolean,
    maxFiles: number,
  ): void {
    //#region Manual settings
    this.resourceType = resourceType;
    this.uploadId = uploadId;
    this.imageType = imageType;
    this.multiple = multiple;
    this.cropping = cropping;
    this.maxFiles = maxFiles;
    //#endregion

    this.createUploadWidget(
      {
        cloudName: settings.cloudName,
        uploadPreset: settings.uploadPreset,
        sources: settings.sources,
        resourceType: this.resourceType,
        googleApiKey: settings.googleApiKey,
        showAdvancedOptions: settings.showAdvancedOptions,
        cropping: cropping,
        multiple: multiple,
        maxFiles: maxFiles,
        showSkipCropButton: settings.showSkipCropButton,
        defaultSource: settings.defaultSource,
        maxImageFileSize: settings.maxImageFileSize,
        maxVideoFileSize: settings.maxVideoFileSize,
        styles: settings.styles,
        folder: this.user?.Email, // settings.folder,
        language: 'default',
        clientAllowedFormats: [this.resourceType === 'video' ? 'video' : ''], // allowed formats
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        text: { default: require(`../../../../assets/i18n/${this._translate.currentLang}.json`).widget },
      },
      (error: any, result: any) => {
        if (result?.event === 'success') {
          this.image.widget = result.info;

          if (this.image.user && this.image.widget) {
            const image: IMedia = {
              Type: this.resourceType === 'video' ? MediaResourceType.Video : MediaResourceType.Image,
              Id: crypto.randomUUID(),
              Section: this.image.type,
              UserId: this.image.user?.Id,
              Url: this.image.widget?.secure_url,
            };

            this._store.dispatch(new UploadMedia(image));
          } else this._snack.error('ImageUploadFailed');
        } else if (!error && result && result.event === 'queues-start') {
          this._store.dispatch(new UploadStatus(IUploadStatus.InProgress));
        } else if (!error && result && result.event === 'queues-end') {
          this._store.dispatch(new UploadStatus(IUploadStatus.Finish));
        } else if (!error && result && result.event === 'abort') {
          this._store.dispatch(new UploadStatus(IUploadStatus.Ready));
        }
      },
    ).subscribe((widget: any) => {
      this.widget = widget;

      if (openNow) {
        this.openWidget(uploadId, imageType);
      }
    });
  }

  private createUploadWidget(data: any, callback: (error: any, result: any) => void): Observable<any> {
    if (this.scriptExists(settings.widgetScript)) {
      return of(cloudinary.createUploadWidget(data, callback));
    } else {
      return fromEvent(this.addJsToElement(settings.widgetScript), 'load').pipe(
        map(() => cloudinary.createUploadWidget(data, callback)),
      );
    }
  }

  private scriptExists(jsUrl: string): boolean {
    return document.querySelector(`script[src="${jsUrl}"]`) ? true : false;
  }

  private addJsToElement(jsUrl: string): HTMLScriptElement {
    const script: any = document.createElement('script');
    script.type = 'text/javascript';
    script.src = jsUrl;
    this.renderer.appendChild(document.body, script);

    return script;
  }
}
