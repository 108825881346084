<div class="container" [ngClass]="!closedAnimation ? 'fade-in-fast' : 'fade-out-fast'">
  <div class="content">

    <div class="image" [@slideAnimation]="currentIndex">
      <img [src]="media[currentIndex].Url">

      <ng-container *ngIf="media.length > 1">
        <button class="prev-button" (click)="prevImage()">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
        <button class="next-button" (click)="nextImage()">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </ng-container>
    </div>

    <div class="background-navigation">
      <div class="left" (click)="prevImage()"></div>
      <div class="right" (click)="nextImage()"></div>
    </div>

    <button class="close-button" (click)="closeMedia();">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
