<ng-container *ngIf="loadFinish && login_Try <= login_TryMax">
  <!-- LOGIN -->
  <div class="box fade-in" *ngIf="mode === 'login'">
    <div class="header">
      <h3>{{ 'Login' | translate }}</h3>
      <mat-error *ngIf="login_Try > 0" [ngClass]="login_Failed ? 'fade-in' : 'fade-out'">{{
        'LoginFailed' | translate
      }}</mat-error>
    </div>

    <auto-form
      [form]="login_Form"
      [disabled]="wait"
      [disableSubmit]="!onLine || wait || !login_AccountValid"
      (valid)="login_AccountValid = $event"
      (formChange)="form_login($event)"
      (formSubmit)="login()"
    ></auto-form>
    <div class="login-actions">
      <auto-form
        [form]="login_RememberMe_Form"
        [disabled]="wait"
        (formChange)="login_RememberMe = $event.RememberMe"
      ></auto-form>
      <a (click)="changeAccountMode('forgot-password')" *ngIf="login_Try >= 2" class="rec-pass scale-in-center"
        >{{ 'RecoveryPassword' | translate }}?</a
      >
      <a (click)="changeAccountMode('sign-up')" *ngIf="login_Try < 2">{{ 'SignUp' | translate }}</a>
    </div>
    <button
      mat-raised-button
      [ngClass]="shakeClass"
      color="primary"
      [disabled]="!onLine || wait || !login_AccountValid"
      (click)="login()"
    >
      <span *ngIf="onLine">{{ 'Login' | translate }}</span>
      <span *ngIf="!onLine">{{ 'InternetConnectionLost' | translate }}</span>
    </button>
  </div>

  <!-- SIGN UP -->
  <div class="box fade-in" *ngIf="mode === 'sign-up'">
    <h3><mat-icon (click)="openLoginMode()">double_arrow</mat-icon>{{ 'SignUp' | translate }}</h3>
    <mat-tab-group [selectedIndex]="signUp_SelectedTab" (selectedTabChange)="tabChanged($event)">
      <!-- Account details -->
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>person</mat-icon>
          {{ 'Account' | translate }}
        </ng-template>
        <auto-form
          [form]="signUp_AccountForm"
          [disabled]="wait"
          [disableSubmit]="wait || !signUp_AccountValid"
          (valid)="signUp_AccountValid = $event"
          (formChange)="signUpForm($event)"
          (formSubmit)="nextTab()"
        ></auto-form>
        <button mat-raised-button color="primary" [disabled]="wait || !signUp_AccountValid" (click)="nextTab()">
          {{ 'Next' | translate }}
        </button>
      </mat-tab>

      <!-- Credentials -->
      <mat-tab [disabled]="!signUp_AccountValid">
        <ng-template mat-tab-label>
          <mat-icon>lock</mat-icon>
          {{ 'Credentials' | translate }}
        </ng-template>
        <auto-form
          [form]="signUp_CredentialEmail"
          [debounceTime]="signUp_EmailCheckingDelay"
          [disableSubmit]="
            !onLine ||
            wait ||
            !signUp_EmailFree ||
            !signUp_CredentialEmailValid ||
            !signUp_CredentialPasswordValid ||
            !signUp_PasswordMatch
          "
          [emitFirstChange]="true"
          (valid)="signUp_CredentialEmailValid = $event"
          (formChange)="signUpCredentialEmail($event)"
          (formSubmit)="nextTab()"
        >
        </auto-form>
        <mat-error *ngIf="signUp_CredentialEmailValid && !signUp_EmailFree" class="email-taken fade-in">{{
          'EmailTaken' | translate
        }}</mat-error>
        <auto-form
          [form]="signUp_CredentialPassword"
          [emitAlways]="true"
          [disabled]="wait"
          [disableSubmit]="
            !onLine ||
            wait ||
            !signUp_EmailFree ||
            !signUp_CredentialEmailValid ||
            !signUp_CredentialPasswordValid ||
            !signUp_PasswordMatch
          "
          (valid)="signUp_CredentialPasswordValid = $event"
          (formChange)="signUpCredentialPassword($event)"
          (formSubmit)="nextTab()"
        >
        </auto-form>
        <ng-container
          *ngTemplateOutlet="
            passwordStrength;
            context: { password: signUp_NewPassword, confirm: signUp_PasswordConfirm, match: signUp_PasswordMatch }
          "
        ></ng-container>
        <button
          mat-raised-button
          color="primary"
          [disabled]="
            !onLine ||
            wait ||
            !signUp_EmailFree ||
            !signUp_CredentialEmailValid ||
            !signUp_CredentialPasswordValid ||
            !signUp_PasswordMatch
          "
          (click)="nextTab()"
        >
          <span *ngIf="onLine">{{ 'Next' | translate }}</span>
          <span *ngIf="!onLine">{{ 'InternetConnectionLost' | translate }}</span>
        </button>
      </mat-tab>

      <!-- Avatar -->
      <mat-tab
        [disabled]="
          !onLine ||
          !signUp_EmailFree ||
          !signUp_CredentialEmailValid ||
          !signUp_CredentialPasswordValid ||
          !signUp_PasswordMatch
        "
      >
        <ng-template mat-tab-label>
          <mat-icon>person_pin</mat-icon>
          {{ 'Avatar' | translate }}
        </ng-template>

        <div class="avatars">
          <ng-container *ngIf="signUp_User.Gender === 'Male'">
            <ng-container
              *ngTemplateOutlet="avatarImg; context: { gender: 'Male', selected: this.signUp_User.Avatar }"
            ></ng-container>
          </ng-container>

          <ng-container *ngIf="signUp_User.Gender === 'Female'">
            <ng-container
              *ngTemplateOutlet="avatarImg; context: { gender: 'Female', selected: this.signUp_User.Avatar }"
            ></ng-container>
          </ng-container>

          <ng-container *ngIf="signUp_User.Gender === 'NonBinary'">
            <ng-container
              *ngTemplateOutlet="avatarImg; context: { gender: 'NonBinary', selected: this.signUp_User.Avatar }"
            ></ng-container>
          </ng-container>
        </div>

        <button
          mat-raised-button
          [disabled]="
            !onLine ||
            wait ||
            !signUp_EmailFree ||
            !signUp_CredentialEmailValid ||
            !signUp_CredentialPasswordValid ||
            !signUp_PasswordMatch ||
            !signUp_User.Avatar
          "
          color="primary"
          (click)="signUp()"
        >
          <ng-container *ngIf="onLine">
            <span *ngIf="wait">{{ 'PleaseWait' | translate }}</span>
            <span *ngIf="!wait">{{ 'SignUp' | translate }}</span>
          </ng-container>
          <ng-container *ngIf="!onLine">
            <span>{{ 'InternetConnectionLost' | translate }}</span>
          </ng-container>
        </button>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- FORGOT PASSWORD -->
  <div class="box fade-in" *ngIf="mode === 'forgot-password'">
    <h3><mat-icon (click)="openLoginMode()">double_arrow</mat-icon>{{ 'ForgotPassword' | translate }}</h3>
    <p>{{ 'ForgotPasswordDesc' | translate }}</p>
    <auto-form
      [form]="forgotPassword_Form"
      [disabled]="wait"
      [disableSubmit]="!onLine || wait || !forgotPassword_AccountValid"
      (valid)="forgotPassword_AccountValid = $event"
      (formChange)="forgotPassword_UserEmail = $event.Email"
      (formSubmit)="forgotPassword()"
    >
    </auto-form>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!onLine || wait || !forgotPassword_AccountValid"
      (click)="forgotPassword()"
    >
      <span *ngIf="onLine">{{ 'SendMeResetLink' | translate }}</span>
      <span *ngIf="!onLine">{{ 'InternetConnectionLost' | translate }}</span>
    </button>
  </div>

  <!-- RESET PASSWORD -->
  <div class="box fade-in" *ngIf="mode === 'reset-password' || mode === 'reset-password-profile'">
    <h3><mat-icon (click)="resetPasswordBack()">double_arrow</mat-icon>{{ 'ResetPassword' | translate }}</h3>
    <auto-form
      [form]="resetPassword_Form"
      [emitAlways]="true"
      [disabled]="wait"
      [disableSubmit]="!onLine || wait || !resetPassword_AccountValid || !resetPassword_Match"
      (valid)="resetPassword_AccountValid = $event"
      (formChange)="form_ResetPassword($event)"
      (formSubmit)="resetPassword()"
    >
    </auto-form>
    <ng-container
      *ngTemplateOutlet="
        passwordStrength;
        context: {
          password: resetPassword_NewPassword,
          confirm: resetPassword_PasswordConfirm,
          match: resetPassword_Match
        }
      "
    ></ng-container>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!onLine || wait || !resetPassword_AccountValid || !resetPassword_Match"
      (click)="resetPassword()"
    >
      <span *ngIf="onLine">{{ 'ResetPassword' | translate }}</span>
      <span *ngIf="!onLine">{{ 'InternetConnectionLost' | translate }}</span>
    </button>
  </div>

  <!-- USER PROFILE -->
  <div class="box fade-in" *ngIf="mode === 'profile'">
    <div class="header">
      <h3>
        <mat-icon *ngIf="!profile_AvatarChooser" class="no-rot">person</mat-icon>
        <mat-icon *ngIf="profile_AvatarChooser" (click)="profile_AvatarChooser = false">double_arrow</mat-icon>
        {{ 'UserProfile' | translate }}
      </h3>
      <div>
        <mat-icon
          *ngIf="user.Id"
          class="fade-in" [ngClass]="subscription_StillValid ? 'user-subscription' : 'user-subscription-expired'"
          (click)="changeModeToSubscription();">
          <ng-container *ngIf="subscription_StillValid">shopping_cart</ng-container>
          <ng-container *ngIf="!subscription_StillValid">production_quantity_limits</ng-container>
          </mat-icon
        >
        <img
          *ngIf="user.Avatar"
          class="fade-in"
          src="../../assets/avatars/{{ user.Avatar }}.png"
          (click)="openProfileMode(true)"
        />
        <mat-icon
          *ngIf="user.Id && !user.Avatar"
          class="fade-in user-undefined"
          (click)="profile_AvatarChooser = !profile_AvatarChooser"
          >account_circle</mat-icon
        >
      </div>
    </div>
    <ng-container *ngIf="profile_Form.length">
      <div *ngIf="profile_AvatarChooser" class="fade-in avatars">
        <ng-container
          *ngTemplateOutlet="avatarImg; context: { gender: user.Gender, selected: profile_AvatarChange }"
        ></ng-container>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!onLine || wait || !profile_Valid || !profile_AvatarSelected"
          (click)="profileChangeAvatar()"
        >
          <span *ngIf="onLine">{{ 'ChangeAvatar' | translate }}</span>
          <span *ngIf="!onLine">{{ 'InternetConnectionLost' | translate }}</span>
        </button>
      </div>
      <div *ngIf="!profile_AvatarChooser" class="fade-in">
        <auto-form
          *ngIf="profile_Form.length"
          [form]="profile_Form"
          [disableSubmit]="!onLine || wait || !profile_Valid"
          (valid)="profile_Valid = $event"
          (formChange)="form_Profile($event)"
          (formSubmit)="updateProfile(true)"
        ></auto-form>
        <div
          *ngIf="!user.Verified"
          class="email-verification"
          (click)="sendEmailVerification(user.FirstName, user.Email, user.Id)"
        >
          <a><mat-icon>email</mat-icon>{{ 'EmailNotVerified' | translate }}</a>
        </div>
      <div
        *ngIf="user.Role === 0"
        class="admin-urls"
      >
      <a (click)="openEmailOfferDocument();"><mat-icon>outgoing_mail</mat-icon>{{ 'EmailOffer' | translate }}</a>
        <a [href]="pitchDeckURL"><mat-icon>business_center</mat-icon>{{ 'PitchDeck' | translate }}</a>
      </div>
        <div class="profile-actions">
          <button mat-raised-button color="primary" (click)="openResetPasswordProfileMode()">
            <span *ngIf="onLine">{{ 'ResetPassword' | translate }}</span>
            <span *ngIf="!onLine">{{ 'InternetConnectionLost' | translate }}</span>
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="!onLine || wait || !profile_Valid || profile_UpdateDisabled || !user.Avatar"
            (click)="updateProfile(true)"
          >
            <span *ngIf="onLine">{{ 'UpdateProfile' | translate }}</span>
            <span *ngIf="!onLine">{{ 'InternetConnectionLost' | translate }}</span>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!profile_Form.length">
      <ng-container *ngIf="!user?.Id; else waitBox">
        <h3>{{ 'PleaseLoginToLookProfile' | translate }}</h3>
        <button mat-raised-button color="primary" (click)="openLoginMode()">{{ 'Login' | translate }}</button>
      </ng-container>
    </ng-container>
  </div>

  <!-- SUBSCRIPTION -->
  <div class="box fade-in" *ngIf="mode === 'subscription'">
    <div class="header">
      <h3><mat-icon class="no-rot">shopping_cart</mat-icon>{{ 'Subscription' | translate }}</h3>
      <img *ngIf="user.Avatar" class="fade-in" src="../../assets/avatars/{{ user.Avatar }}.png" (click)="changeModeToProfile();"/>
      <mat-icon
        *ngIf="user.Id && !user.Avatar"
        class="fade-in user-undefined"
        (click)="profile_AvatarChooser = !profile_AvatarChooser;"
        >account_circle</mat-icon
      >
    </div>
    <ng-container *ngIf="subscription">
      <div class="sub-header">
        {{ 'SubscriptionPackage' | translate }}: <b>{{ subscription.Package }}</b>
      </div>
      <div *ngIf="subscription.SubDomain.length">
        {{ 'SubDomain' | translate }}:
        <a [href]="subscription_SubDomainURL" target="_blank">{{ subscription.SubDomain }}</a>
      </div>
      <div *ngIf="subscription_StillValid">
        {{ 'SubscriptionValidUntil' | translate }}: {{ subscription.ValidUntil | date : _units.dateFormat }}
      </div>
      <div *ngIf="!subscription_StillValid" style="color: red;">
        {{ 'SubscriptionExpired' | translate }}: <b>{{ subscription.ValidUntil | date : _units.dateFormat }}</b>
      </div>
      <div *ngIf="subscription.Renewal">{{ 'SubscriptionRenewal' | translate }}: {{ 'TurnedOn' | translate }}</div>
      <hr />
      <ng-container *ngIf="subscription_StillValid">
        <div class="sub-header">
          <b>{{ 'SubscriptionIncludedServices' | translate }}:</b>
        </div>
          <div *ngFor="let service of subscription_Package?.include" [matTooltip]="service + '_Desc' | translate" style="cursor: help;">
            {{ service | translate }}
          </div>
      </ng-container>
      <br />
      <button mat-raised-button color="primary" (click)="openSubscriptionPackages()">
        <span *ngIf="subscription_StillValid">{{ 'ExtendLicense' | translate }}</span>
        <span *ngIf="!subscription_StillValid">{{ 'Subscribe' | translate }}</span>
      </button>
    </ng-container>
    <ng-container *ngIf="!subscription">
      <div class="no-subscription">{{ 'NoSubscription' | translate }}</div>
      <button mat-raised-button color="primary" (click)="openSubscriptionPackages()">
        {{ 'Subscribe' | translate }}
      </button>
    </ng-container>
  </div>

  <!-- PASSWORD CHANGED -->
  <div class="box fade-in" *ngIf="mode === 'password-changed'">
    <ng-container
      *ngTemplateOutlet="
        result;
        context: {
          result: resetPassword_ChangedSuccessfully,
          successTitle: 'PasswordChanged',
          failTitle: 'PasswordNotChanged'
        }
      "
    ></ng-container>
  </div>

  <!-- ACCOUNT VERIFIED -->
  <div class="box fade-in" *ngIf="mode === 'account-verified'">
    <ng-container
      *ngTemplateOutlet="
        result;
        context: {
          result: signUp_EmailVerifiedSuccessfully,
          successTitle: 'AccountVerified',
          failTitle: 'AccountNotVerified'
        }
      "
    ></ng-container>
  </div>

  <!-- ACCOUNT BLOCKED -->
  <div class="box fade-in" *ngIf="mode === 'blocked'">
    <ng-container
      *ngTemplateOutlet="result; context: { result: false, failTitle: 'AccountBlocked', blockLogin: true }"
    ></ng-container>
  </div>

  <!-- EMAIL SENT -->
  <div class="box fade-in" *ngIf="mode === 'email-sent'">
    <ng-container
      *ngTemplateOutlet="
        result;
        context: {
          result: _email.status,
          emailCheck: true,
          successTitle: 'EmailSentCheckJunk',
          failTitle: 'AccountVerificationEmailNotSent'
        }
      "
    ></ng-container>
  </div>
</ng-container>

<!-- INIT WAIT -->
<div class="box fade-in" *ngIf="!loadFinish">
  <ng-container *ngTemplateOutlet="waitBox; context: { title: 'PleaseWait' }"></ng-container>
</div>

<!-- TOO MANY LOGIN ATTEMPTS -->
<div class="box fade-in" *ngIf="login_Try > login_TryMax">
  <ng-container *ngTemplateOutlet="waitBox; context: { title: 'TooManyFailedAttempts' }"></ng-container>
</div>

<!-- T E M P L A T E S ------------------------------------------------------------------------------------------------------ -->

<!-- PASSWORD STRENGTH TEMPLATE -->
<ng-template #passwordStrength let-password="password" let-confirm="confirm" let-match="match">
  <div class="password-strength">
    <div>
      <span
        *ngIf="!password || !confirm || password?.length < passwordMinLength || confirm?.length < passwordMinLength"
        class="fade-in"
        >{{ 'PasswordCreationHint' | translate }}</span
      >
      <span
        *ngIf="password?.length >= passwordMinLength && confirm?.length >= passwordMinLength && !match"
        class="fade-in"
        >{{ 'PasswordsNotMatch' | translate }}</span
      >
      <span
        *ngIf="password?.length >= passwordMinLength && confirm?.length >= passwordMinLength && match"
        class="fade-in stronges-{{ password }}"
      >
        <span *ngIf="passwordStrong(password, match) === 100">{{ 'PasswordVeryStrong' | translate }}</span>
        <span *ngIf="passwordStrong(password, match) === 80">{{ 'PasswordStrong' | translate }}</span>
        <span *ngIf="passwordStrong(password, match) === 60">{{ 'PasswordMedium' | translate }}</span>
        <span *ngIf="passwordStrong(password, match) === 30">{{ 'PasswordWeak' | translate }}</span>
      </span>
      <span>
        <mat-icon *ngIf="match">lock</mat-icon>
        <mat-icon *ngIf="!match">no_encryption_gmailerrorred</mat-icon>
      </span>
    </div>
    <mat-progress-bar mode="determinate" [color]="'primary'" [value]="passwordStrong(confirm, match)" [bufferValue]="0">
    </mat-progress-bar>
  </div>
</ng-template>

<!-- AVATAR TEMPLATE -->
<ng-template #avatarImg let-gender="gender" let-selected="selected">
  <ng-container *ngFor="let i of profile_AvatarsPack">
    <img
      class="fade-in"
      src="../../assets/avatars/{{ gender === 'Male' ? 'M' : gender === 'Female' ? 'F' : i % 2 === 0 ? 'M' : 'F' }}{{
        i
      }}.png"
      [ngClass]="'M' + i === selected || 'F' + i === selected ? 'selected' : ''"
      (click)="setAvatar(gender, i)"
    />
  </ng-container>
  <div class="load-more-avatars">
    <span
      *ngFor="let avatar of [].constructor(5); let i = index"
      (click)="avatarsPack(i)"
      [ngClass]="profile_AvatarsPackPage === i ? 'page' : ''"
      >{{ i + 1 }}</span
    >
  </div>
</ng-template>

<!-- RESULT TEMPLATE (password changed, account verified, account blocked, email sent)-->
<ng-template
  #result
  let-result="result"
  let-emailCheck="emailCheck"
  let-successTitle="successTitle"
  let-failTitle="failTitle"
  let-blockLogin="blockLogin"
>
  <div class="center">
    <!-- Content view -->
    <ng-container *ngIf="!emailCheck">
      <ng-container *ngIf="result">
        <mat-icon class="big success">check_circle</mat-icon>
        <h3>{{ successTitle | translate }}</h3>
      </ng-container>

      <ng-container *ngIf="!result">
        <mat-icon class="big fail">cancel</mat-icon>
        <h3>{{ failTitle | translate }}</h3>
      </ng-container>
      <button
        mat-raised-button
        *ngIf="!blockLogin"
        color="primary"
        [disabled]="result === 'sending'"
        (click)="openLoginMode()"
      >
        {{ 'Login' | translate }}
      </button>
    </ng-container>

    <!-- Email view -->
    <ng-container *ngIf="emailCheck">
      <div *ngIf="result === 'sending'" class="center fade-in">
        <mat-icon class="big wait rotate-center">autorenew</mat-icon>
        <h3>{{ 'EmailSending' | translate }}</h3>
      </div>

      <div *ngIf="result === 'sent'" class="center fade-in">
        <mat-icon class="big success">mark_email_read</mat-icon>
        <h3>{{ successTitle | translate }}</h3>
      </div>

      <div *ngIf="result === 'failed'" class="center fade-in">
        <mat-icon class="big wait">unsubscribe</mat-icon>
        <h3>{{ failTitle | translate }}</h3>
      </div>

      <!-- Email in state sending or sent -->
      <button
        mat-raised-button
        *ngIf="!blockLogin && (result === 'sending' || result === 'sent')"
        color="primary"
        [disabled]="result === 'sending'"
        (click)="openLoginMode()"
      >
        {{ 'Login' | translate }}
      </button>

      <!-- Email sending failed -->
      <button mat-raised-button *ngIf="!blockLogin && result === 'failed'" color="primary" (click)="sendEmailAgain()">
        <mat-icon>send</mat-icon>{{ 'SendAgain' | translate }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- WAIT TEMPLATE -->
<ng-template #waitBox let-title="title">
  <div class="center fade-in">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <h3>{{ title | translate }}</h3>
  </div>
</ng-template>

<!--------------------------------------------------------------------------------------------------------------------------- -->
