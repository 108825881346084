import { ElasticEmailService } from './providers/elastic-emai.service';
import { EnvironmentService } from '@environments/environment.service';
import { IEmail, IEmailStatus } from './models/email.interface';
import { SendGridService } from './providers/send-grid.service';
import { DefaultTemplate } from './templates/default-template';
import { EmailJSService } from './providers/email-js.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, Injector } from '@angular/core';

@Injectable()
export class EmailService {
  public email: IEmail = {} as any;
  public status: IEmailStatus = IEmailStatus.Ready;

  constructor(private _injector: Injector, private _translate: TranslateService) {
    window.addEventListener('emailStatus', (event: Event) => {
        const customEvent = event as CustomEvent;
        this.status = customEvent.detail.status;
    });
  }

  public sendEmail(email: IEmail): void {
    if(this.status !== IEmailStatus.Sending){
        this.status = IEmailStatus.Sending;
        email.subject = this._translate.instant(email.subject);
        email.body = this._translate.instant(email.body);

        if(!this.isHtmlString(email.body)){ // Apply default email template
            email.body = DefaultTemplate(email, this._translate);
        }

        const provider: string = EnvironmentService.getProp('email|providerInUse');
        this.email = email;
        let service: any;

        switch(provider) {
            case 'sendGrid': service = this._injector.get(SendGridService, new SendGridService()); break;
            case 'emailJS': service = this._injector.get(EmailJSService, new EmailJSService()); break;
            case 'elasticEmail': service = this._injector.get(ElasticEmailService, new ElasticEmailService()); break;
         }
        
        if(service){ service.sendEmail(email); }
        else { console.error('No email service provider!'); }

    } else { console.warn('Email service is busy: request refused as an email is currently being sent.'); }
  }

  public trySendEmailAgain(): void {
    this.sendEmail(this.email);
  }

  public isHtmlString(str: string): boolean {
    const regex = /<\/?[a-z][\s\S]*>/i;
    return regex.test(str);
  }
}
