import { URLService } from '@app/shared/services/url.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(public _url: URLService) {}
  public ngOnInit(): void {}

  public scrollUp(): void {
    document.getElementById('scrollup')?.click();
  }
}
