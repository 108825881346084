import { IUser } from '@shared/models/user.interface';

export class SetToken {
  public static readonly type: string = '[SetToken] User token set';
  constructor(public user: IUser) {}
}
export class Logout {
  public static readonly type: string = '[Logout] User logout';
}

export class IFrameLogin {
  public static readonly type: string = '[IFrameLogin] Login using companyId';
  constructor(public companyId: string) {}
}
