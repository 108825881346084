import { Page } from '@app/core/config/routes-permissions.config';
import { UsersState } from '../account/store-user/users.state';
import { URLService } from '@app/shared/services/url.service';
import { LoadingState } from './store-loading/loading.state';
import { Logout } from '../account/store-auth/auth.actions';
import { IUser } from '@app/shared/models/user.interface';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppMenu } from './interfaces/menu.interface';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class AppMenuComponent implements OnInit {
  @Select(UsersState.selectUser) user$!: Observable<IUser | null>;
  @Select(LoadingState.loading) loading$!: Observable<boolean>;

  @Input() navigation: AppMenu[] | undefined;
  @Input() showLogo: boolean = true;

  public navigationDefault: AppMenu[] = [
    {
      title: 'Login',
      url: undefined,
      icon: 'lock',
      children: [
        {
          title: 'Login',
          url: 'Login',
          icon: 'password',
        },
        {
          title: 'BackOffice',
          url: 'BackOffice',
          icon: 'format_list_bulleted',
        },
      ],
    },
  ];

  public progress: boolean = false;

  constructor(private _url: URLService, public _translate: TranslateService, private _store: Store) {}
  public ngOnInit(): void {
    if (!this.navigation) {
      this.navigation = this.navigationDefault;
    }
  }

  public changeLang(lang: string): void {
    localStorage.setItem('lang', lang);
    this._translate.use(lang);
  }

  public logout(): void {
    this._store.dispatch(new Logout());
  }

  public navigate(url: Page | undefined): void {
    if (url && url.length > 1) {
      this._url.setURL(url);
    }
  }
}
