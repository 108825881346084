<div class="count-down-timer fade-in" [style.background-color]="background ? background : ''" [style.color]="color ? color : ''">

  <p><strong>{{header | translate}} {{
    targetDate | date: 'dd-MMM-yy HH:mm' | 
    replace: 'Jan': ('Jan' | translate) |
    replace: 'Feb': ('Feb' | translate) |
    replace: 'Mar': ('Mar' | translate) |
    replace: 'Apr': ('Apr' | translate) |
    replace: 'May': ('May' | translate) |
    replace: 'Jun': ('Jun' | translate) |
    replace: 'Jul': ('Jul' | translate) |
    replace: 'Avg': ('Avg' | translate) |
    replace: 'Sep': ('Sep' | translate) |
    replace: 'Oct': ('Oct' | translate) |
    replace: 'Nov': ('Nov' | translate) |
    replace: 'Dec': ('Dec' | translate)
  }}</strong></p>

  <div class="wrapper">
    <div class="description">
      <p>{{'Days' | translate}}</p>
      <p>{{'Hours' | translate}}</p>
      <p>{{'Minutes' | translate}}</p>
      <p>{{'Seconds' | translate}}</p>
    </div>
    <div class="times">
      <p #days></p>
      <p #hours></p>
      <p #minutes></p>
      <p #seconds></p>
    </div>
  </div>

</div>
