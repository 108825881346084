import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Loading } from './loading.actions';
import { Injectable } from '@angular/core';

export class LoadingStateModel {
  loading: boolean = false;
}
@State<LoadingStateModel>({
  name: 'loadingState',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class LoadingState {
  @Selector()
  public static loading(state: LoadingStateModel): boolean {
    return state.loading;
  }

  @Action(Loading)
  public Loading(ctx: StateContext<LoadingStateModel>, { loading }: Loading): void {
    ctx.patchState({
      loading: loading,
    });
  }
}
