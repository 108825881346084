export interface IUser {
  Id: string;
  Active: boolean;
  FirstName: string;
  LastName: string;
  Email: string;
  Gender: Gender;
  Avatar?: string;
  BirthDate: Date;
  State: string;
  City: string;
  Role: IRole;
  Created: Date;
  Verified: boolean;
  LastActivity: Date;
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  NonBinary = 'NonBinary',
}

export enum IRole {
  /** System Owner */
  SuperAdmin,

  /** System Administrator */
  Admin,

  /** Client who pay for service */
  Client,

  /** Client who create account */
  Visitor,
}
