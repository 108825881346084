import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'core-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnDestroy {
  // Inputs
  @Input() close: boolean = true; // Close button
  @Input() show: boolean = true; // Show dialog
  @Input() header: string | undefined; // Header title

  @Input() width: string | undefined;
  @Input() minWidth: string | undefined;
  @Input() maxWidth: string | undefined;

  @Input() height: string | undefined;
  @Input() minHeight: string | undefined;
  @Input() maxHeight: string | undefined;

  // Outputs
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Init
  public closeTrigger: boolean | undefined;
  public showDialog: boolean | undefined;

  constructor(private renderer: Renderer2){
    this.blockScroll();
  }

  public ngOnDestroy(): void {
    this.enableScroll();
  }

  //#region Methods

  public closeDialogStart(): void {
    this.closeTrigger = true;
    this.closeDialogEnd();
  }

  private closeDialogEnd(): void {
    this.closeTrigger = true;

    setTimeout(() => {
      this.closeTrigger = false;
      this.closed.emit(true);
      this.showDialog = false;
      this.enableScroll();
    }, 300);
  }

  private enableScroll() {
    this.renderer.removeStyle(document.body, 'overflow');
  }

  private blockScroll() {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  public bgClickHandler(event: any): void {
    if(event.target.id === 'dialog-bg') {
      this.closeDialogStart();
    }
  }

  //#endregion
}
