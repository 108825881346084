import { ISubscription } from '@app/core/components/subscription/interfaces/subscription.interface';
import { AddSubscription } from '@app/core/components/subscription/store/subscription.actions';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { InternetStatusService } from '@interceptor/Internet-status.service';
import { FirestoreCollection } from '@config/firestore.collections.config';
import { catchError, first, from, map, Observable, of } from 'rxjs';
import { URLService } from '@services/url.service';
import { IToken } from '../models/token.interface';
import { IUser } from '@models/user.interface';
import { Injectable } from '@angular/core';
import 'firebase/firestore';

@Injectable({ providedIn: 'root' })
export class ApiAccountService {
  constructor(private db: AngularFirestore, private _url: URLService, private _net: InternetStatusService) {}
  //#region AUTHORIZATION

  public login(email: string, password: string): Observable<IToken | null> {
    if (this._net.isOnline()) {
      return this.db
        .collection<IToken[]>(FirestoreCollection.Token, (ref: any) =>
          ref.where('Email', '==', email).where('Password', '==', password),
        )
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? x[0] : null)));
    } else return of(null);
  }

  //#endregion

  //#region USER

  public getUsers(): Observable<IUser[] | null> {
    if (this._net.isOnline()) {
      return <Observable<IUser[]>>this.db.collection(FirestoreCollection.User).valueChanges().pipe(first());
    } else return of(null);
  }

  public getUserById(id: string): Observable<IUser | null> {
    if (this._net.isOnline()) {
      return this.db
        .collection(FirestoreCollection.User, (ref: any) => ref.where('Id', '==', id))
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? x[0] : null)));
    } else return of(null);
  }

  public getUserByEmail(email: string): Observable<IUser | null> {
    if (this._net.isOnline()) {
      return this.db
        .collection(FirestoreCollection.User, (ref: any) => ref.where('Email', '==', email))
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? x[0] : null)));
    } else return of(null);
  }

  public isUserEmailFree(email: string): Observable<boolean> {
    if (this._net.isOnline()) {
      return this.db
        .collection(FirestoreCollection.User, (ref: any) => ref.where('Email', '==', email))
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? false : true)));
    } else return of(false);
  }

  public getTokenByUserId(id: string): Observable<IToken | null> {
    if (this._net.isOnline()) {
      return this.db
        .collection(FirestoreCollection.Token, (ref: any) => ref.where('Id', '==', id))
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? x[0] : null)));
    } else return of(null);
  }

  public getUserByIdEmailCreated(userId: string, userEmail: string, userCreated: Date): Observable<IUser | null> {
    if (this._net.isOnline()) {
      return this.db
        .collection<IUser[]>(FirestoreCollection.User, (ref: any) =>
          ref.where('Id', '==', userId).where('Email', '==', userEmail).where('Created', '==', userCreated),
        )
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? x[0] : null)));
    } else return of(null);
  }

  public getUserByCompanyId(companyId: string): Observable<IUser | null> {
    if (this._net.isOnline()) {
      return this.db
        .collection<IUser[]>(FirestoreCollection.User, (ref: any) => ref.where('Id', '==', companyId))
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? x[0] : null)));
    } else return of(null);
  }

  public getLoggedUser(): Observable<IUser | null> {
    const token: string | null = localStorage.getItem('token');

    if (token) {
      const user: IUser = JSON.parse(atob(token));

      return this.getUserById(user.Id);
    } else return of(null);
  }

  public createUser(user: IUser, token: IToken): Observable<boolean> {
    if (this._net.isOnline()) {
      const userCollection: AngularFirestoreCollection<IUser> = this.db.collection<IUser>(FirestoreCollection.User);
      const tokenCollection: AngularFirestoreCollection<IToken> = this.db.collection<IToken>(FirestoreCollection.Token);
      const saveUser: any = {
        ...user,
        Created: new Date().toISOString(),
        LastActivity: new Date().toISOString(),
        BirthDate: new Date(user.BirthDate).toISOString().split('T')[0],
      };

      return from(
        userCollection
          .doc(saveUser.Id.toString())
          .set(saveUser)
          .then(() => {
            return tokenCollection.doc(token.Id.toString()).set(token);
          }),
      ).pipe(
        map(() => true),
        catchError(() => of(false)),
      );
    } else return of(false);
  }

  public updateUser(user: IUser): Observable<boolean> {
    if (this._net.isOnline()) {
      const saveUser: any = {
        ...user,
        BirthDate: new Date(user.BirthDate).toISOString().split('T')[0],
        LastActivity: new Date().toISOString(),
      };

      return from(
        this.db
          .doc(`${FirestoreCollection.User}/${saveUser.Id}`)
          .update(saveUser)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          }),
      );
    } else return of(false);
  }

  public updateCredentials(token: IToken): Observable<boolean> {
    if (this._net.isOnline()) {
      return from(
        this.db
          .doc(`${FirestoreCollection.Token}/${token.Id}`)
          .update(token)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          }),
      );
    } else return of(false);
  }

  public getUsersCount(): Observable<number> {
    if (this._net.isOnline()) {
      return this.db
        .collection<IUser[]>(FirestoreCollection.User)
        .valueChanges()
        .pipe(first())
        .pipe(
          map((x: any[]) => {
            return x.length + 1;
          }),
        );
    } else return of(-1);
  }

  //#endregion
}
