import { GetConfig, SetChatMode } from '@app/core/components/chat-support/store-chat/support/chat-support.actions';
import { IChatMode } from '@app/core/components/chat-support/models/chat-mode.interface';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { IFrameLogin, Logout, SetToken } from './auth.actions';
import { SecurityService } from '@environments/security.service';
import { ApiAccountService } from '../api/api.account.service';
import { URLService } from '@shared/services/url.service';
import { IUser } from '@shared/models/user.interface';
import { SetUser } from '../store-user/users.actions';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';

export class AuthStateModel {
  auth: IToken = {} as IToken;
}
export interface IToken {
  token: string | null;
  valid: string | null | undefined;
}

@State<AuthStateModel>({
  name: 'authState',
  defaults: {
    auth: SecurityService.getToken() ?? ({} as IToken),
  },
})
@Injectable()
export class AuthState {
  constructor(private _store: Store, private _apiAccount: ApiAccountService, private _url: URLService) {}

  @Selector()
  public static getToken(state: AuthStateModel): string | null {
    return state.auth.token;
  }

  @Action(SetToken)
  public setToken(ctx: StateContext<AuthStateModel>, { user }: SetToken): void {
    ctx.patchState({
      auth: SecurityService.setTokenFromUser(user),
    });
  }

  @Action(Logout)
  public removeToken(ctx: StateContext<AuthStateModel>): void {
    // const user: IUser | null = this._store.selectSnapshot(UsersState.selectUser);
    this._store.dispatch(new SetChatMode(IChatMode.Public));
    this._store.dispatch(new GetConfig());
    
    ctx.patchState({
      auth: {} as IToken,
    });

    this._store.dispatch(new SetUser({} as IUser));
    this._url.setURL('Home');
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.removeItem('token');
    // sessionStorage.removeItem('support');
  }

  @Action(IFrameLogin)
  public iFrameLogin(ctx: StateContext<AuthStateModel>, { companyId }: IFrameLogin): void {
    this._apiAccount
      .getUserByCompanyId(companyId)
      .pipe(take(1))
      .subscribe((user: IUser | null) => {
        if (user?.Active) {
          this._store.dispatch(new SetChatMode(IChatMode.Integrated));
          setTimeout(() => {
            this._store.dispatch(new GetConfig(user));
          }, 200);
        }
      });
  }
}
