import { Action, Selector, State, StateContext } from '@ngxs/store';
import { environment } from '@environments/environment';
import { BadgeVisible } from './app.actions';
import { Injectable } from '@angular/core';

export class AppStateModel {
  /** App URL forwarder for mobile app installation (android, ios, huawei) */
  badge: boolean | undefined;
}

@State<AppStateModel>({
  name: 'appState',
  defaults: {
    badge: environment.app.showBadge ? undefined : false,
  },
})
@Injectable()
export class AppState {
  @Selector()
  public static badge(state: AppStateModel): boolean | undefined {
    return state.badge;
  }

  @Action(BadgeVisible)
  public badge(ctx: StateContext<AppStateModel>, { visible }: BadgeVisible): void {
    ctx.patchState({ badge: visible });
  }
}
