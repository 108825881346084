export interface IChatSupport {
  /** ISO string */
  Started: string;
  UserId?: string;
  Image: string;
  Gender: 'Male' | 'Female';
  Messages: IChatMessage[];
  /** The percentage of exchanged messages with technical support in accordance with the daily limit */
  Usage: number;
}

/** This is request for OpenAI - they use lower case properties. We use the same model for our collection in order to avoid confusion during maintenance */
export interface IChatMessage {
  /** User role represents the end user or the person interacting with the system. It typically contains the messages sent by the user in the conversation.
   * "Assistant" role represents the AI model or the system providing responses. It includes the messages generated by the model in the conversation.
   * "System" role is used for special messages that provide instructions or context within the conversation.
   * It can be used for system prompts, welcome messages, or any other system-generated content.
   * "Break" isn't supported on OpenAI, it's ours property that define when conversation starts. */
  role: 'user' | 'system' | 'assistant' | 'break';

  /** The contents of the message. content is required for all messages except assistant messages with function calls. */
  content: string;
}

export interface IChatMessageStream {
  Messages: string;
}

export enum ILocaleCRUD {
  Create,
  Read,
  Update,
  Delete,
}

export interface IQueryString {
  /** companyId is userId in our data base. That's because we are dealing just with companies not with end users. When we put companyId in query string it's looks more official */
  companyId: string;

  /** This is user of company (our user) */
  userId?: string;

  name?: string;
  license?: string;
  theme?: string;
  open?: string;

  prompt: string;
}
