import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { IChatConfig } from '@app/features/back-office/interfaces/chat-config.interface';
import { IChatMessage, IChatMessageStream } from '../models/chat-support.interface';
import { InternetStatusService } from '@interceptor/Internet-status.service';
import { catchError, first, from, map, Observable, of, single } from 'rxjs';
import { FirestoreCollection } from '@config/firestore.collections.config';
import { EnvironmentService } from '@environments/environment.service';
import { AppService } from '@app/shared/services/app.service';
import { Injectable } from '@angular/core';
import 'firebase/firestore';

@Injectable()
export class ApiChatSupportService {
  constructor(private db: AngularFirestore, private _net: InternetStatusService, private _app: AppService) {}

  public updateSupportSession(messages: IChatMessage[], userId: string, endUserId: string): Observable<boolean> {
    const supportCollection: AngularFirestoreCollection<IChatMessageStream> = this.db.collection<IChatMessageStream>(
      FirestoreCollection.Support,
    );

    const id: string = `${userId}|${endUserId}`;

    const msg: IChatMessageStream = {
      Messages: JSON.stringify(messages),
    };

    return from(supportCollection.doc(id).set(msg)).pipe(
      single(),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  public getSupportSession(userId: string, endUserId: string): Observable<IChatMessage[]> {
    if (this._net.isOnline()) {
      const id: string = `${userId}|${endUserId}`;

      return this.db
        .collection(FirestoreCollection.Support)
        .doc(id)
        .valueChanges()
        .pipe(
          first(),
          map((support: any) => {
            if (support && support.Messages?.length) {
              return JSON.parse(support.Messages);
            } else {
              return [];
            }
          }),
          catchError(() => of([])),
        );
    } else {
      return of([]);
    }
  }

  public getConfig(userId: string): Observable<IChatConfig> {
    // const configSession: string | null = sessionStorage.getItem(userId);

    // if (configSession) {
    //   const config: any = JSON.parse(EnvironmentService.decryptTextAES(configSession));
    //   config.Themes = JSON.parse(config.Themes || '{}');
    //   config.APIKey = EnvironmentService.decryptTextAES(config.APIKey); // ATTENTION!
    //   return of(config);

    // } else {
      if (this._net.isOnline()) {
        return this.db
          .collection(FirestoreCollection.Config)
          .doc(userId)
          .valueChanges()
          .pipe(
            first(),
            map((config: any) => {
              if(config){
                sessionStorage.setItem(userId, EnvironmentService.encryptTextAES(this._app.stringifyWithUTF8(config)));
                config.APIKey = EnvironmentService.decryptTextAES(config.APIKey);
  
                if (config.Themes) {
                  config.Themes = JSON.parse(config.Themes);
                }
              }

              return config;
            }),
            catchError(() => of({} as IChatConfig)),
          );
      } else {
        return of({} as IChatConfig);
      }
    // }
  }

  public updateConfig(config: IChatConfig, userId: string): Observable<boolean> {
    const chatConfig: AngularFirestoreCollection<IChatConfig> = this.db.collection<IChatConfig>(
      FirestoreCollection.Config,
    );
    const params: any = this._app.removeUndefinedProperties(config);
    params.APIKey = EnvironmentService.encryptTextAES(params.APIKey);
    params.Domain = params.Domain.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/)[1];

    if (params['Themes']) {
      params.Themes = JSON.stringify(params.Themes);
    }

    return from(chatConfig.doc(userId).set(params)).pipe(
      single(),
      map(() => true),
      catchError(() => of(false)),
    );
  }
}
