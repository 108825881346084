import { appStage, IAppStage } from '@app/core/config/environment.config';
import { SubscriptionState } from '@app/core/components/subscription/store/subscription.state';
import { UsersState } from '../account/store-user/users.state';
import { URLService } from '@app/shared/services/url.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Logout } from '../account/store-auth/auth.actions';
import { IUser } from '@app/shared/models/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Select, Store } from '@ngxs/store';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(UsersState.selectUser) user$!: Observable<IUser | null>;
  @Select(SubscriptionState.isSubscriptionValid) subscriptionValid$!: Observable<boolean | null | undefined>;
  
  public environment: IAppStage = appStage;
  public user: IUser | undefined;
  private destroy$: Subject<void> = new Subject<void>();
  public showLanguages: boolean = false;
  public selectedLang: string;
  public backOfficeBtnInFocus: boolean = false;
  public langs: string[] = [ 'en', 'sr', 
    // 'cz', 'de', 'hi', 'ru', 'es' 
  ];

  constructor(public _store: Store, public _url: URLService, public _translate: TranslateService) {
    this.selectedLang = this.langs.find(x => x === this._translate.currentLang)!;

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: IUser | null) => {
      if (user && (this.user?.Id !== user?.Id || this.user?.Avatar !== user?.Avatar)) {
        this.user = user;
      }
    });
  }
  
  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public changeLanguage(key: string): void {
    this.selectedLang = this.langs.find(x => x === key)!;
    this._translate.use(key);
    localStorage.setItem('lang', key);
    this.showLanguages = false;
  }

  public mobileMenuOpen(): void {
    setTimeout(() => {
      const mobileMenu: any = document.getElementById('mobile-menu');
      const menuWrap: any = document.getElementsByClassName('appi-ei-mobile_menu_wrap')[0];
      if(!mobileMenu.classList.value.includes('mobile_menu_overlay_on')){
        mobileMenu.classList.add('mobile_menu_overlay_on');
        menuWrap.classList.add('mobile_menu_on');
      }
    });
  }

  public mobileMenuClose(): void {
    setTimeout(() => {
      const mobileMenu: any = document.getElementById('mobile-menu');
      const menuWrap: any = document.getElementsByClassName('appi-ei-mobile_menu_wrap')[0];
      if(mobileMenu.classList.value.includes('mobile_menu_overlay_on')){
        mobileMenu.classList.remove('mobile_menu_overlay_on');
        menuWrap.classList.remove('mobile_menu_on');
      }
    });
  }

  public logout(): void {
    this._store.dispatch(new Logout());
  }

  public logoutFocus(focus: boolean){
    if(focus){
      setTimeout(() => {
        this.backOfficeBtnInFocus = true;
      }, 500);
    }
    else {
      setTimeout(() => {
        this.backOfficeBtnInFocus = false;
      }, 1000);
    }
  }
}