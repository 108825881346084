import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { URLService } from '@shared/services/url.service';
import { TranslateService } from '@ngx-translate/core';
import { Route, RouterModule } from '@angular/router';
import { RouteConfig } from '@config/routes.config';
import { NgModule } from '@angular/core';

const availableLangs: string[] = ['en', 'sr', 'es'];
let defaultLang: string = 'en';
let lang: string = '';

function getLang(): string {
  if (lang !== '') return lang;
  else {
    const language: string = location.pathname.slice(1, 3);

    if (availableLangs.some((x: string) => x === language)) {
      lang = language;
    } else {
      const language: string | null = localStorage.getItem('lang');

      if (language && availableLangs.some((x: string) => x === language)) {
        defaultLang = language;
      }
      lang = defaultLang;
    }
  }

  return lang;
}

const routes: Route[] = [
  {
    path: 'en',
    loadChildren: () => import('../en.module').then((m: any) => m.EnglishRoutingModule),
  },
  {
    path: 'sr',
    loadChildren: () => import('../sr.module').then((m: any) => m.SerbianRoutingModule),
  },
  {
    path: 'es',
    loadChildren: () => import('../es.module').then((m: any) => m.SpanishRoutingModule),
  },
  {
    path: '**',
    redirectTo: `${getLang()}/${RouteConfig[getLang()].Public.Home}`,
  },
  {
    path: '',
    redirectTo: `${getLang()}/${RouteConfig[getLang()].Public.Home}`,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
  providers: [HttpClient],
})
export class LanguageRoutingModule {
  constructor(private _translate: TranslateService, private _url: URLService) {
    _translate.addLangs(availableLangs);
    _translate.setDefaultLang(defaultLang);

    const language: string | null = localStorage.getItem('lang');

    if (language) {
      // Use language from local storage
      _translate.use(language);
      this._url.translateURL();
    } else {
      // Use language from route
      _translate.use(lang);
    }
  }
}

function translateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
