<section id="ei-footer" class="ei-footer-section position-relative">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="ei-footer-widget pera-content appeight-headline">
          <div class="ei-footer-logo" (click)="scrollUp();">
            <img src="./../../../assets/template/img/app-landing/logo/logo-dark.png" alt="" />
          </div>
          <p>{{ 'FooterDescription' | translate }}</p>
          <div class="ei-payment-method" (click)="_url.navigate('Subscription')">
            <img src="./../../../assets/template/img/app-landing/logo/paypal.png" alt="" />
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="ei-footer-widget appeight-headline ul-li-block navigation">
          <h3 class="ei-widget-title">{{ 'FooterUsefulLinks' | translate }}</h3>
          <ul>
            <li><a class="nav-link" (click)="_url.navigate('Home')">{{'Home' | translate}}</a></li>
            <li><a class="nav-link" (click)="_url.navigate('Home', 'eg-how-work')">{{'HowItWorks' | translate}}</a></li>
            <li><a class="nav-link" (click)="_url.navigate('Subscription')">{{'Subscription' | translate}}</a></li>
            <li><a class="nav-link" (click)="_url.navigate('Home', 'ei-faq')">{{'FAQ' | translate}}</a></li>
            <li><a class="nav-link" (click)="_url.navigate('Contact')">{{'Contact' | translate}}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="ei-footer-widget appeight-headline ul-li-block">
          <h3 class="ei-widget-title">{{ 'FooterContact' | translate }}</h3>
          <h4 (click)="_url.navigate('Contact')" style="cursor: pointer;">
            <i class="fas fa-envelope"></i>
            {{'Email' | translate}} <br> 08:00 - 16:00
            <span>({{'Monday' | translate}} - {{'Friday' | translate}})</span>
          </h4>
          <h4>
            <i class="fas fa-clock"></i>
            {{'WorkingHours' | translate}} <br> 08:00 - 16:00
            <span>({{'Monday' | translate}} - {{'Friday' | translate}})</span>
          </h4>
          <div class="download-btn">
            <!-- <a><img src="./../../../assets/template/img/app-landing/logo/app1.png" alt="" /></a> -->
            <!-- <a><img src="./../../../assets/template/img/app-landing/logo/gp1.png" alt="" /></a> -->
          </div>
          <div class="ei-footer-social">
            <a [href]="_url.facebookURL" target=”_blank”><i class="fab fa-facebook-f"></i></a>
            <a [href]="_url.instagramURL" target=”_blank”><i class="fab fa-instagram" style="color: #bf15c0"></i></a>
            <a [href]="_url.tiktokURL" target=”_blank”>
              <svg style="color: black" xmlns="http://www.w3.org/2000/svg" width="16" height="13" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" fill="black" stroke="#8f20ad" stroke-width="1.3"></path>
              </svg>
            </a>
            <a [href]="_url.linkedinURL" target=”_blank”><i class="fab fa-linkedin"></i></a>
            <a [href]="_url.twitterURL" target=”_blank”><i class="fab fa-twitter" style="color: #198cd8"></i></a>
            <a [href]="_url.youtubeURL" target=”_blank”><i class="fab fa-youtube" style="color: #ff1600;"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ei-footer-copyright">
    <div class="container">
      <div class="ei-footer-copyright-content">
        <div class="row">
          <div class="col-md-4">
            <div class="ei-copyright-text">
              <p>© 2024 <a>Chat Support</a></p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="ei-copyright-menu">
              <a (click)="_url.navigate('Contact')" style="cursor: pointer;">{{'Help' | translate}}</a>
              <!-- <a>{{ 'TermsConditions' | translate }}</a>
              <a>{{ 'Privacy' | translate }}</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ei-footer-shape1 position-absolute" data-parallax='{"x" : 60}'>
    <img src="../../../assets/template/img/app-landing/shape/fo-shape1.png" alt="" />
  </div>
  <div class="ei-footer-shape2 position-absolute" data-parallax='{"y" : 60}'>
    <img src="./../../../assets/template/img/app-landing/shape/fo-shape2.png" alt="" />
  </div>
  <div class="ei-footer-shape3 position-absolute">
    <img src="./../../../assets/template/img/app-landing/shape/eimap.png" alt="" />
  </div>
</section>
