import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '@environments/environment';
import { BadgeVisible } from '@app/store/app.actions';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Store } from '@ngxs/store';
import { take } from 'rxjs';

@Component({
  selector: 'mobile-badge',
  templateUrl: './mobile-badge.component.html',
  styleUrls: ['./mobile-badge.component.scss'],
})
export class MobileBadgeComponent implements OnInit {
  private badge: string = 'block_mobile_badge';
  private badgeDuration: number = 20; // Seconds
  public showDialog: boolean = false;
  public showABadge: boolean = true;

  public selectedApp: AppBadge | undefined;
  public apps: AppBadge[] = [
    {
      type: 'android',
      app: 'GooglePlay',
      icon: 'google',
      url: environment.app.androidApp,
      width: 35,
      height: 45,
      marginTop: 4,
    },
    {
      type: 'ios',
      app: 'AppStore',
      icon: 'apple',
      url: environment.app.iosApp,
      width: 35,
      height: 45,
      marginTop: 0,
    },
    {
      type: 'huawei',
      app: 'AppGallery',
      icon: 'huawei',
      url: environment.app.huaweiApp,
      width: 35,
      height: 37,
      marginTop: 4,
    },
  ];

  constructor(public _platform: Platform, private _activatedRoute: ActivatedRoute, private _store: Store) {}

  public ngOnInit(): void {
    const local: boolean = localStorage.getItem(this.badge) ? true : false;
    const session: boolean = sessionStorage.getItem(this.badge) ? true : false;

    if (!local && !session) {
      setTimeout(() => {
        this._activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
          if (!params[this.badge]) {
            this.showMobileBadge();
          } else this.hideForever();
        });
      }, 2000);
    } else this._store.dispatch(new BadgeVisible(false));
  }

  public showMobileBadge(): void {
    this._store.dispatch(new BadgeVisible(true));

    if (this._platform.ANDROID || this._platform.IOS) {
      if (this._platform.ANDROID) {
        this.selectedApp = this.apps.find((x: AppBadge) => x.type === 'android');
      } else if (this._platform.IOS) {
        this.selectedApp = this.apps.find((x: AppBadge) => x.type === 'ios');
      }
      // If user not make reaction on badge
      setTimeout(() => {
        this.showABadge = false;
        this._store.dispatch(new BadgeVisible(false));
      }, this.badgeDuration * 1000);
    } else {
      this.hideForever();
    }
  }

  public hideTemporary(): void {
    this._store.dispatch(new BadgeVisible(false));
    sessionStorage.setItem(this.badge, 'true');
    this.showDialog = false;
  }

  public hideForever(): void {
    this._store.dispatch(new BadgeVisible(false));
    localStorage.setItem(this.badge, 'true');
    this.showDialog = false;
  }

  public downloadApp(): void {
    if (this.selectedApp?.url) {
      window.location.href = this.selectedApp.url;
    }
  }
}

interface AppBadge {
  type: string;
  app: string;
  icon: string;
  url: string;
  width: number;
  height: number;
  marginTop: number;
}
