import { IRole } from '@app/shared/models/user.interface';

/** All pages available in app */
export type Page =
  | 'Home'
  | 'Login'
  | 'NotFound'
  | 'Subscription'
  | 'LandingPage'
  | 'BackOffice'
  | 'HeadOffice'
  | 'Contact'
  | 'Demo'
  | 'Blank'
  | 'Profile'

/** All pages must have permission. If permission is an empty array, all roles can access it. */
export const RoutePermission: Record<Page, IRole[]> = {
  Login: [],
  Home: [],
  NotFound: [],
  Subscription: [],
  LandingPage: [],
  Profile: [IRole.SuperAdmin, IRole.Admin, IRole.Client, IRole.Visitor],
  BackOffice: [IRole.SuperAdmin, IRole.Admin, IRole.Client, IRole.Visitor],
  HeadOffice: [IRole.SuperAdmin],
  Contact: [],
  Demo: [],
  Blank: [],
};
