import { ValidatorAutoComplete, ValidatorEmail } from '@extensions/validators/form-control.validator';
import { InternetStatusService } from '@interceptor/Internet-status.service';
import { IAutoForm } from '../auto-form/interface/auto-form.interface';
import { Observable, of, share, Subject, switchMap, take } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UnitsService } from '@app/shared/services/units.service';
import { ListOfCountries } from '@constants/list-of-countries';
import { Gender, IRole, IUser } from '@models/user.interface';
import { ApiAccountService } from './api/api.account.service';
import { AccountMode } from './models/account-mode.interface';
import { ICredentials } from './models/credentials.interface';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Page } from '@config/routes-permissions.config';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '@environments/environment';
import { SnackService } from '@services/snack.service';
import { TranslateService } from '@ngx-translate/core';
import { URLService } from '@services/url.service';
import { IToken } from './models/token.interface';
import { Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

import {
  ISubscription,
  ISubscriptionPackage,
} from '@app/core/components/subscription/interfaces/subscription.interface';

// STORE
import { SetToken } from './store-auth/auth.actions';
import { SetUser } from './store-user/users.actions';
import { UsersState } from './store-user/users.state';
import { SubscriptionState } from '@app/core/components/subscription/store/subscription.state';
import { SetChatMode } from '@app/core/components/chat-support/store-chat/support/chat-support.actions';
import { IChatMode } from '@app/core/components/chat-support/models/chat-mode.interface';
import { EnvironmentService } from '@environments/environment.service';
import { IEmail, IEmailStatus } from '@app/core/extensions/email/models/email.interface';
import { EmailService } from '@app/core/extensions/email/email.service';

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  @Select(SubscriptionState.getSubscription) subscription$!: Observable<ISubscription | null | undefined>;
  @Input() mode: AccountMode = AccountMode.Login;

  //#region GLOBAL PROPERTIES

  public wait: boolean = false;
  public passwordMinLength: number = 6;
  public user: IUser = {} as IUser;
  public shakeClass: string = '';
  public loadFinish: boolean = false;
  public onLine: boolean = navigator.onLine;
  private destroy$: Subject<void> = new Subject<void>();

  public emailOfferURL = EnvironmentService.getProp('documents|emailOffer');
  public pitchDeckURL = EnvironmentService.getProp('documents|pitchDeck');

  //#endregion

  //#region INIT

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _apiAuth: ApiAccountService,
    private _snack: SnackService,
    public _email: EmailService,
    private _url: URLService,
    private _net: InternetStatusService,
    private _store: Store,
    public _units: UnitsService,
    public _translate: TranslateService,
  ) {
    // Online
    this._net.internetStatus$.pipe(takeUntil(this.destroy$)).subscribe((online: boolean) => {
      this.onLine = online;
    });

    // Too many failed login attempts
    const nextTry: string | null = localStorage.getItem('nextTry');

    if (nextTry) {
      const lockedUntil: Date = new Date(nextTry);
      if (new Date() > lockedUntil) {
        localStorage.removeItem('nextTry');
      } else this.login_Try = this.login_TryMax + 1;
    }

    // User
    this._store
      .select(UsersState.selectUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IUser | null) => {
        if (user?.Id) {
          this.user = user;
          this.form_ProfileCreation();
        }
      });
  }

  public ngOnInit(): void {
    const accountBlocked: boolean = localStorage.getItem('#') ? true : false;
    this.initAvatarsPack();

    if (accountBlocked) {
      this.mode = AccountMode.Blocked;
    }

    this._activatedRoute.queryParams.pipe(take(1)).subscribe((param: Params) => {
      const resetPasswordKey: string = param[this.resetPassword_Key];
      const accountVerificationKey: string = param[this.signUp_VerificationKey];

      if (resetPasswordKey || accountVerificationKey) {
        if (resetPasswordKey && resetPasswordKey === localStorage[this.resetPassword_Key]) {
          this.resetPassword_UserId = resetPasswordKey.split('|')[1];
          this.mode = AccountMode.ResetPassword;
          this.loadFinish = true;
        }
        if (accountVerificationKey && accountVerificationKey === localStorage[this.signUp_VerificationKey]) {
          this.signUp_UserId = accountVerificationKey.split('|')[1];
          this.accountVerification();
        } else this.loadFinish = true;
      } else this.loadFinish = true;
    });

    this.subscription$.pipe(takeUntil(this.destroy$)).subscribe((subscription: ISubscription | null | undefined) => {
      if (subscription) {
        this.setSubscription(subscription);
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public changeAccountMode(mode: string): void {
    this.mode = mode as AccountMode;
    this.initAvatarsPack();
  }

  public initAvatarsPack(): void {
    if (this.mode === AccountMode.Signup || this.mode === AccountMode.Profile) {
      this.avatarsPack(0);
    }
  }

  //#endregion

  //#region LOGIN

  public login_Try: number = 0;
  public login_TryMax: number = 5;
  public login_TempBlock: number = 3; // Minutes
  public login_Failed: boolean = false;
  public login_AccountValid: boolean = false;
  public login_RememberMe: boolean = true;
  public login_Token: ICredentials = {} as ICredentials;
  public login_Form: IAutoForm[] = [
    {
      Name: 'Email',
      Type: 'email',
      Validators: [Validators.required, ValidatorEmail],
    },
    {
      Name: 'Password',
      Type: 'password',
      Validators: [Validators.required, Validators.minLength(6)],
    },
  ];

  public login_RememberMe_Form: IAutoForm[] = [
    {
      Name: 'RememberMe',
      Type: 'checkbox',
      Value: this.login_RememberMe,
    },
  ];

  public form_login(form: ICredentials): void {
    if (this.login_Token.Email !== form.Email || this.login_Token.Password !== form.Password) {
      this.login_Token.Email = form.Email;
      this.login_Token.Password = form.Password;
      this.login_Failed = false;
    }
  }

  public login(): void {
    this.wait = true;
    const passwordHash: string = CryptoJS.MD5(this.login_Token.Password).toString();
    this._apiAuth
      .login(this.login_Token.Email, passwordHash)
      .pipe(
        switchMap((token: IToken | null) => {
          if (token) return this._apiAuth.getUserById(token?.Id);
          else return of(undefined);
        }),
        take(1),
      )
      .subscribe((user: IUser | null | undefined) => {
        if (user) {
          if (user.Active) {
            if (this.login_RememberMe) {
              this._store.dispatch(new SetToken(user));
            }
            this._store.dispatch(new SetUser(user));
            this._store.dispatch(new SetChatMode(IChatMode.User));
            
            localStorage.removeItem('nextTry');
            let homePage: Page = 'Home';

            switch (user.Role) {
              case IRole.SuperAdmin:
                homePage = 'BackOffice';
                break;
              case IRole.Admin:
                homePage = 'BackOffice';
                break;
              case IRole.Client:
                homePage = 'BackOffice';
                break;
              case IRole.Visitor:
                homePage = 'BackOffice';
                break;
              default:
                homePage = 'Home';
            }

            // Case scenario where user try to subscribe but he is not logged in.
            // We want to redirect him on subscription page immediately after login in such a case.
            if (sessionStorage.getItem('subscription')) {
              homePage = 'Subscription';
              sessionStorage.removeItem('subscription');
            }

            this._url.setURL(homePage);
          } else {
            localStorage.clear();
            localStorage.setItem('#', '#');
            this.mode = AccountMode.Blocked;
          }
        } else {
          this.shakeLoginBtn();
          localStorage.removeItem('token');
          this.login_Try = this.login_Try + 1;

          if (this.login_Try > this.login_TryMax) {
            localStorage.setItem(
              'nextTry',
              new Date(new Date().getTime() + this.login_TempBlock * 60000).toISOString(),
            );
          }
          this.login_Failed = true;
        }
        this.wait = false;
      });
  }

  private shakeLoginBtn(): void {
    this.shakeClass = 'shake-horizontal';
    setTimeout(() => {
      this.shakeClass = '';
    }, 1000);
  }

  public openLoginMode(): void {
    this.mode = AccountMode.Login;
    this._email.status = IEmailStatus.Ready;
    this.login_Try = 0;
  }

  //#endregion

  //#region SIGNUP
  public signUp_UserId: string = '';
  public signUp_User: IUser = {} as IUser;
  public signUp_Token: IToken = {} as IToken;
  public signUp_PasswordMatch: boolean = true;
  public signUp_PasswordConfirm: string | undefined = undefined;
  public signUp_NewPassword: string | undefined = undefined;
  public signUp_AccountValid: boolean = false;
  public signUp_CredentialEmailValid: boolean = false;
  public signUp_CredentialPasswordValid: boolean = false;
  public signUp_EmailVerifiedSuccessfully: boolean = true;
  public signUp_EmailFree: boolean = false;
  public signUp_EmailCheckingDelay: number = 1500;
  public signUp_VerificationKey: string = 'accVerificationKey';
  public signUp_SelectedTab: number = 0;

  public signUp_AccountForm: IAutoForm[] = [
    {
      Name: 'FirstName',
      Validators: [Validators.required, Validators.minLength(2), Validators.maxLength(30)],
      Icon: 'person',
    },
    {
      Name: 'LastName',
      Validators: [Validators.required, Validators.minLength(2), Validators.maxLength(30)],
      Icon: 'person',
    },
    {
      Name: 'Gender',
      Type: 'select',
      Options: ['Male', 'Female', 'NonBinary'],
      Validators: [Validators.required],
      Icon: 'people',
    },
    {
      Name: 'BirthDate',
      Type: 'date',
      Icon: 'calendar_month',
      Validators: [Validators.required],
      MinValue: new Date().setFullYear(new Date().getFullYear() - 100),
      MaxValue: new Date().setFullYear(new Date().getFullYear() - 18),
    },
    {
      Name: 'State',
      Validators: [Validators.required, ValidatorAutoComplete(ListOfCountries)],
      Icon: 'public',
      Type: 'autocomplete',
      Options: ListOfCountries,
    },
    {
      Name: 'City',
      Validators: [Validators.required, Validators.minLength(2), Validators.maxLength(30)],
      Icon: 'apartment',
    },
  ];

  public signUp_CredentialEmail: IAutoForm[] = [
    {
      Name: 'Email',
      Type: 'email',
      Validators: [Validators.required, ValidatorEmail],
      AutoFill: false,
    },
  ];

  public signUp_CredentialPassword: IAutoForm[] = [
    {
      Name: 'Password',
      Type: 'password',
      Validators: [Validators.required, Validators.minLength(6)],
      AutoFill: false,
    },
    {
      Name: 'PasswordConfirm',
      Type: 'password',
      Validators: [Validators.required, Validators.minLength(6)],
      AutoFill: false,
    },
  ];

  public signUpForm(form: IUser): void {
    if (this.signUp_User.Gender !== form.Gender) {
      this.signUp_User.Avatar = undefined;
    }

    this.signUp_User = {
      ...this.signUp_User,
      Active: form.Active,
      BirthDate: form.BirthDate,
      City: form.City,
      FirstName: form.FirstName,
      Gender: form.Gender,
      LastName: form.LastName,
      State: form.State,
    };
  }

  public signUpCredentialEmail(form: any): void {
    if (this.signUp_CredentialEmailValid) {
        if (this.signUp_User.Email !== form.Email) {
          this._apiAuth
            .getUserByEmail(form.Email)
            .pipe(take(1))
            .subscribe((x: IUser | null) => { 
              this.signUp_EmailFree = !x ? true : false;
  
              if (x) {
                this.signUp_SelectedTab = 1;
              }
            });
        }
        this.signUp_Token.Email = form.Email;
    }
  }

  public signUpCredentialPassword(form: any): void {
    this.signUp_NewPassword = form.Password;
    this.signUp_PasswordConfirm = form.PasswordConfirm;

    if (form.Password?.length >= this.passwordMinLength && form.PasswordConfirm?.length >= this.passwordMinLength) {
      this.signUp_PasswordMatch = form.Password === form.PasswordConfirm;
    } else this.signUp_PasswordMatch = true;

    if (form.Password === form.PasswordConfirm) {
      this.signUp_Token.Password = form.PasswordConfirm;
      this.signUp_PasswordMatch = true;
    }
  }

  public signUp(): void {
    this.wait = true;
    this.signUp_Token.Id = this.signUp_User.Id;
    this.signUp_User.Email = this.signUp_Token.Email;
    this.signUp_User.Verified = false;
    this.signUp_User.Active = true;
    this.signUp_User.Role = IRole.Visitor;
    this._apiAuth
      .isUserEmailFree(this.signUp_User.Email)
      .pipe(
        switchMap((emailFree: boolean) => {
          if (emailFree) {
            this.signUp_User.Id = crypto.randomUUID();
            this.signUp_Token.Id = this.signUp_User.Id;
            this.signUp_Token.Password = CryptoJS.MD5(this.signUp_Token.Password).toString();

            return this._apiAuth.createUser({ ...this.signUp_User }, { ...this.signUp_Token });
          } else return of(false);
        }),
        take(1),
      )
      .subscribe((success: boolean) => {
        if (success) {
          this.sendEmailVerification(this.signUp_User.FirstName, this.signUp_User.Email, this.signUp_Token.Id);
        } else this._snack.error('UserUnsuccessfullyCreated');
        this.wait = false;
      });
  }

  public sendEmailVerification(firstName: string, email: string, userId: string): void {
    const accVerificationKey: string = `${Math.floor(Math.random() * 999999999)}|${userId}`;
    const verificationURL: string = `${location.origin}/${this._url.getURL('Login')}?${
      this.signUp_VerificationKey
    }=${accVerificationKey}`;

    localStorage.setItem(this.signUp_VerificationKey, accVerificationKey);

    this._email.sendEmail({
      userName: firstName,
      to: email,
      subject: 'AccountVerification',
      body: 'AccountVerificationBody',
      button: 'AccountVerification',
      buttonURL: verificationURL,
    });
    this.mode = AccountMode.EmailSent;
  }

  public accountVerification(): void {
    if (this.signUp_UserId !== '') {
      this._apiAuth
        .getUserById(this.signUp_UserId)
        .pipe(
          switchMap((user: IUser | null) => {
            if (user) {
              if (!user.Verified) {
                return this._apiAuth.updateUser({ ...user, Verified: true });
              } else return of(true);
            } else return of(undefined);
          }),
          take(1),
        )
        .pipe(share())
        .subscribe((x: boolean | undefined) => {
          this.signUp_EmailVerifiedSuccessfully = x ? true : false;
          this.mode = AccountMode.AccountVerified;
          this.loadFinish = true;

          if (this.signUp_EmailVerifiedSuccessfully) {
            localStorage.removeItem(this.signUp_VerificationKey);
          }
        });
    } else {
      this.signUp_EmailVerifiedSuccessfully = false;
      this.mode = AccountMode.AccountVerified;
      this.loadFinish = true;
    }
  }

  public setAvatar(gender: Gender, i: number): void {
    this.profile_AvatarSelected = true;
    const avatar: string =
      gender === Gender.Male ? 'M' + i : gender === Gender.Female ? 'F' + i : i % 2 === 0 ? 'M' + i : 'F' + i;

    if (this.mode === AccountMode.Signup) {
      this.signUp_User.Avatar = avatar;
    } else if (this.mode === AccountMode.Profile) {
      this.profile_AvatarChange = avatar;
    }
  }

  public tabChanged(tab: MatTabChangeEvent): void {
    this.signUp_SelectedTab = tab.index;

    if (tab.index === 2) {
      this.wait = true;
      setTimeout(() => {
        this.wait = false;
      }, this.signUp_EmailCheckingDelay);
    }
  }

  public nextTab(): void {
    if (this.signUp_SelectedTab < 2) {
      this.signUp_SelectedTab = this.signUp_SelectedTab + 1;
    }
  }

  //#endregion

  //#region FORGOT PASSWORD
  public forgotPassword_UserEmail: string = '';
  public forgotPassword_AccountValid: boolean = false;
  public forgotPassword_Form: IAutoForm[] = [
    {
      Name: 'Email',
      Type: 'email',
      Validators: [Validators.required, ValidatorEmail],
    },
  ];

  public forgotPassword(): void {
    this.wait = true;
    this._apiAuth
      .getUserByEmail(this.forgotPassword_UserEmail)
      .pipe(take(1))
      .subscribe((user: IUser | null | undefined) => {
        if (user) {
          const resetPasswordKey: string = `${Math.floor(Math.random() * 999999999)}|${user.Id}`;
          const resetURL: string = `${location.origin}/${this._url.getURL('Login')}?${
            this.resetPassword_Key
          }=${resetPasswordKey}`;

          localStorage.setItem(this.resetPassword_Key, resetPasswordKey);

          this._email.sendEmail({
            userName: user.FirstName,
            to: this.forgotPassword_UserEmail,
            subject: 'ForgotPassword',
            body: 'ForgotPasswordEmailBody',
            button: 'ResetPassword',
            buttonURL: resetURL,
          });
          this.mode = AccountMode.EmailSent;
        } else this._snack.warning('EmailNotValid');
        this.wait = false;
      });
  }

  //#endregion

  //#region RESET PASSWORD

  public resetPassword_UserId: string = '';
  public resetPassword_Key: string = 'resetPasswordKey';
  public resetPassword_AccountValid: boolean = false;
  public resetPassword_Match: boolean = true;
  public resetPassword_PasswordConfirm: string | undefined = undefined;
  public resetPassword_NewPassword: string | undefined = undefined;
  public resetPassword_ChangedSuccessfully: boolean = true;
  public resetPassword_Form: IAutoForm[] = [
    {
      Name: 'Password',
      Label: 'NewPassword',
      Type: 'password',
      Validators: [Validators.required, Validators.minLength(this.passwordMinLength)],
      AutoFill: false,
    },
    {
      Name: 'PasswordConfirm',
      Type: 'password',
      Validators: [Validators.required, Validators.minLength(this.passwordMinLength)],
      AutoFill: false,
    },
  ];

  public form_ResetPassword(form: any): void {
    if (form.Password?.length >= this.passwordMinLength && form.PasswordConfirm?.length >= this.passwordMinLength) {
      this.resetPassword_Match = form.Password === form.PasswordConfirm;
    } else this.resetPassword_Match = true;

    this.resetPassword_NewPassword = form.Password;
    this.resetPassword_PasswordConfirm = form.PasswordConfirm;
  }

  public resetPassword(): void {
    this.wait = true;
    let canChangePassword: boolean = true;

    if (this.mode === AccountMode.ResetPassword) {
      if (this.resetPassword_UserId === '') {
        this.resetPassword_ChangedSuccessfully = false;
      }
    } else if (this.mode === AccountMode.ResetPasswordProfile) {
      if (this.user) {
        this.resetPassword_UserId = this.user.Id;
      } else canChangePassword = false;
    }

    if (canChangePassword) {
      this._apiAuth
        .getTokenByUserId(this.resetPassword_UserId)
        .pipe(
          switchMap((token: IToken | null) => {
            if (token) {
              if (this.resetPassword_PasswordConfirm) {
                const passwordHash: string = CryptoJS.MD5(this.resetPassword_PasswordConfirm).toString();

                return this._apiAuth.updateCredentials({ ...token, Password: passwordHash });
              } else return of(undefined);
            } else return of(undefined);
          }),
          take(1),
        )
        .subscribe((x: boolean | undefined) => {
          this.resetPassword_ChangedSuccessfully = x ? true : false;
          this.mode = AccountMode.PasswordChanged;

          if (this.resetPassword_ChangedSuccessfully) {
            localStorage.removeItem(this.resetPassword_Key);
            localStorage.removeItem('token');
          }
          this.wait = false;
        });
    } else {
      this.resetPassword_ChangedSuccessfully = false;
      this.mode = AccountMode.PasswordChanged;
      this.wait = false;
    }
  }

  public passwordStrong(password: string, match: boolean): number {
    if (password?.length >= this.passwordMinLength) {
      const uppercase: boolean = password.match(/[A-Z]/g) ? true : false;
      const lowercase: boolean = password.match(/[a-z]/g) ? true : false;
      const digit: boolean = password.match(/[0-9]/g) ? true : false;
      const specialCharacter: boolean = password.match(/[^A-Za-z0-9]/gi) ? true : false;

      let passwordStrong: number = 0;

      if (uppercase && lowercase && digit && specialCharacter) {
        passwordStrong = 100;
      } else if (uppercase && lowercase && (digit || specialCharacter)) {
        passwordStrong = 80;
      } else if ((uppercase || lowercase) && digit && specialCharacter) {
        passwordStrong = 80;
      } else if ((uppercase || lowercase) && (digit || specialCharacter)) {
        passwordStrong = 60;
      } else passwordStrong = 30;

      return match ? passwordStrong : 0;
    } else return 0;
  }

  public openResetPasswordProfileMode(): void {
    this.mode = AccountMode.ResetPasswordProfile;
  }

  public resetPasswordBack(): void {
    if (this.mode === AccountMode.ResetPassword) {
      this.openLoginMode();
    } else if (this.mode === AccountMode.ResetPasswordProfile) {
      this.openProfileMode(false);
    }
  }

  //#endregion

  //#region PROFILE

  public profile_Valid: boolean = true;
  public profile_Form: IAutoForm[] = [];
  public profile_AvatarChooser: boolean = false;
  public profile_AvatarSelected: boolean = false;
  public profile_UpdateDisabled: boolean = true;
  public profile_AvatarChange: string = '';
  public profile_AvatarsPack: number[] = [];
  public profile_AvatarsPackPage: number = 0;

  public form_Profile(form: IUser): void {
    if (this.user.Gender !== form.Gender) {
      this.user.Avatar = undefined;
    }

    this.user = {
      ...this.user,
      BirthDate: form.BirthDate,
      City: form.City,
      FirstName: form.FirstName,
      LastName: form.LastName,
      Gender: form.Gender,
      State: form.State,
    };
    this.profile_UpdateDisabled = false;
  }

  public form_ProfileCreation(): void {
    this.profile_Form = [
      {
        Name: 'FirstName',
        Validators: [Validators.required, Validators.minLength(2), Validators.maxLength(30)],
        Icon: 'person',
        Value: this.user.FirstName,
      },
      {
        Name: 'LastName',
        Validators: [Validators.required, Validators.minLength(2), Validators.maxLength(30)],
        Icon: 'person',
        Value: this.user.LastName,
      },
      {
        Name: 'Gender',
        Type: 'select',
        Options: ['Male', 'Female', 'NonBinary'],
        Validators: [Validators.required],
        Icon: 'people',
        Value: this.user.Gender,
      },
      {
        Name: 'BirthDate',
        Type: 'date',
        Validators: [Validators.required],
        Value: this.user.BirthDate,
        MinValue: new Date().setFullYear(new Date().getFullYear() - 100),
        MaxValue: new Date().setFullYear(new Date().getFullYear() - 18),
      },
      {
        Name: 'State',
        Validators: [Validators.required, ValidatorAutoComplete(ListOfCountries)],
        Icon: 'public',
        Type: 'autocomplete',
        Options: ListOfCountries,
        Value: this.user.State,
      },
      {
        Name: 'City',
        Validators: [Validators.required, Validators.minLength(2), Validators.maxLength(30)],
        Icon: 'apartment',
        Value: this.user.City,
      },
    ];
  }

  public profileChangeAvatar(): void {
    this.user.Avatar = this.profile_AvatarChange;
    this.profile_AvatarChooser = false;
    this.updateProfile(true);
  }

  public updateProfile(userAction?: boolean): void {
    if (this.user) {
      this.wait = true;
      this._apiAuth
        .updateUser(this.user)
        .pipe(take(1))
        .subscribe((x: boolean) => {
          if (x) {
            this._store.dispatch(new SetToken(this.user));
          }

          if (userAction) {
            x ? this._snack.success('ProfileUpdated') : this._snack.error('ProfileNotUpdated');
          }

          this.wait = false;
        });
    }
  }

  public sendEmailAgain(): void {
    this._email.trySendEmailAgain();
  }

  public avatarsPack(page: number): void {
    this.profile_AvatarsPackPage = page;

    const pack: number[] = [];
    const take: number = 9;
    const skip: number = page * take;

    for (let i: number = skip; i < skip + take; i++) {
      pack.push(i);
    }
    this.profile_AvatarsPack = pack;
  }

  public openProfileMode(avatarChooser: boolean): void {
    this.mode = AccountMode.Profile;
    this.profile_AvatarChooser = avatarChooser;
    this.avatarsPack(0);
  }

  //#endregion

  //#region SUBSCRIPTION

  public subscription: ISubscription | undefined;
  public subscription_Package: ISubscriptionPackage | undefined;
  public subscription_Packages: ISubscriptionPackage[] = environment.subscriptions;
  public subscription_SubDomainURL: string = '';
  public subscription_StillValid: boolean = false;

  private setSubscription(sub: ISubscription): void {
    this.subscription = sub;
    this.subscription_Package = this.subscription_Packages.find((x: ISubscriptionPackage) => x.name === sub.Package);
    this.subscription_SubDomainURL = `${location.origin}/${this._translate.currentLang}/${sub.SubDomain}`;
    this.subscription_StillValid = new Date() < new Date(sub.ValidUntil) ? true : false;
  }

  public openSubscriptionPackages(): void {
    this._url.setURL('Subscription');
  }

  //#endregion

  //#region CHANGE MODE

  public changeModeToProfile(): void {
    this.mode = AccountMode.Profile;
  }

  public changeModeToSubscription(): void {
    this.mode = AccountMode.Subscription;
  }

  //#endregion

  //#region OPEN DOCX DOCUMENT
  public openEmailOfferDocument(): void {
    const encodedDocUrl = `${window.location.origin}/assets/documents/LenaAI_${this._translate.currentLang}.docx`;
    const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedDocUrl}`;
    window.open(officeViewerUrl);
    // window.open(officeViewerUrl, '_blank');
  }
  
  //#endregion
}
