export interface IChatTheme {
  Name?: string;

  // Chat support button
  ChatButtonBg?: string;
  ChatButtonIcon?: string;
  ChatButtonBorder?: string;

  // Header
  HeaderFooterBg?: string;
  HeaderText?: string;
  HeaderSubText?: string;
  CloseButton?: string;
  ChatBorderRadius?: number;
  ChatBorder?: string;

  // Progress bar
  ProgressBarBg?: string;
  Progress?: string;

  // Content
  MessagesBorder?: string;
  MessagesBg?: string;
  MessageAIBg?: string;
  MessageAIText?: string;
  MessageAIBorder?: string;
  MessageAITypingDots?: string;
  MessageUserBg?: string;
  MessageUserText?: string;
  MessageUserBorder?: string;
  MessageSeparatorBg?: string;
  MessageSeparatorText?: string;

  // Footer
  FooterIcons?: string;
  InputBg?: string;
  InputText?: string;
  InputPlaceholder?: string;
}

export const DefaultTheme: IChatTheme = {
  Name: undefined,
  ChatButtonBg: undefined,
  ChatButtonIcon: undefined,
  ChatButtonBorder: undefined,
  HeaderFooterBg: undefined,
  HeaderText: undefined,
  HeaderSubText: undefined,
  CloseButton: undefined,
  FooterIcons: undefined,
  MessagesBg: undefined,
  MessageAIBg: undefined,
  MessageAIText: undefined,
  MessageAIBorder: undefined,
  MessageUserBg: undefined,
  MessageUserText: undefined,
  MessageUserBorder: undefined,
  MessageSeparatorBg: undefined,
  MessageSeparatorText: undefined,
  ChatBorderRadius: undefined,
  ChatBorder: undefined,
  MessagesBorder: undefined,
  ProgressBarBg: undefined,
  Progress: undefined,
  InputBg: undefined,
  InputText: undefined,
  InputPlaceholder: undefined,
};

export const DefaultThemes: IChatTheme[] = [
  { ...DefaultTheme, Name: 't1' },
  { ...DefaultTheme, Name: 't2' },
  { ...DefaultTheme, Name: 't3' },
];
