<ng-container *ngIf="group && control">
  <ng-container *ngIf="group?.get(control)?.hasError('required')">{{'InputError_FieldRequired' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('email')">{{'InputError_EmailInvalid' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('emailPattern')">{{'InputError_EmailInvalid' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('urlPattern')">{{'InputError_UrlInvalid' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('pattern')">{{'InputError_InvalidEntry' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('min')">{{'InputError_Min' | translate | replace:'{0}':group.get(control)?.errors?.['min']?.min}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('max')">{{'InputError_Max' | translate | replace:'{0}':group.get(control)?.errors?.['max']?.max}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('minlength')">{{'InputError_MinLength' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('maxlength')">{{'InputError_MaxLength' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('autoComplete')">{{'InputError_AutoComplete' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('evenNumber')">{{'InputError_NotEvenNumber' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('oddNumber')">{{'InputError_NotOddNumber' | translate}}</ng-container>
  <!-- <ng-container *ngIf="group?.get(control)?.hasError('requiredTrue')">{{'InputError_RequiredTrue' | translate}}</ng-container>
  <ng-container *ngIf="group?.get(control)?.hasError('nullValidator')">{{'InputError_NullValidator' | translate}}</ng-container> -->
</ng-container>