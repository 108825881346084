import { SharedModule } from '../../shared/modules/shared.module';
import { BlankRoutingModule } from './blank-routing.module';
import { BlankComponent } from './blank.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [BlankComponent],
  imports: [BlankRoutingModule, SharedModule],
  exports: [BlankComponent],
})
export class BlankModule {}
