import { IChatTheme } from '@app/features/back-office/interfaces/chat-style.interface';
import { IChatMessage, IQueryString } from '../../models/chat-support.interface';
import { IUser } from '@app/shared/models/user.interface';
import { IChatMode } from '../../models/chat-mode.interface';
import { IChatConfig } from '@app/features/back-office/interfaces/chat-config.interface';

export class SupportSwitch {
  public static readonly type: string = '[SupportSwitch] Chat is shown / hidden';
  constructor(public open: boolean) {}
}

export class GetSupportSession {
  public static readonly type: string = '[GetSupportSession] Getting support session';
  constructor(public userId?: string) {}
}

export class SupportMessage {
  public static readonly type: string = '[SupportMessage] Message sent. Message is stored locally';
  constructor(public message: IChatMessage, public userId?: string) {}
}

export class SaveConfig {
  public static readonly type: string = '[SaveConfig] Saving config changes';
  constructor(public config: IChatConfig, public userId: string) {}
}

export class PendingSave {
  public static readonly type: string = '[PendingSave] Config parameters are changed but not saved';
}

export class SetQuery {
  public static readonly type: string = '[SetQuery] Chat support query';
  constructor(public query: IQueryString) {}
}

export class ChangeTheme {
  public static readonly type: string = '[ChangeTheme] Theme is set';
  constructor(public theme: IChatTheme) {}
}

export class SetThemeStyle {
  public static readonly type: string = '[SetThemeStyle] Theme style is changed';
  constructor(public theme: IChatTheme) {}
}

export class GetConfig {
  public static readonly type: string = '[GetConfig] Get chat config';
  constructor(public user?: IUser) {}
}

export class SetChatMode {
  public static readonly type: string = '[SetChatMode] Set chat mode';
  constructor(public mode: IChatMode) {}
}

export class ChatEnableSwitch {
  public static readonly type: string = '[SetChatMode] Chat enable';
  constructor(public enable: boolean) {}
}

