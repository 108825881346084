import { SetChatMode } from '@app/core/components/chat-support/store-chat/support/chat-support.actions';
import { IChatMode } from '@app/core/components/chat-support/models/chat-mode.interface';
import { GetUser, SetUser, SetUserFromToken, UpdateUserRole } from './users.actions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { SecurityService } from '@environments/security.service';
import { ApiAccountService } from '../api/api.account.service';
import { URLService } from '@app/shared/services/url.service';
import { IUser } from '@shared/models/user.interface';
import { SetToken } from '../store-auth/auth.actions';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';

export class UsersStateModel {
  user: IUser | null = {} as IUser;
}
@State<UsersStateModel>({
  name: 'usersState',
  defaults: {
    user: SecurityService.getUserFromToken(),
  },
})
@Injectable()
export class UsersState {
  constructor(private _apiAccount: ApiAccountService, private _url: URLService, private _store: Store) {}

  @Selector()
  public static selectUser(state: UsersStateModel): IUser | null {
    return state.user;
  }

  @Action(GetUser)
  public getUser(): IUser | null {
    return SecurityService.getUserFromToken();
  }

  @Action(SetUser)
  public setUser(ctx: StateContext<UsersStateModel>, { user }: SetUser): void {
    ctx.patchState({
      user: { ...user },
    });
  }

  @Action(UpdateUserRole)
  public updateUserRole(ctx: StateContext<UsersStateModel>, { role }: UpdateUserRole): void {
    const user: IUser | null = ctx.getState().user;

    if (user?.Role !== role) {
      const userUpdate: any = {
        ...user,
        Role: role,
      };

      this._apiAccount.updateUser(userUpdate).pipe(take(1)).subscribe();
    }
  }

  @Action(SetUserFromToken)
  public setUserFromToken(ctx: StateContext<UsersStateModel>): void {
    const user: IUser | null = SecurityService.getUserFromToken();

    if (user) {
      this._apiAccount
        .getUserByIdEmailCreated(user.Id, user.Email, user.Created)
        .pipe(take(1))
        .subscribe((user: IUser | null) => {
          if (user?.Active) {
            const today: Date = new Date();
            const lastActivityInMs: number = today.getTime() - new Date(user.LastActivity).getTime();
            this._store.dispatch(new SetChatMode(IChatMode.User));

            // Updating LastActivity (inactivity was more than 2 minutes)
            if (lastActivityInMs > 120000) {
              user.LastActivity = today;
              this._apiAccount
                .updateUser(user)
                .pipe(take(1))
                .subscribe((x: boolean) => {
                  if (x) {
                    ctx.dispatch(new SetToken(user));

                    ctx.patchState({
                      user: user,
                    });
                  }
                });
            } else {
              ctx.patchState({
                user: user,
              });
            }
          } else {
            localStorage.clear();
            sessionStorage.clear();
            this._url.setURL('Home');
          }
        });
    }
  }
}
