import { SetChatMode } from '@app/core/components/chat-support/store-chat/support/chat-support.actions';
import { IChatMode } from '@app/core/components/chat-support/models/chat-mode.interface';
import { Page, RoutePermission } from '@app/core/config/routes-permissions.config';
import { NavigationEnd, Params, Router } from '@angular/router';
import { RouteConfig } from '@app/core/config/routes.config';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, Renderer2 } from '@angular/core';
import { IRole } from '../models/user.interface';
import { filter, switchMap, take } from 'rxjs';
import { AppService } from './app.service';
import { Store } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class URLService {
  constructor(private _store: Store, private _translate: TranslateService, private _router: Router, private _app: AppService) {
    const specialPages: string[] = ['Blank', 'BackOffice'];

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlWithoutParams = event.url.split('?')[0];

        const pageKey = urlWithoutParams.split('/').pop() as Page;
        const page: Page = this.getPageKey(pageKey) as Page;

        const isSpecialPage = specialPages.includes(page);
        this.showFooter = !isSpecialPage;
        this.showHeader = !isSpecialPage;        

        if(page !== 'Blank'){
          setTimeout(() => { this._app.loadScripts('../../../assets', this._renderer); }, 50);
        } else { this._store.dispatch(new SetChatMode(IChatMode.Public)); }
      }
    });
  }
  private config: any = RouteConfig as any;
  public _renderer: Renderer2 = {} as Renderer2;

  public facebookURL: string = environment.app.facebook;
  public instagramURL: string = environment.app.instagram;
  public linkedinURL: string = environment.app.linkedin;
  public youtubeURL: string = environment.app.youtube;
  public tiktokURL: string = environment.app.tiktok;
  public twitterURL: string = environment.app.twitter;

  public showHeader: boolean = true;
  public showFooter: boolean = true;

  public navigate(page: Page, elementId?: string): void {      
    this.setURL(page);

    if(elementId){
      setTimeout(() => {
        const targetElement = document.getElementById(elementId);
        const boundingRect = targetElement!.getBoundingClientRect();
        const distanceFromTop = boundingRect.top + window.scrollY;
        window.scrollTo({ top: distanceFromTop, behavior: 'auto' });
      });
    }

    const mobileMenu: any = document.getElementById('mobile-menu');
    const menuWrap: any = document.getElementsByClassName('appi-ei-mobile_menu_wrap')[0];
    if(mobileMenu.classList.value.includes('mobile_menu_overlay_on')){
        mobileMenu.classList.remove('mobile_menu_overlay_on');
        menuWrap.classList.remove('mobile_menu_on');
      }
  }

  // public navigate(page: Page, elementId?: string): void {   
  //   // const currentPage: Page | undefined = this.getPageKey(location.href.match(/\/sr\/([^\/]+)/)?.[1] as Page);
  //   // const behavior: ScrollBehavior = currentPage === page ? 'smooth' : 'instant' as ScrollBehavior;
  //   const behavior: ScrollBehavior = 'smooth' as ScrollBehavior;
    
  //   this.setURL(page);

  //   if (!elementId) {
  //     // window.scrollTo({ top: 0, behavior: behavior });
  //   } else {
  //     setTimeout(() => {
  //       const target: HTMLElement | null = document.getElementById(elementId);

  //       if (target) {
  //         target.scrollIntoView({ behavior, block: 'start' });
  //       }
  //     }, 200);
  //   }
  //   document.getElementById('header-close-icon')?.click();
  // }

  public getURL(page: Page): string {
    return `${this._translate.currentLang}/${this.config[this._translate.currentLang][this.getUrlType(page)][page]}`;
  }

  public setURL(page: Page, params?: any): void {
    window.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });
    const url: string = this.config[this._translate.currentLang][this.getUrlType(page)][page];
    if (!params) {
      this._router.navigate([`${this._translate.currentLang}/${url}`]);
    } else {
      this._router.navigate([`${this._translate.currentLang}/${url}`], { queryParams: params });
    }
  }

  public setExternalURL(url: string, newTab: boolean = false): void {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }

  private getUrlType(page: Page): string {
    return this.config[this._translate.currentLang].Public[page] ? 'Public' : 'Private';
  }

  public getPageKey(page: Page): Page | undefined {
    const routeMap: { [key: string]: string } = {};

    Object.keys(RouteConfig).forEach((langKey: string) => {
      const routeTypes: { [key: string]: { [key: string]: string } } = RouteConfig[langKey];

      Object.keys(routeTypes).forEach((routeType: string) => {
        const routes: { [key: string]: string } = routeTypes[routeType];

        Object.keys(routes).forEach((routeKey: string) => {
          const routeValue: string = routes[routeKey];

          if (routeValue === page) {
            routeMap[routeKey] = page;
          }
        });
      });
    });

    return Object.keys(routeMap)[0] as Page | undefined;
  }

  public getURLPermissions(page: Page): IRole[] {
    const pageKey: Page | undefined = this.getPageKey(page);

    return pageKey ? RoutePermission[pageKey] : [];
  }

  public getCleanUrl(url: string | undefined): string {
    if(url){
      // Removing http, https and /
      return url.replace(/^http(s)?:\/\//, '').replace(/\/$/, '');
    } else return '';
  }

  public translateURL(): void {
    const fromLang: string = location.pathname.split('/')[1];
    const query: string = location.href.split('?')[1];
    let params: any = {};

    if (query) {
      params = JSON.parse(
        '{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      );
    }

    if (fromLang.length && fromLang !== this._translate.currentLang) {
      const page: string = location.pathname.split('/')[2];

      const obj: Record<any, any> = this.config[fromLang];
      const publicRoutes: string[] = Object.keys(obj['Public']);
      const privateRoutes: string[] = Object.keys(obj['Private']);

      for (const route of publicRoutes) {
        if (obj['Public'][route] === page) {
          this.setURL(route as Page, params);

          return;
        }
      }

      for (const route of privateRoutes) {
        if (obj['Private'][route] === page) {
          this.setURL(route as Page, params);

          return;
        }
      }
    }
  }

  public urlRedirection(): void {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap(() => this._router.routerState.root.queryParams),
      take(1)
    ).subscribe((params: Params) => {
      const requestedLink = params['link'];
      switch(requestedLink){
        case 'become-partner': this.setURL('Home', {becomePartner:true}); break;
        case 'donation': window.location.href = environment.app.donation; break;
        case 'facebook': window.location.href = environment.app.facebook; break;
        case 'instagram': window.location.href = environment.app.instagram; break;
        case 'linkedin': window.location.href = environment.app.linkedin; break;
        case 'twitter': window.location.href = environment.app.twitter; break;
        case 'tiktok': window.location.href = environment.app.tiktok; break;
        case 'youtube': window.location.href = environment.app.youtube; break;
      }
    });
  }
}
