<div *ngIf="environment === 0" class="stripe stripe-dev-color">
  *** {{'DevStripeLabel' | translate | uppercase}} ***
</div>
<div *ngIf="environment === 1" class="stripe stripe-stg-color">
  *** {{'StgStripeLabel' | translate | uppercase}} ***
</div>

<header id="header-eight" class="main-header-eight">
  <div class="appheader-content">
    <div class="site-logo float-left">
      <a (click)="_url.navigate('Home')"><img src="./../../../assets/template/img/app-landing/logo/logo-white.png" alt="" /></a>
    </div>
    <nav class="navigation-eight ul-li">
      <ul>
        <li><a class="nav-link" (click)="_url.navigate('Home')">{{'Home' | translate}}</a></li>
        <!-- <li><a class="nav-link" (click)="_url.navigate('Home', 'feature-eight')">{{'WhyUs' | translate}}</a></li> -->
        <li><a class="nav-link" (click)="_url.navigate('Home', 'eg-how-work')">{{'HowItWorks' | translate}}</a></li>
        <li><a class="nav-link" (click)="_url.navigate('Subscription')"><b>{{'Subscribe' | translate}}</b></a></li>
        <li><a class="nav-link" (click)="_url.navigate('Home', 'ei-faq')">{{'FAQ' | translate}}</a></li>        
        <li><a class="nav-link" (click)="_url.navigate('Contact')">{{'Contact' | translate}}</a></li>
      </ul>
    </nav>
    <div class="h-eight-social ul-li float-right clearfix">
      <ul>
        <li><a [href]="_url.facebookURL" target=”_blank”><i class="fab fa-facebook-f"></i></a></li>
        <li><a [href]="_url.instagramURL" target=”_blank”><i class="fab fa-instagram"></i></a></li>
        <li><a [href]="_url.tiktokURL" target=”_blank”>
          <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="16" height="13" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
            <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" fill="white" stroke="white" stroke-width="1.2"></path>
          </svg>
        </a></li>
        <li><a [href]="_url.linkedinURL" target=”_blank”><i class="fab fa-linkedin"></i></a></li>
        <li><a [href]="_url.twitterURL" target=”_blank”><i class="fab fa-twitter"></i></a></li>

        <li><a [href]="_url.youtubeURL" target=”_blank”><i class="fab fa-youtube"></i></a></li>
        <li class="language-selector">
          <a class="lang-flag">
            <img src="../../../../assets/template/img/app-landing/flag/{{selectedLang}}.png">
          </a>
          <div class="language-options">
              <ng-container *ngFor="let lang of langs">
                  <a class="lang-flag mr" *ngIf="_translate.currentLang !== lang" 
                     (click)="changeLanguage(lang)">
                     <img src="../../../../assets/template/img/app-landing/flag/{{lang}}.png">
                  </a>
              </ng-container>
          </div>
        </li>         
      </ul>
    </div>
    <div class="sign-up-btn-eight text-center float-right clearfix" (mouseenter)="logoutFocus(true)" (mouseleave)="logoutFocus(false)">
      <ng-container *ngIf="!user?.Id">
        <mat-icon>key</mat-icon>
        <a (click)="_url.navigate('Login')" style="margin-right: 8px;">{{ 'SignIn' | translate }}</a>
      </ng-container>
      <ng-container *ngIf="user?.Id">
        <a *ngIf="(subscriptionValid$ | async) === null" (click)="_url.navigate('Subscription')">
          {{ 'Subscribe_Short' | translate }}
        </a>
        <a *ngIf="(subscriptionValid$ | async) !== null" (click)="_url.navigate('BackOffice')">
          {{ 'BackOffice' | translate }}
        </a>
        <img (click)="_url.navigate('Profile')" src="../../../../assets/avatars/{{user?.Avatar}}.png" />
        <div *ngIf="backOfficeBtnInFocus" class="logout" [ngClass]="backOfficeBtnInFocus ? 'fade-in' : 'fade-out'" (click)="logout();">{{'Logout' | translate}}</div>
      </ng-container>
    </div>
  </div>
  
    <!-- /mobile-menu -->
  <div class="appi-ei-mobile_menu relative-position">
    <div class="appi-ei-mobile_menu_button appi-ei-open_mobile_menu" (click)="mobileMenuOpen();">
      <i id="hamburger" class="fas fa-bars"></i>
    </div>
    <div class="appi-ei-mobile_menu_wrap">
      <div class="mobile_menu_overlay appi-ei-open_mobile_menu" (click)="mobileMenuClose();"></div>
      <div class="appi-ei-mobile_menu_content">
        <div class="sidebar-header">
          <!-- <div id="header-close-icon" class="appi-ei-mobile_menu_close appi-ei-open_mobile_menu" (click)="mobileMenuClose();">
            <i class="far fa-times-circle"></i>
          </div> -->
          <div class="m-brand-logo text-center">
            <img src="./../../../assets/template/img/app-landing/logo/logo-white.png" alt="" />
          </div>
          <div class="language-selector">
            <a class="lang-flag" (click)="showLanguages = !showLanguages;">
              <img src="../../../../assets/template/img/app-landing/flag/{{selectedLang}}-round.png">
            </a>
            <div *ngIf="showLanguages" class="language-options">
                <ng-container *ngFor="let lang of langs">
                    <a class="lang-flag mr fade-in" *ngIf="_translate.currentLang !== lang" 
                       (click)="changeLanguage(lang)">
                        <img src="../../../../assets/template/img/app-landing/flag/{{lang}}-round.png">
                    </a>
                </ng-container>
            </div>
          </div>
        </div>
        <nav class="appi-ei-mobile-main-navigation saasio_one_click clearfix ul-li">
          <ul id="main-nav" class="navbar-nav text-capitalize clearfix">
            <li class="nav-link" (click)="_url.navigate('Home')"><a>{{'Home' | translate}}</a></li>
            <!-- <li class="nav-link" (click)="navigate('Home', 'feature-eight')"><a>{{'WhyUs' | translate}}</a></li> -->
            <li class="nav-link" (click)="_url.navigate('Home', 'eg-how-work')"><a>{{'HowItWorks' | translate}}</a></li>
            <li class="nav-link" (click)="_url.navigate('Subscription')"><a>{{'Subscribe' | translate}}</a></li>
            <li class="nav-link" (click)="_url.navigate('Contact')"><a>{{'Contact' | translate}}</a></li>
            <li class="nav-link" (click)="_url.navigate('Home', 'ei-faq')"><a>{{'FAQ' | translate}}</a></li>            
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>
