import { IRole, IUser } from '@shared/models/user.interface';

export class GetUser {
  public static readonly type: string = '[GetUser] Getting current user';
}

export class SetUser {
  public static readonly type: string = '[SetUser] User set';
  constructor(public user: IUser) {}
}

export class SetUserFromToken {
  public static readonly type: string = '[SetUserFromToken] Set user from token';
}

export class UpdateUserRole {
  public static readonly type: string = '[UpdateUserRole] Update user role';
  constructor(public role: IRole) {}
}
