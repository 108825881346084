<div *ngIf="selectedApp?.url?.length" class="wrapper">
  <ng-container *ngIf="showABadge">
    <div class="app-badge slide-in-bottom" (click)="downloadApp();">
      <img src="../assets/badges/{{selectedApp?.icon}}.png" [width]="selectedApp?.width" [height]="selectedApp?.height" [style]="'margin-top:' + selectedApp?.marginTop + 'px;'"/>
      <div class="title">
        <div class="header">{{'DownloadOnThe' | translate}}</div>
        <div class="store">{{selectedApp?.app! | translate}}</div>
      </div>
    </div>
    <div class="slide-in-bottom button close" (click)="showABadge = false; showDialog = true;">✖</div>
  </ng-container>

  <div *ngIf="showDialog" class="dialog">
    <div class="button slide-in-bottom" (click)="hideForever();">
      <span class="material-icons">delete_forever</span>
      {{'HideForever' | translate}}
    </div>
    <div class="button slide-in-bottom" (click)="hideTemporary();">
      <span class="material-icons">visibility_off</span>
      {{'HideTemporary' | translate}}
    </div>
  </div>
</div>