import { EnvironmentService } from "@environments/environment.service";

export enum IAppStage {
    Development,
    Staging,
    Production
}

const getAppStage = (): IAppStage => {
    switch (window.location.hostname) {
        case EnvironmentService.getProp('domains|prod'): return IAppStage.Production;
        case EnvironmentService.getProp('domains|prodAlt'): return IAppStage.Production;

        case EnvironmentService.getProp('domains|stg'): return IAppStage.Staging;
        case EnvironmentService.getProp('domains|stgAlt'): return IAppStage.Staging;

        default: return IAppStage.Development;
    }
};

export const appStage: IAppStage = getAppStage();
