import { ValidatorEmail } from '@app/core/extensions/validators/form-control.validator';
import { ContactFrom, PartnerContactFrom } from './models/contact-form.interface';
import { IEmail, IEmailStatus } from '@app/core/extensions/email/models/email.interface';
import { EmailService } from '@app/core/extensions/email/email.service';
import { IAutoForm } from '../auto-form/interface/auto-form.interface';
import { EnvironmentService } from '@environments/environment.service';
import { UsersState } from '../account/store-user/users.state';
import { URLService } from '@app/shared/services/url.service';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { IUser } from '@app/shared/models/user.interface';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { contactTemplate, scheduleMeetingEmailTemplate } from '@app/core/extensions/email/templates/contact-template';
@Component({
  selector: 'contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnDestroy {
  @Select(UsersState.selectUser) user$!: Observable<IUser | null>;
  private destroy$: Subject<void> = new Subject<void>();
  
  public contact: ContactFrom = {} as ContactFrom;
  public partnerContact: PartnerContactFrom = {} as PartnerContactFrom;
  public formValid: boolean = false;
  public wait: boolean = false;
  public setMeeting: boolean | undefined;
  public user: IUser | undefined | null;

  public contact_Form: IAutoForm[] = [
    {
      Name: 'FirstName',
      Validators: [Validators.required, Validators.minLength(2), Validators.maxLength(30)],
      Icon: 'person',
    },
    {
      Name: 'Email',
      Type: 'email',
      Label: 'YourEmail',
      Validators: [Validators.required, ValidatorEmail],
    },
    {
      Name: 'Subject',
      Type: 'text',
      Validators: [Validators.required],
      Icon: 'alternate_email',
    },
    {
      Name: 'Content',
      Type: 'textarea',
      Label: 'PleaseWriteYourMsg',
      Validators: [Validators.required, Validators.maxLength(800)],
    },
  ];

  public setMeeting_Form: IAutoForm[] = [];

  constructor(public _mail: EmailService, public _url: URLService, private _translate: TranslateService, private _activatedRoute: ActivatedRoute) {
    this._activatedRoute.queryParams.pipe(take(1)).subscribe((param: Params) => {
      this.setMeeting = param['setMeeting'] ? true : false;
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: IUser | null) => {
      this.user = user;
      if(this.setMeeting){
        this.setMeeting_Form = [
          {
            Name: 'SetYourAvailability',
            Type: 'title',
            Prefix: '🕒',
          },
          {
            Name: 'MeetingDate',
            Type: 'date',
            Validators: [Validators.required],
            MinValue: new Date().setDate(new Date().getDate() + 3),
            MaxValue: new Date().setMonth(new Date().getMonth() + 1),
          },
          {
            Name: 'AvailableFrom',
            Validators: [Validators.required],
            Icon: 'watch_later',
            Type: 'select',
            Options: ['15h', '16h', '17h', '18h', '19h', '20h', '21h'],
          },
          {
            Name: 'AvailableUntil',
            Validators: [Validators.required],
            Icon: 'watch_later',
            Type: 'select',
            Options: ['15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h']
          },
          {
            Name: 'FillYourInfo',
            Type: 'title',
            Prefix: '📝'
          },
          {
            Name: 'PartnerInterest',
            Validators: [Validators.required],
            Icon: 'business_center',
            Type: 'select',
            Options: ["PartnerInvestInProduct", "PartnerIntegrationProposal", "PartnerExploreCollaboration", "PartnerOfferServices", "PartnerSeekingPartnership", "PartnerOther"],
          },
          {
            Name: 'FirstName',
            Type: 'text',
            Validators: !this.user?.Id ? [Validators.required, Validators.minLength(2), Validators.maxLength(30)] : [],
            Style: `display: ${this.user?.Id ? 'none' : 'block'}`,
            Icon: 'person',
          },
          {
            Name: 'Email',
            Type: 'email',
            Style: `display: ${this.user?.Id ? 'none' : 'block'}`,
            Validators: !this.user?.Id ? [Validators.required, ValidatorEmail] : [],
          },
          {
            Name: 'Content',
            Type: 'textarea',
            Label: 'PleaseWriteYourMsg',
            Validators: [Validators.required, Validators.maxLength(800)],
          },
        ]
      }
    });
  }

  public sendEmail(): void {
    this._mail.sendEmail({
      to: EnvironmentService.getProp(`email|${EnvironmentService.getProp('email|providerInUse')}|support`),
      subject: this.contact.Subject,
      body: contactTemplate(this.contact, this._translate)
    });
  }

  public scheduleMeetingEmail(): void {
    this._mail.sendEmail({
      to: EnvironmentService.getProp(`email|${EnvironmentService.getProp('email|providerInUse')}|admin`),
      subject: 'PARTNER OPPORTUNITY❗️',
      body: scheduleMeetingEmailTemplate(this.user!, this.partnerContact, this._translate)
    });
  }

  public ngOnDestroy(): void {
    this._mail.status = IEmailStatus.Ready;
  }
}
