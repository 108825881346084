import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

// To make sure that the language change functionality works correctly,
// it is necessary to import this module in the component or module
// where the language change feature is required.

@NgModule({
  imports: [
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [],
})
export class I18nProviderModule {}

function translateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
