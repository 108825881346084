<div [lang]="_translate.currentLang">
  <app-header *ngIf="_url.showHeader"></app-header>

  <div id="preloader" class="ei-preloader"></div>
  <div class="ei-up">
    <a id="scrollup" class="ei-scrollup text-center"><i class="fas fa-angle-up"></i></a>
  </div>

  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>

  <app-footer *ngIf="_url.showFooter"></app-footer>
</div>

<chat-support *ngIf="supportEnabled"></chat-support>
<mobile-badge *ngIf="badgeEnabled"></mobile-badge>
