import { GetConfig } from './core/components/chat-support/store-chat/support/chat-support.actions';
import { GetSubscriptionPackage } from './core/components/subscription/store/subscription.actions';
import { SetUserFromToken } from './shared/components/account/store-user/users.actions';
import { UsersState } from './shared/components/account/store-user/users.state';
import { EnvironmentService } from '@environments/environment.service';
import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { URLService } from './shared/services/url.service';
import { environment } from '@environments/environment';
import { IUser } from './shared/models/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Select, Store } from '@ngxs/store';

// https://developers.google.com/codelabs/building-a-web-app-with-angular-and-firebase#0
// https://www.youtube.com/watch?v=QEuu9X9L-MU
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  @Select(UsersState.selectUser) user$!: Observable<IUser | null>;

  private destroy$: Subject<void> = new Subject<void>();
  public supportEnabled: boolean = false;
  public badgeEnabled: boolean = false;
  public title: string = 'Core';
  private user: IUser | undefined;

  constructor(public _translate: TranslateService, private _store: Store, public _url: URLService, private _renderer: Renderer2) {     
    this._url.urlRedirection();
    this._url._renderer = this._renderer;

    this.supportEnabled = EnvironmentService.getProp('openai|enabled');
    this.badgeEnabled = environment.app.showBadge;
    this._store.dispatch(new SetUserFromToken());

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: IUser | null) => {
      if (user && this.user?.Id !== user?.Id) {
        this.user = user;
        this._store.dispatch(new GetSubscriptionPackage(user.Id));
        this._store.dispatch(new GetConfig(user));
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
