import { LanguageRoutingModule } from '@app/core/routing/language/engine/language-routing.module';
import { MobileBadgeModule } from './shared/components/mobile-badge/mobile-badge.module';
import { ChatSupportModule } from './core/components/chat-support/chat-support.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnvironmentService } from '../environments/environment.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire/compat';
import { TitleCasePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';

// NGXS
import { ChatSupportState } from './core/components/chat-support/store-chat/support/chat-support.state';
import { appStage, IAppStage } from './core/config/environment.config';
import { MediaUploadState } from './shared/components/media-upload/store/media-upload.state';
import { SubscriptionState } from './core/components/subscription/store/subscription.state';
import { LoadingState } from './shared/components/menu/store-loading/loading.state';
import { UsersState } from './shared/components/account/store-user/users.state';
import { AuthState } from './shared/components/account/store-auth/auth.state';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { BlankModule } from './features/blank/blank.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from './store/app.state';
import { NgxsModule } from '@ngxs/store';

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LanguageRoutingModule,
    MobileBadgeModule,
    MatSnackBarModule,
    MatIconModule,
    ChatSupportModule,
    BlankModule,
    TranslateModule,

    NgxsModule.forRoot([
      LoadingState,
      AppState,
      AuthState,
      UsersState,
      SubscriptionState,
      MediaUploadState,
      ChatSupportState,
    ]),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.ngsxLogging,
      logger: !environment.ngsxLogging,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: !environment.ngsxDevTools,
    }),

    AngularFireModule.initializeApp(
      EnvironmentService.getProp(
        appStage === IAppStage.Production ? 'firebasePROD' : 
        appStage === IAppStage.Staging ? 'firebaseSTG' : 'firebaseDEV'
      )),
  ],
  bootstrap: [AppComponent],
  providers: [TitleCasePipe],
})
export class AppModule {}
