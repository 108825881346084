import { IChatConfig } from "@app/features/back-office/interfaces/chat-config.interface";
import { IChatMode } from "../components/chat-support/models/chat-mode.interface";
import { EnvironmentService } from "@environments/environment.service";

const HOST = window.location.hostname;
const BOT_NAME = 'LenaAI';

export const BASIC_SETTINGS = {
    AIModel: 'gpt-3.5-turbo',
    APIKey: EnvironmentService.getProp('openai|apiKey'),
    CompanyName: 'LenaAI',
    CompanyLogo: undefined,
    BotName: `${BOT_NAME}`,
    BotGender: 'Female',
    BotAvatar: '',
    Domain: HOST,
    FAQ: `${HOST}/en/home?section=ei-faq`,
    PrivacyPolicy: undefined,
    HumanSupport: undefined,
    IOSApp: undefined,
    AndroidApp: undefined,
    HuaweiApp: undefined,
    FirstEngagementMessage: undefined,
    Language: '',
    DefaultTheme: 't1',
    MakeEngagementFirst: false,
    Enabled: true,
    ShowUsageProgress: true,
    NotificationSound: true,
    Themes: [
        {
          Name: 't1',
          HeaderFooterBg: '#b45add',
          HeaderText: '#ffffff',
          HeaderSubText: '#ffffff',
          CloseButton: '#ffffff',
          ProgressBarBg: '#eeeeee',
          Progress: '#9c00e2',
          MessageAIBg: '#f2d6ff',
          MessageAITypingDots: '#b45ade',
          MessageUserBg: '#b5def2',
          MessageUserText: '#303030',
          FooterIcons: '#ffffff',
          ChatButtonBg: '#e50fe5',
          ChatButtonIcon: '#ffffff',
        },
        {
          Name: 't2',
        },
        {
          Name: 't3',
        },
    ]
}

export const CHAT_CONFIG: Record<IChatMode, IChatConfig> = {
    [IChatMode.Public]: {
        // Settings
        ...BASIC_SETTINGS,

        // Limitations
        MaxMessages: 200,
        DailyLimit: 40,
        MaxLengthUser: 120,
        MaxLengthSystem: 20000,
        ResponseWordsCount: 60,
        PreviousMessagesIncluded: 1,

        // Prompt
        Personality: `CustomerSupport`,
        Tone: `Persuasive`,
        Emoji: `UseEmojiSometimes`,
        Business: `${BOT_NAME} je vaš neizostavan digitalni saradnik, pružajući tehničku podršku i prodajne informacije. Uči iz svake interakcije kako bi personalizovao korisničko iskustvo. Proširite funkcionalnosti kroz URL parametre za još personalizovanije odgovore.`,
        IndustryJargon: `Integrišite specifičan industrijski žargon kako bi ${BOT_NAME} pokazao dublje razumevanje vaše oblasti.`,
        ImportantDates: `Sa ponosom poslujemo godinu dana, postižući izvanredne rezultate i pozitivan feedback od korisnika.`,
        CurrentPromotions: `Pratite naše društvene mreže za najnovije promocije i ekskluzivne popuste na naše usluge.`,
        PricingDetails: `Posetite ${HOST}/en/subscription za detalje o cenama usluga i dostupnim paketima.`,
        ProductLimitations: `${BOT_NAME} koristi informacije iz Back Office-a o vašem poslovanju za pružanje personalizovanih odgovora. Iskoristite URL parametre za još bolje prilagođavanje korisničkim potrebama.`,
        SpecificFeatures: `Kada ${BOT_NAME} ne zna odgovor, podstiče korisnika na preformulisanje pitanja. Iskoristite URL parametre za slanje dodatnih informacija kao što su ime korisnika sa kojim ${BOT_NAME} razgovara, licenca korisnika, i puno drugih dostupnih parametara (detaljno kada napravite nalog).`,
        AdditionalServices: `Kreirajte jedinstvenu temu za ${BOT_NAME} putem Theme Builder-a koja se savršeno uklapa u vaš brend. ${BOT_NAME} može poprimiti boje vašeg sajta kako bi se ${BOT_NAME} stopio sa vašim sadržajem.`,
        Integration: `Integracija je jednostavna. Nakon kupovine, pristupate Back Office-u, podešavate parametre i dobijate embed link za postavljanje ${BOT_NAME} na vašu web stranicu.`,
        CommonIssueSolutions: `Ukoliko imate bilo kakva dodatna pitanja, na koje ${BOT_NAME} nema odgovore kontaktirajte nas putem stranice ${HOST}/en/contact`,
        CurrentTechnicalIssues: `Obaveštavamo vas o trenutnim tehničkim problemima i radimo na njihovom rešavanju.`,
        DevelopmentPath: `Razvijamo dashboard sa detaljnim statistikama i izveštajima kako biste analizirali interakcije korisnika.`,
        RefundPolicy: `Ukoliko niste zadovoljni u prvih 14 dana, kontaktirajte nas preko ${HOST}/en/contact za povrat novca.`,
        PrivacyQueries: `${BOT_NAME} funkcioniše nezavisno, bez pristupa vašoj bazi podataka, čuvajući samo poslednjih 200 poruka radi vaše analize, koja će uskoro biti dostupna u okviru dashboard-a.`,
        ImportantPages: `Kako funkcioniše: ${HOST}/en/home?eg-how-work, FAQ: ${HOST}/en/home?ei-faq, Kontakt: ${HOST}/en/contact`,
        BackOffice: `Back Office omogućava podešavanje parametara za ${BOT_NAME}, u nekoliko jednostavnih koraka podesite veštačku intaligenciju da radi za vas 24/7. Saznajte više prilikom pretplate na naše pakete.`,
    },
    [IChatMode.User]: {
        // Settings
        ...BASIC_SETTINGS,
        BotName: `${BOT_NAME} ⚡`,

        // Limitations
        MaxMessages: 200,
        DailyLimit: 60,
        MaxLengthUser: 135,
        MaxLengthSystem: 20000,
        ResponseWordsCount: 80,
        PreviousMessagesIncluded: 2,

        // Prompt
        Personality: `CustomerSupport`,
        Tone: `Persuasive`,
        Emoji: `UseEmojiSometimes`,
        Business: `${BOT_NAME} je vaš neizostavan digitalni saradnik, pružajući tehničku podršku i prodajne informacije. Uči iz svake interakcije kako bi personalizovao korisničko iskustvo. Proširite funkcionalnosti kroz URL parametre za još personalizovanije odgovore.`,
        IndustryJargon: `Integrišite specifičan industrijski žargon kako bi ${BOT_NAME} pokazao dublje razumevanje vaše oblasti.`,
        ImportantDates: `Sa ponosom poslujemo godinu dana, postižući izvanredne rezultate i pozitivan feedback od korisnika.`,
        CurrentPromotions: `Pratite naše društvene mreže za najnovije promocije i ekskluzivne popuste na naše usluge.`,
        PricingDetails: `Posetite ${HOST}/en/subscription za detalje o cenama usluga i dostupnim paketima.`,
        ProductLimitations: `${BOT_NAME} koristi informacije iz Back Office-a o vašem poslovanju za pružanje personalizovanih odgovora. Iskoristite URL parametre za još bolje prilagođavanje korisničkim potrebama.`,
        SpecificFeatures: `Kada ${BOT_NAME} ne zna odgovor, podstiče korisnika na preformulisanje pitanja. Iskoristite URL parametre za slanje dodatnih informacija kao što su ime korisnika sa kojim ${BOT_NAME} razgovara, licenca korisnika, i puno drugih dostupnih parametara.`,
        AdditionalServices: `Kreirajte jedinstvenu temu za ${BOT_NAME} putem Theme Builder-a koja se savršeno uklapa u vaš brend. ${BOT_NAME} može poprimiti boje vašeg sajta kako bi se ${BOT_NAME} stopio sa vašim sadržajem.`,
        Integration: `Integracija je jednostavna. Nakon kupovine, pristupate Back Office-u, podešavate parametre i dobijate embed link za postavljanje ${BOT_NAME} na vašu web stranicu.`,
        CommonIssueSolutions: `Ukoliko imate bilo kakva dodatna pitanja, na koje ${BOT_NAME} nema odgovore kontaktirajte nas putem stranice ${HOST}/en/contact`,
        CurrentTechnicalIssues: `Obaveštavamo vas o trenutnim tehničkim problemima i radimo na njihovom rešavanju.`,
        DevelopmentPath: `Razvijamo dashboard sa detaljnim statistikama i izveštajima kako biste analizirali interakcije korisnika.`,
        RefundPolicy: `Ukoliko niste zadovoljni u prvih 14 dana, kontaktirajte nas preko ${HOST}/en/contact za povrat novca.`,
        PrivacyQueries: `${BOT_NAME} funkcioniše nezavisno, bez pristupa vašoj bazi podataka, čuvajući samo poslednjih 200 poruka radi vaše analize, koja će uskoro biti dostupna u okviru dashboard-a.`,
        ImportantPages: `Kako funkcioniše: ${HOST}/en/home?eg-how-work, FAQ: ${HOST}/en/home?ei-faq, Kontakt: ${HOST}/en/contact`,
        BackOffice: `Back Office omogućava podešavanje parametara za ${BOT_NAME}, tu imate mogućnost da setujete sve neophodne informacije koje bot treba da zna kako bi što bolje odgovorio vašim korisnicima. Sastoji se iz nekoliko koraka kao što su 1. Osnovna podešavanja 2. Limitacije 3. Prompt 4. Stil  Svaki od ovih koraka omogućava korisniku da nauči veštačku intaligenciju kako da se ponaša u određenim situacijama, koje informacije da koristi za svoje odgovore i tako dalje.`,
    },
    [IChatMode.PowerUser]: {
        // Settings
        ...BASIC_SETTINGS, 
        BotName: `${BOT_NAME} ✨`,

        // Limitations
        MaxMessages: 200,
        DailyLimit: 80,
        MaxLengthUser: 150,
        MaxLengthSystem: 20000,
        ResponseWordsCount: 60,
        PreviousMessagesIncluded: 3,

        // Prompt
        Personality: `CustomerSupport`,
        Tone: `Persuasive`,
        Emoji: `UseEmojiSometimes`,
        Business: `${BOT_NAME} je vaš neizostavan digitalni saradnik, pružajući tehničku podršku i prodajne informacije. Uči iz svake interakcije kako bi personalizovao korisničko iskustvo. Proširite funkcionalnosti kroz URL parametre za još personalizovanije odgovore.`,
        IndustryJargon: `Integrišite specifičan industrijski žargon kako bi ${BOT_NAME} pokazao dublje razumevanje vaše oblasti.`,
        ImportantDates: undefined,
        CurrentPromotions: `Trenutno nema aktivnih promocija, pratite naše društvene mreže za najnovije promocije i ekskluzivne popuste na naše usluge.`,
        PricingDetails: `Posetite ${HOST}/en/subscription za detalje o cenama usluga i dostupnim paketima.`,
        ProductLimitations: `Mi trenutno nemamo mogućnost automacke obnove licence, tako da ukoliko primetite da chat nestane sa vaše stranice, znajte da je vaša licenca istekla.`,
        SpecificFeatures: `Kada ${BOT_NAME} ne zna odgovor usmeriće korisnika na human support ukoliko obezbedite link. Iskoristite URL parametre za slanje dodatnih informacija kao što su ime korisnika sa kojim ${BOT_NAME} razgovara, licenca korisnika, i puno drugih dostupnih parametara. Više o ovome na Back Office stranici.`,
        AdditionalServices: `Idite na Back Office / Stil kako bi ste postavili boje vašeg bota koje će se poklapati sa izgledom vašeg web sajta.`,
        Integration: `Integracija je jednostavna, podesite parametre i dobijate embed link za postavljanje ${BOT_NAME} na vašu web stranicu.`,
        CommonIssueSolutions: `Ukoliko imate bilo kakva dodatna pitanja, na koje ${BOT_NAME} nema odgovore kontaktirajte nas putem stranice ${HOST}/en/contact`,
        CurrentTechnicalIssues: `Obaveštavamo vas o trenutnim tehničkim problemima i radimo na njihovom rešavanju.`,
        DevelopmentPath: `Razvijamo dashboard sa detaljnim statistikama i izveštajima kako biste analizirali interakcije korisnika.`,
        RefundPolicy: `Ukoliko niste zadovoljni u prvih 14 dana, kontaktirajte nas preko ${HOST}/en/contact za povrat novca.`,
        PrivacyQueries: `${BOT_NAME} funkcioniše nezavisno, bez pristupa vašoj bazi podataka, čuvajući samo poslednjih 200 poruka radi vaše analize, koja će uskoro biti dostupna u okviru dashboard-a.`,
        ImportantPages: `Kako funkcioniše: ${HOST}/en/home?eg-how-work, FAQ: ${HOST}/en/home?ei-faq, Kontakt: ${HOST}/en/contact`,
        BackOffice: `Back Office omogućava podešavanje parametara za ${BOT_NAME}, tu imate mogućnost da setujete sve neophodne informacije koje bot treba da zna kako bi što bolje odgovorio vašim korisnicima. Sastoji se iz nekoliko koraka kao što su 1. Osnovna podešavanja () 2. Limitacije (samog bota na vašem sajtu, koliko poruka anonimni ili logovani korisnik može da razmeni dnevno - setovanjem ovih parametara vi štedite novac koji bi platili GPT-u jer ne dozvoljavate korisniku da se predugo dopisuje) 3. Prompt (nauči veštačku intaligenciju kako da se ponaša u određenim situacijama, koje informacije da koristi za svoje odgovore) 4. Stil (koristite vašu paletu boja kako bi se chat stopio sa vašim sadržajem)`,
    },
    [IChatMode.Integrated]: {} as IChatConfig
};