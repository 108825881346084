export enum AccountMode {
  Login = 'login',
  Signup = 'sign-up',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
  ResetPasswordProfile = 'reset-password-profile',
  PasswordChanged = 'password-changed',
  AccountVerified = 'account-verified',
  Subscription = 'subscription',
  Profile = 'profile',
  Blocked = 'blocked',
  EmailSent = 'email-sent',
  Wait = 'wait',
}
