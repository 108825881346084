import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { IMedia } from '../account/models/media.interface';

@Component({
  selector: 'media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateX(0%)', opacity: 0.4 }),
        animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(-0%)', opacity: 0.4 }),
        animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class MediaViewerComponent implements OnInit {
  @Input() media: IMedia[] = [];
  @Input() selectedMedia: IMedia | undefined;
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  public currentIndex: number = 0;
  public closedAnimation: boolean = false;

  public ngOnInit(): void {
    if (this.selectedMedia) {
      this.currentIndex = this.media.indexOf(this.selectedMedia);
    }
  }

  public prevImage(): void {
    this.currentIndex--;

    if (this.currentIndex < 0) {
      this.currentIndex = this.media.length - 1;
    }
  }

  public nextImage(): void {
    this.currentIndex++;

    if (this.currentIndex >= this.media.length) {
      this.currentIndex = 0;
    }
  }

  public closeMedia(): void {
    this.closedAnimation = true;
    setTimeout(() => {
      this.closed.emit();
    }, 600);
  }

  public get currentImage(): IMedia {
    return this.media[this.currentIndex];
  }
}
