import { SharedModule } from '@app/shared/modules/shared.module';
import { ChatSupportComponent } from './chat-support.component';
import { ApiChatSupportService } from './api/api.chat.service';
import { MatIconModule } from '@angular/material/icon';
import { PlatformModule } from '@angular/cdk/platform';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ChatSupportComponent],
  imports: [CommonModule, PlatformModule, TranslateModule, MatIconModule, FormsModule, SharedModule],
  exports: [ChatSupportComponent],
  providers: [ApiChatSupportService],
})
export class ChatSupportModule {}
