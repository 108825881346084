export enum IChatMode {
    /** Public: When user visit website, but don't have account */
    Public,
  
    /** User: When user have account, but didn't purchase package */
    User,
    
    /** PowerUser: When user have account, and purchase package, and he is currently on our website */
    PowerUser,
  
    /** Integrated: Chatbot is integrated on user website */
    Integrated
  }