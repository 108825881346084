import { ErrorHandlerComponent } from '../components/auto-form/error-handler/error-handler.component';
import { MediaViewerComponent } from '@app/shared/components/media-viewer/media-viewer.component';
import { PhoneAnimationComponent } from '../components/phone-animation/phone-animation.component';
import { I18nProviderModule } from '@app/core/routing/language/engine/I18n-provider.module';
import { ContactFormComponent } from '../components/contact-form/contact-form.component';
import { GeoLocationComponent } from '../components/geo-location/geo-location.component';
import { MediaUploadComponent } from '../components/media-upload/media-upload.component';
import { DialogComponent } from '@app/shared/components/dialog/dialog.component';
import { CountdownComponent } from '../components/countdown/countdown.component';
import { AutoFormComponent } from '../components/auto-form/auto-form.component';
import { AccountComponent } from '../components/account/account.component';
import { MultiSwitchCasePipe } from '../pipes/multi-switch-case.pipe';
import { AppMenuComponent } from '../components/menu/menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeURLPipe } from '@app/shared/pipes/safe-url.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ReplacePipe } from '../pipes/replace-pipe';
import { MaterialModule } from './material.module';
import { CloudinaryModule } from '@cloudinary/ng';
import { EmojiPipe } from '../pipes/emoji-pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    // Components
    AccountComponent,
    AutoFormComponent,
    ErrorHandlerComponent,
    DialogComponent,
    ContactFormComponent,
    GeoLocationComponent,
    MediaUploadComponent,
    MediaViewerComponent,
    AppMenuComponent,
    CountdownComponent,
    PhoneAnimationComponent,

    // Pipes
    MultiSwitchCasePipe,
    SafeURLPipe,
    ReplacePipe,
    EmojiPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    CloudinaryModule,
    I18nProviderModule,
  ],
  exports: [
    // Components
    AccountComponent,
    AutoFormComponent,
    ErrorHandlerComponent,
    DialogComponent,
    ContactFormComponent,
    GeoLocationComponent,
    MediaUploadComponent,
    MediaViewerComponent,
    AppMenuComponent,
    CountdownComponent,
    PhoneAnimationComponent,

    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    CloudinaryModule,
    I18nProviderModule,

    // Pipes
    MultiSwitchCasePipe,
    SafeURLPipe,
    ReplacePipe,
    EmojiPipe
  ],
})
export class SharedModule {}
