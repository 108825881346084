import { IChatMessage, IChatSupport } from '@app/core/components/chat-support/models/chat-support.interface';
import { SupportSwitch } from '@app/core/components/chat-support/store-chat/support/chat-support.actions';
import { IChatTheme } from '@app/features/back-office/interfaces/chat-style.interface';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UsersState } from '@app/shared/components/account/store-user/users.state';
import { debounceTime, fromEvent, Observable, Subscription } from 'rxjs';
import { UnitsService } from '@app/shared/services/units.service';
import { URLService } from '@app/shared/services/url.service';
import { IUser } from '@app/shared/models/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { Select, Store } from '@ngxs/store';
import { IChatConfig } from '@app/features/back-office/interfaces/chat-config.interface';

@Component({
  selector: 'phone-animation',
  templateUrl: './phone-animation.component.html',
  styleUrls: ['./phone-animation.component.scss'],
})
export class PhoneAnimationComponent implements OnInit, OnDestroy {   
    @Select(UsersState.selectUser) user$!: Observable<IUser | null>;
    @ViewChild('phoneChatInput') phoneChatInput: ElementRef | undefined;
    @ViewChild('phoneChat') phoneChat: ElementRef | undefined;
    @ViewChild('demoVideo') demoVideo: ElementRef | undefined;

    @Input() config: IChatConfig | undefined;
    @Input() theme: IChatTheme = {
        "HeaderFooterBg":"#b45add",
        "HeaderText":"#ffffff",
        "HeaderSubText":"#ffffff",
        "CloseButton":"#ffffff",
        "ProgressBarBg":"#eeeeee",
        "Progress":"#9c00e2",
        "MessageAIBg":"#f2d6ff",
        "MessageUserBg":"#b5def2",
        "MessageUserText":"#303030",
        "FooterIcons":"#ffffff",
        "ChatButtonBg":"#e50fe5",
        "ChatButtonIcon":"#ffffff",
        "Name":"t1"
      };
    
    public step: number = 0;
    public currentDate: string = Date.now().toString();
  
    public typing: boolean = false; // Assistant typing
    public userPrompt: string = ''; // User typing
    private typingTimerId: any = null;
  
    public scrollSubscription: Subscription = new Subscription();
    public phoneChatInViewPort: boolean = false;
    public browserTabInViewPort: boolean = true;
    public simulationAllowed: boolean = true;
  
    private messages = [
      // Initial curiosity and greeting
      {"role":"user","content":"initial_curiosity"},
      {"role":"assistant","content":"greeting_and_assurance"},
    
      // Inquiry about website integration
      {"role":"user","content":"website_integration_inquiry"},
      {"role":"assistant","content":"website_integration_confirmation"},
    
      // Questions about customizability
      {"role":"user","content":"customization_query"},
      {"role":"assistant","content":"customization_confirmation"},
      {"role":"user","content":"user_experience_theme"},
      {"role":"assistant","content":"user_experience_theme_details"},
      {"role":"user","content":"company_logo"},
      {"role":"assistant","content":"company_logo_details"},
    
      // Concerns about user experience
      {"role":"user","content":"user_experience_concern"},
      {"role":"assistant","content":"user_experience_reassurance"},
    
      // Interest in automation benefits
      {"role":"user","content":"automation_benefits_interest"},
      {"role":"assistant","content":"automation_benefits_details"},
    
      // Inquiry about 24/7 availability
      {"role":"user","content":"availability_question"},
      {"role":"assistant","content":"availability_affirmation"},
    
      // Doubts about handling complex queries
      {"role":"user","content":"complex_queries_doubt"},
      {"role":"assistant","content":"complex_queries_handling"},
      {"role":"user","content":"complex_queries_training"},
      {"role":"assistant","content":"complex_queries_training_details"},
      {"role":"user","content":"complex_queries_human_support"},
      {"role":"assistant","content":"complex_queries_human_support_details"},
    
      // Question about data security
      {"role":"user","content":"data_security_question"},
      {"role":"assistant","content":"data_security_reassurance"},
      {"role":"user","content":"data_security_experience"},
      {"role":"assistant","content":"data_security_experience_details"},
      {"role":"user","content":"data_security_experience_prompt"},
      {"role":"assistant","content":"data_security_experience_prompt_details"},
    
      // Inquiry about scalability
      {"role":"user","content":"scalability_inquiry"},
      {"role":"assistant","content":"scalability_confirmation"},
    
      // Interest in analytics and reporting
      {"role":"user","content":"analytics_interest"},
      {"role":"assistant","content":"analytics_details"},
      {"role":"user","content":"analytics_details_details"},
      {"role":"assistant","content":"analytics_details_info"},
    
      // User asks about cost efficiency
      {"role":"user","content":"cost_efficiency_query"},
      {"role":"assistant","content":"cost_efficiency_explanation"},
    
      // Inquiry about support and maintenance
      {"role":"user","content":"support_maintenance_question"},
      {"role":"assistant","content":"support_maintenance_details"},
    
      // Concerns about technical requirements
      {"role":"user","content":"technical_requirements_concern"},
      {"role":"assistant","content":"technical_requirements_reassurance"},
    
      // Questions about industry adaptability
      {"role":"user","content":"industry_adaptability_query"},
      {"role":"assistant","content":"industry_adaptability_confirmation"},
    
      // Inquiry about customer engagement
      {"role":"user","content":"customer_engagement_interest"},
      {"role":"assistant","content":"customer_engagement_details"},
    
      // User inquires about multilingual support
      {"role":"user","content":"multilingual_support_question"},
      {"role":"assistant","content":"multilingual_support_confirmation"},
    
      // User asks about integrating with existing systems
      {"role":"user","content":"integration_query"},
      {"role":"assistant","content":"integration_assurance"},
    
      // User is curious about the AI's learning over time
      {"role":"user","content":"ai_learning_curiosity"},
      {"role":"assistant","content":"ai_learning_explanation"},
    
      // User wonders about the chatbot's personality customization
      {"role":"user","content":"chatbot_personality_customization_inquiry"},
      {"role":"assistant","content":"chatbot_personality_customization_response"},
    
      // User is ready to commit to the service
      {"role":"user","content":"commitment_readiness_confirmation"},
      {"role":"assistant","content":"commitment_welcome_response"},
    
      // User asks for best practices on launching the chatbot
      {"role":"user","content":"launch_best_practices_query"},
      {"role":"assistant","content":"launch_best_practices_advice"},
      {"role":"user","content":"launch_best_practices_advice_info"},
      {"role":"assistant","content":"launch_best_practices_advice_final_step"},
    
      // User confirms the purchase decision
      {"role":"user","content":"purchase_decision_confirmation"},
      {"role":"assistant","content":"purchase_appreciation_response"},
    
      // User thanks for a successful setup and launch
      {"role":"user","content":"successful_setup_and_launch_thanks"},
      {"role":"assistant","content":"successful_setup_and_launch_gratitude"},
      {"role":"user","content":"successful_setup_other_websites"},
      {"role":"assistant","content":"successful_setup_other_websites_details"},
      {"role":"user","content":"successful_setup_other_sectors"}
    ]; 

    public support: IChatSupport = 
      {"Messages":[{"role":"break","content":"2023-11-04T05:51:19.796Z"}],
      "Started":"2023-11-04T05:51:19.796Z",
      "Gender":"Male",
      "Image":"M40.png",
      "Usage":5
    }

  
    constructor(public _url: URLService, public _store: Store, public _units: UnitsService, public _translate: TranslateService, public _platform: Platform) {}
  
    public ngOnInit(): void { 
      this.chatSimulation(0);
  
      document.addEventListener('visibilitychange', () => {
        this.browserTabInViewPort = document.visibilityState === 'visible';
        this.falseScroll();
      });
    }
  
    public ngOnDestroy(): void {
      this.simulationAllowed = false;
      this.scrollSubscription.unsubscribe();
    }
  
    public ngAfterViewInit(): void {
      this.checkIfElementInView();
    }
  
    public checkIfElementInView(): void {
      setTimeout(() => {
        this.scrollSubscription = fromEvent(window, 'scroll').pipe(
          debounceTime(20)
        ).subscribe(() => {
          this.phoneChatInViewPort = this.isElementVisible(this.phoneChat?.nativeElement, 60); // PHONE CHAT
        });
        this.falseScroll();
      }, 1000);
    }
  
    public isElementVisible(element: HTMLElement, viewPercentage: number): boolean {
      const rect = element.getBoundingClientRect();
      const elementHeight = rect.height;
      const topBoundary = rect.top;
      const bottomBoundary = rect.bottom;
      const visibleHeight = Math.max(0, Math.min(elementHeight, bottomBoundary) - Math.max(0, topBoundary));
      const visiblePercentage = (visibleHeight / elementHeight) * 100;
      return visiblePercentage >= viewPercentage;
    }
  
    public simpleOpenChat(): void {
      this._store.dispatch(new SupportSwitch(true));
    }
  
    public openChat(): void {
      this._url.navigate('Home', 'ei-faq');
      if(window.innerWidth > 768){
        setTimeout(() => {
          this._store.dispatch(new SupportSwitch(true));
        }, 800);
      }
    }
  
    public chatSimulation(index: number): void {
      if(this.simulationAllowed){
        const time: number = index === 0 ? 300 : this._translate.instant(this.messages[index-1].content).length * 50;
        this.resetTyping();
        
        setTimeout(() => {
          this.typing = this.messages[index].role === 'assistant'; 
          if(this.typing) {
            this.scrollToBottomOfChat();
        } else if (this.browserTabInViewPort && this.phoneChatInViewPort && index > 0 && this.messages[index].role === 'user'){
          this.simulateTyping(this._translate.instant(this.messages[index].content));
        } 
      }, 1000);
      
        setTimeout(() => {
          if(this.browserTabInViewPort && this.phoneChatInViewPort){
            const message = this.messages[index];
                if(message) {
                  this.typing = false;
                  this.support.Messages.push(message as IChatMessage);
                  this.scrollToBottomOfChat();
                }
        
                if(index < this.messages.length){
                  this.chatSimulation(index+1);
                } else this.chatSimulation(3);
    
            } else { setTimeout(() => {
              this.chatSimulation(index);
            }, 1000); }
          }, time)
      }
    }
  
    public simulateTyping(inputText: string) {
      this.resetTyping();
  
      const addCharacter = (index: number) => {
        if (index < inputText.length) {
          this.userPrompt += inputText[index];
          this.typingTimerId = setTimeout(() => {
            addCharacter(index + 1)
            const element = this.phoneChatInput?.nativeElement;
            element.scrollLeft = element.scrollWidth;
          }, 40);
        } else {
          this.typingTimerId = null;
        }
      };
  
      addCharacter(0);
    }
  
    public resetTyping(): void {
      clearTimeout(this.typingTimerId);
      this.userPrompt = ''; 
    } 
  
    private scrollToBottomOfChat(): void {
      setTimeout(() => {
        document.getElementById('chat')?.scroll({
          top: document.getElementById('chat')?.scrollHeight,
          left: 0,
          behavior: 'smooth' as ScrollBehavior
        });
      }, 200);
    }
  
    private falseScroll(): void {
      const scrollEvent = new Event('scroll');
        window.dispatchEvent(scrollEvent);
    }
}
