export interface IEmail { 
    /** Name of our customer */
    userName?: string;

    /** Our supported email => default: support */
    from?: string;
  
    /** Email of our customer */
    to: string;
  
    /** Translation key */
    subject: string;
  
    /** Translation key */
    body: string;
  
    /** Translation key */
    button?: string;

    /** Event on button click inside email */
    buttonURL?: string;
  
    /** Translation key */
    successMsg?: string;
  
    /** Translation key */
    failedMsg?: string;
  }
  
  export enum IEmailStatus {
    Ready = 'ready',
    Sending = 'sending',
    Sent = 'sent',
    Failed = 'failed',
  }