import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ValidatorAutoComplete(validOptions: Array<string>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (validOptions.indexOf(control.value) !== -1) {
      return null; /* valid option selected */
    }

    return { autoComplete: { value: control.value } };
  };
}

// Test example
export function ValidatorAutoCompleteObject(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return { autoComplete: { value: control.value } };
    }

    return null; /* valid option selected */
  };
}

export function ValidatorEmail(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value) {
    const EMAIL_REGEXP: any =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    ValidatorNoWhitespace(control);

    if (!EMAIL_REGEXP.test(control.value)) {
      return { emailPattern: true };
    }
  }

  return null;
}

export function ValidatorURL(control: AbstractControl): { [key: string]: any } | null {
  if (control.value) {
    const URL_REGEXP = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*(\?.*)?(#[\w-]*)?$/;

    if (!URL_REGEXP.test(control.value)) {
      return { urlPattern: true };
    }
  }

  return null;
}


export function ValidatorNoWhitespace(control: AbstractControl): ValidatorFn | null {
  if (control.value && control.value.includes(' ')) {
    const trimmed: string = control.value.replace(/\s/g, '');
    control.setValue(trimmed);
  }

  return null;
}

export function ValidatorEvenNumber(control: AbstractControl): { [key: string]: any } | null {
  return +control.value % 2 === 0 ? null : { evenNumber: true };
}

export function ValidatorOddNumber(control: AbstractControl): { [key: string]: any } | null {
  return +control.value % 2 !== 0 ? null : { oddNumber: true };
}
