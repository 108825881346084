import { ContactFrom, PartnerContactFrom } from "@app/shared/components/contact-form/models/contact-form.interface";
import { IUser } from "@app/shared/models/user.interface";
import { environment } from "@environments/environment";
import { TranslateService } from "@ngx-translate/core";

export const contactTemplate = (contact: ContactFrom, translate: TranslateService) => {
    const emailBody: string = `
        <h4 style="color: #16e18b">${translate.instant('Application')}: ${environment.app.name}</h4>
        <h3>${contact.FirstName} ${translate.instant('SentYouMsg')}:</h3>
        <p>${contact.Content}</p>
        <h5 style="color: #16e18b">${translate.instant('AnswerThisClientOn')}: <b>${contact.Email}</b></h5>`;
    
        return emailBody;
}

export const scheduleMeetingEmailTemplate = (user: IUser, partnerContact: PartnerContactFrom, translate: TranslateService) => {
    let body: string = `
    <style>
      .email-content hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #eeeeee;
      }
      .email-content div {
        font-size: 14px;
        line-height: 22px;
        font-family: system-ui;
        color: #555555;
      }
    </style>

    <div class="email-content">
      {UserId}
      {UserName}
      {UserEmail}
      <div><b>Interest:</b> ${translate.instant(partnerContact.PartnerInterest)}</div>
    <hr>
      <div><b>Meeting date:</b> ${partnerContact.MeetingDate}</div>
      <div><b>Availability:</b> From ${partnerContact.AvailableFrom} To ${partnerContact.AvailableUntil}</div>
    <hr>
      <div><b>Message:</b> ${partnerContact.Content}</div>
    </div>
  `;

  if(user?.Id) {
    body = body.replace('{UserId}', `<div><b>ID:</b> ${user.Id}</div>`);
    body = body.replace('{UserName}', `<div><b>Name:</b> ${user.FirstName} ${user.LastName}</div>`);
    body = body.replace('{UserEmail}', `<div><b>Email:</b> ${user.Email}</div>`);
  } else {
    body = body.replace('{UserId}', ``);
    body = body.replace('{UserName}', `<div><b>Name:</b> ${partnerContact.FirstName}</div>`);
    body = body.replace('{UserEmail}', `<div><b>Email:</b> ${translate.instant(partnerContact.Email)}</div>`);
  }

  return body;
};