<ng-container *ngIf="media.length">
  <ng-container *ngIf="selectedMedia?.Url">
    <!-- Main media -->
    <ng-container *ngIf="mediaType === 'image'">
      <img [src]="selectedMedia?.Url" loading="lazy"/>
    </ng-container>

    <ng-container *ngIf="mediaType === 'video'">
      <video controls>
        <source [src]="selectedMedia?.Url" loading="lazy">
      </video>
    </ng-container>

    <!-- Media actions -->
    <div class="action fade-in">
        <div class="delete">
          <div *ngIf="deleteConfirm" class="fade-in">
            <span>{{'DeletePermanently' | translate}}</span>
            <b (click)="deleteMedia();">{{'Yes' | translate}}</b><b (click)="deleteConfirm = false;">{{'No' | translate}}</b>
          </div><mat-icon *ngIf="!deleteConfirm" (click)="deleteConfirm = true;" class="fade-in">delete_forever</mat-icon>
        </div>
        <div class="close">
          <mat-icon (click)="selectedMedia = undefined;" class="fade-in">expand_more</mat-icon>
        </div>
        <div class="fullscreen" *ngIf="mediaType === 'image'">
          <mat-icon (click)="fullScreen = true;" class="fade-in">zoom_out_map</mat-icon>
        </div>
    </div>
  </ng-container>

  <!-- Media list -->
  <div class="media-list" cdkDropListOrientation="horizontal" cdkDropList (cdkDropListDropped)="dropMedia($event)">
    <div class="box" *ngFor="let m of media" cdkDrag (click)="selectedMedia = m;">

      <ng-container *ngIf="mediaType === 'image'">
        <img [src]="m.Url" loading="lazy"/>
      </ng-container>

      <ng-container *ngIf="mediaType === 'video'">
        <video>
          <source [src]="m.Url" loading="lazy">
        </video>
      </ng-container>

    </div>
  </div>
</ng-container>

<!-- Media widget -->
<button mat-raised-button *ngIf="!mediaReorder" type="button" color="primary" 
  [disabled]="disabled || (uploadStatus$ | async)! > 0 ||
  (config.MinFiles! === config.MaxFiles!) ||
  (media.length === config.MaxFiles!)"
  (click)="showWidget();">

  <mat-icon *ngIf="!config.Icon">cloud_upload</mat-icon>
  <mat-icon *ngIf="config.Icon">{{config.Icon}}</mat-icon>

  <ng-container *ngIf="config.MaxFiles! > 1">
    <span *ngIf="!config.Label">{{'UploadMediaList' | translate}}</span>
    <span *ngIf="config.Label">{{config.Label | translate}}</span>
    <span *ngIf="config.MaxFiles! - media.length !== 0" class="max-files">&nbsp;({{'Max' | translate}}: {{config.MaxFiles! - media.length}})</span>
  </ng-container>

  <ng-container *ngIf="config.MaxFiles! === 1">
    <span *ngIf="!config.Label">{{'UploadMedia' | translate}}</span>
    <span *ngIf="config.Label">{{config.Label | translate}}</span>
  </ng-container>
</button>

<!-- Save reorder -->
<button mat-raised-button *ngIf="mediaReorder" type="button" color="primary" (click)="saveReordering();">
    <mat-icon *ngIf="!config.Icon">save</mat-icon>
    {{'SaveReordering' | translate}}
</button>

<!-- Error -->
<mat-error *ngIf="touch && (config.MinFiles! - media.length > 0)" class="fade-in">
  <span *ngIf="config.MinFiles! === 1">{{'UploadAtLeastSingle' | translate}}</span>
  <span *ngIf="config.MinFiles! > 1">{{'UploadAtLeastMultiple' | translate:{num: config.MinFiles! - media.length} }}</span>
</mat-error>

<media-viewer 
    *ngIf="fullScreen" 
    [media]="media" 
    [selectedMedia]="selectedMedia" 
    (closed)="fullScreen = false;">
</media-viewer>