import { InternetStatusService } from '@interceptor/Internet-status.service';
import { FirestoreCollection } from '@config/firestore.collections.config';
import { ISubscription } from '../interfaces/subscription.interface';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first, from, map, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ApiSubscription {
  constructor(private db: AngularFirestore, private _net: InternetStatusService) {}

  public getSubscription(userId: string): Observable<ISubscription | null> {
    if (this._net.isOnline()) {
      return this.db
        .collection<ISubscription[]>(FirestoreCollection.Subscription, (ref: any) => ref.where('UserId', '==', userId))
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? x[0] : null)));
    } else return of(null);
  }

  public getSubDomainSubscription(subDomain: string): Observable<ISubscription | null> {
    if (this._net.isOnline()) {
      return this.db
        .collection<ISubscription[]>(FirestoreCollection.Subscription, (ref: any) =>
          ref.where('SubDomain', '==', subDomain),
        )
        .valueChanges()
        .pipe(first())
        .pipe(map((x: any[]) => (x?.length > 0 ? x[0] : null)));
    } else return of(null);
  }

  public addSubscription(subscription: ISubscription): Observable<boolean> {
    if (this._net.isOnline()) {
      const sub: any = {
        ...subscription,
        ValidUntil: new Date(subscription.ValidUntil).toISOString(),
        Purchased: new Date(subscription.Purchased).toISOString(),
      };

      return from(
        this.db
          .doc(`${FirestoreCollection.Subscription}/${subscription.UserId}`)
          .set(sub)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          }),
      );
    } else return of(false);
  }

  public updateSubscription(subscription: ISubscription): Observable<boolean> {
    if (this._net.isOnline()) {
      const sub: any = {
        ...subscription,
        ValidUntil: new Date(subscription.ValidUntil).toISOString(),
        Purchased: new Date(subscription.Purchased).toISOString(),
      };

      return from(
        this.db
          .doc(`${FirestoreCollection.Subscription}/${subscription.UserId}`)
          .update(sub)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          }),
      );
    } else return of(false);
  }
}
