import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements AfterViewInit {
  @Input() background: string | undefined;
  @Input() color: string | undefined;
  @Input() header: string = 'CountdownTo';
  @Input() targetDate: Date | undefined;

  @ViewChild('days', { static: true }) days!: ElementRef;
  @ViewChild('hours', { static: true }) hours!: ElementRef;
  @ViewChild('minutes', { static: true }) minutes!: ElementRef;
  @ViewChild('seconds', { static: true }) seconds!: ElementRef;

  public date: Date | undefined;
  public difference: number = 0;
  public now: number = 0;
  public targetTime: any;

  public ngAfterViewInit(): void {
    if (this.targetDate) {
      this.targetTime = this.targetDate.getTime();

      setInterval(() => {
        this.tickTock();
        this.difference = this.targetTime - this.now;
        this.difference = this.difference / (1000 * 60 * 60 * 24);

        // !isNaN(this.days.nativeElement.innerText) ?
        //   ? (this.days.nativeElement.innerText = Math.floor(this.difference))
        //   (this.days.nativeElement.innerHTML = '<img src="https://i.gifer.com/VAyR.gif" />');
      }, 1000);
    } else {
      // eslint-disable-next-line quotes
      console.error(`You didn't provide 'targetDate' for CountdownComponent`);
    }
  }

  public tickTock(): void {
    this.date = new Date();
    this.now = this.date.getTime();
    this.days.nativeElement.innerText = Math.floor(this.difference);
    this.hours.nativeElement.innerText = 23 - this.date.getHours();
    this.minutes.nativeElement.innerText = 60 - this.date.getMinutes();
    this.seconds.nativeElement.innerText = 60 - this.date.getSeconds();
  }
}
