import { IFrameLogin, Logout } from '@app/shared/components/account/store-auth/auth.actions';
import { URLService } from '@app/shared/services/url.service';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
})
export class BlankComponent implements OnDestroy {
  constructor(private _store: Store, private _url: URLService) {
    const urlParams = new URLSearchParams(window.location.search);
    const companyId: string | null = urlParams.get('companyId');
    if (companyId) { 
      this._store.dispatch(new IFrameLogin(companyId)); 
    } else {
          this._store.dispatch(new Logout());
              console.error(
                // eslint-disable-next-line max-len
                '%cIMPORTANT: Direct access to chat page without companyId parameter is forbidden!',
                'font-size: 16px; color: red; font-weight: bold;',
              );
    }
  }

  public ngOnDestroy(): void {
    this._store.dispatch(new Logout());
  }
}
