import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emoji' })
export class EmojiPipe implements PipeTransform {
  public transform(input: string): string {
    if (input) {
      let text: string = input;

      for (const emoji of emojisList) {
        const regex: RegExp = new RegExp(this.escapeRegExp(emoji.text), 'g');
        text = text.replace(regex, emoji.hex);
      }

      return text;
    } else return input;
  }

  private escapeRegExp(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
}

const emojisList: IEmojiInterface[] = [
  { text: ':)', hex: '&#x1F642;' },
  { text: ':D', hex: '&#x1F603;' },
  { text: ':(', hex: '&#x1F61E;' },
  { text: ':p', hex: '&#x1F61B;' },
  { text: ':O', hex: '&#x1F632;' },
  { text: ';)', hex: '&#x1F609;' },
  { text: ':|', hex: '&#x1F610;' },
  { text: ':*', hex: '&#x1F617;' },
  { text: ':$', hex: '&#x1F911;' },
  { text: ':s', hex: '&#x1F616;' },
  // eslint-disable-next-line quotes
  { text: ":'(", hex: '&#x1F622;' },
  { text: ':X', hex: '&#x1F635;' },
  { text: ':3', hex: '&#x1F638;' },
  { text: ':|)', hex: '&#x1F607;' },
  { text: '>:(', hex: '&#x1F621;' },
  { text: '<3', hex: '&#x2764;' },
  { text: '8-)', hex: '&#x1F60E;' },
  { text: '^_^', hex: '&#x1F60A;' },
  { text: '-_-', hex: '&#x1F611;' },
  { text: ':poop:', hex: '&#x1F4A9;' },
  { text: ':fire:', hex: '&#x1F525;' },
  { text: ':thumbsup:', hex: '&#x1F44D;' },
  { text: ':thumbsdown:', hex: '&#x1F44E;' },
  { text: ':muscle:', hex: '&#x1F4AA;' },
  { text: ':pray:', hex: '&#x1F64F;' },
  { text: ':star:', hex: '&#x2B50;' },
  { text: ':gift:', hex: '&#x1F381;' },
  { text: ':bell:', hex: '&#x1F514;' },
  { text: ':beer:', hex: '&#x1F37A;' },
  { text: ':cake:', hex: '&#x1F370;' },
  { text: ':ghost:', hex: '&#x1F47B;' },
  { text: ':sun:', hex: '&#x2600;' },
  { text: ':moon:', hex: '&#x1F319;' },
  { text: ':umbrella:', hex: '&#x2614;' },
  { text: ':clock:', hex: '&#x1F570;' },
  { text: ':email:', hex: '&#x2709;' },
  { text: ':phone:', hex: '&#x260E;' },
  { text: ':book:', hex: '&#x1F4D6;' },
  { text: ':camera:', hex: '&#x1F4F7;' },
  { text: ':music:', hex: '&#x1F3B5;' },
  { text: ':video:', hex: '&#x1F3A5;' },
  { text: ':computer:', hex: '&#x1F4BB;' },
  { text: ':lock:', hex: '&#x1F512;' },
  { text: ':key:', hex: '&#x1F511;' },
  { text: ':wrench:', hex: '&#x1F527;' },
  { text: ':hammer:', hex: '&#x1F528;' },
  { text: ':bulb:', hex: '&#x1F4A1;' },
  { text: ':flag:', hex: '&#x1F3F3;' },
  { text: ':world:', hex: '&#x1F30D;' },
  { text: ':heart:', hex: '&#x2764;' },
];

interface IEmojiInterface {
  text: string;
  hex: string;
}
