<form [formGroup]="formGroup" (ngSubmit)="formSubmitted()" [ngClass]="inline ? 'row' : 'column'">
  <ng-container *ngFor="let element of form">
    <ng-container [ngSwitch]="element.Type">
      <ng-container *ngIf="!search?.length || _translate.instant(element.Label ? (element.Label | translate) : (element.Name | translate)).toLowerCase().includes(search?.toLowerCase())">

      <!-- TEXT -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="'text'"
           [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style" class="fade-in">
           <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
           <input 
             matInput 
             [id]="element.AutoFill === false ? element.Name + '-' + form_Id : ''"
             [placeholder]="element.Placeholder ? (element.Placeholder | translate) : ''"
             [formControlName]="element.Name" 
             [attr.autocomplete]="element.AutoFill === false ? 'off' : ''"
             (focus)="focusedElementId = element.Name" 
             (blur)="focusedElementId = undefined"/>
           <mat-icon matSuffix *ngIf="element.Icon && !element.Clear">{{ element.Icon }}</mat-icon>
           <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
           <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
           <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field>

      <!-- NUMBER -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="'number'"
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style">
        <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
        <input
          matInput
          type="number"
          [placeholder]="element.Placeholder ? (element.Placeholder | translate) : ''"
          [formControlName]="element.Name"
          [attr.autocomplete]="element.AutoFill === false ? 'off' : ''"
          [id]="element.AutoFill === false ? element.Name + '-' + form_Id : ''"
          [min]="element.MinValue ? +element.MinValue : null"
          [max]="element.MaxValue ? +element.MaxValue : null"
          (focus)="focusedElementId = element.Name"
          (blur)="focusedElementId = undefined"/>
        <mat-icon matSuffix *ngIf="element.Icon && !element.Clear">{{ element.Icon }}</mat-icon>
        <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
        <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
        <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field>

      <!-- EMAIL -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="'email'"
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style" >
        <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
        <input
          matInput
          [placeholder]="element.Placeholder ? element.Placeholder : 'post@example.com'"
          [formControlName]="element.Name"
          [attr.autocomplete]="element.AutoFill === false ? 'off' : ''"
          [id]="element.AutoFill === false ? element.Name + '-' + form_Id : ''"
          [min]="element.MinValue ? +element.MinValue : null"
          [max]="element.MaxValue ? +element.MaxValue : null"
          (focus)="focusedElementId = element.Name"
          (blur)="focusedElementId = undefined"/>
        <mat-icon matSuffix *ngIf="!element.Clear">email</mat-icon>
        <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
        <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
        <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field>

      <!-- PASSWORD -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="'password'"
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style">
        <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
        <input
          #password
          matInput
          type="password"
          [formControlName]="element.Name"
          [placeholder]="element.Placeholder ? (element.Placeholder | translate) : ''"
          [attr.autocomplete]="element.AutoFill === false ? form_Id : ''"
          [id]="element.AutoFill === false ? element.Name + '-' + form_Id : ''"
          (focus)="focusedElementId = element.Name"
          (blur)="focusedElementId = undefined"/>
        <mat-icon matSuffix
          (mousedown)="changePasswordType(password, 'down')"
          (mouseup)="changePasswordType(password, 'up')">
          <span *ngIf="password.type === 'password'">visibility_off</span>
          <span *ngIf="password.type === 'text'">visibility</span>
        </mat-icon>
        <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
        <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field>

      <!-- TEXT AREA -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="'textarea'"
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''"
        [style]="element.Style">
        <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          [placeholder]="element.Placeholder ? (element.Placeholder | translate) : ''"
          [formControlName]="element.Name"
          [cdkAutosizeMinRows]="textAreaMinRows"
          [cdkAutosizeMaxRows]="textAreaMaxRows"
          (focus)="focusedElementId = element.Name"
          (blur)="focusedElementId = undefined">
        </textarea>
        <mat-icon matSuffix *ngIf="element.Icon && !element.Clear">{{ element.Icon }}</mat-icon>
        <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
        <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
        <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field>

      <!-- SELECT -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="'select'"
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''"
        [style]="element.Style">
        <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
        <mat-select matSelect [formControlName]="element.Name" (openedChange)="focusedElementId = element.Name">
          <mat-option *ngFor="let option of element.Options; let i=index;" [value]="option">{{ option | translate }}
            <span *ngIf="element.OptionsDesc && element.OptionsDesc[i]" style="color: #6b6b7f"> - {{element.OptionsDesc[i] | translate}}</span>
          </mat-option>
        </mat-select>
        <mat-icon matSuffix *ngIf="element.Icon && !element.Clear">{{ element.Icon }}</mat-icon>
        <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
        <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
        <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field>

      <!-- SELECT IMG -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="'select-img'"
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''"
        [style]="element.Style">
        <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
        <img class="selected-img" *ngIf="formGroup.get(element.Name)?.value" [src]="formGroup.get(element.Name)?.value">
        <mat-select matSelect class="selected-img-text" [formControlName]="element.Name" (openedChange)="focusedElementId = element.Name">
          <mat-option *ngIf="formGroup.get(element.Name)?.value" [value]="null"><b>{{'Deselect' | translate}}</b></mat-option>
          <mat-option *ngFor="let option of element.Options; let i=index" [value]="option">
            <img [src]="option" width="30" height="30">
            <span *ngIf="element.OptionsDesc && element.OptionsDesc[i]">{{element.OptionsDesc[i] | translate}}</span>
          </mat-option>
        </mat-select>
        <mat-icon matSuffix *ngIf="element.Icon && !element.Clear">{{ element.Icon }}</mat-icon>
        <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
        <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
        <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field>

      <!-- AUTOCOMPLETE -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="'autocomplete'"
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style">
        <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
        <input
          matInput
          #autoCompleteInput
          [placeholder]="element.Placeholder ? (element.Placeholder | translate) : ''"
          [formControlName]="element.Name"
          [matAutocomplete]="autoComplete"
          (focus)="focusedElementId = element.Name"
          (blur)="focusedElementId = undefined"/>
        <mat-autocomplete autoActiveFirstOption #autoComplete [displayWith]="autoCompleteDisplay.bind(this)">
          <ng-container *ngFor="let option of element.Options">
            <mat-option *ngIf="_translate.instant(option).toLowerCase().includes(autoCompleteInput.value.toLowerCase())" [value]="option">
              {{ option | translate }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
        <mat-icon matSuffix *ngIf="element.Icon && !element.Clear">{{ element.Icon }}</mat-icon>
        <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
        <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
        <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field>

      <!-- DATE -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="['datetime', 'date', 'time'] | multiSwitchCase : element.Type"
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style">
        <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [formControlName]="element.Name"
          [min]="element.MinValue ? element.MinValue : null"
          [max]="element.MaxValue ? element.MaxValue : null"/>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <!-- <mat-form-field [appearance]="appearance" *ngSwitchCase="['datetime', 'date', 'time'] | multiSwitchCase: element.Type" 
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style">
        <mat-label>{{element.Label ? (element.Label | translate) : (element.Name | translate)}}</mat-label>
        <input matInput [type]="element.Type === 'datetime' ? 'datetime-local' : element.Type!" [placeholder]="element.Placeholder ? (element.Placeholder | translate) : ''" [formControlName]="element.Name" 
        [min]="element.MinValue ? element.MinValue : null" [max]="element.MaxValue ? element.MaxValue : null">
        <mat-icon matSuffix *ngIf="element.Icon && !element.Clear">{{ element.Icon }}</mat-icon>
        <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
        <mat-hint *ngIf="element.Hint">{{element.Hint | translate}}</mat-hint>
        <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field> -->

      <!-- CHECKBOX -->
      <ng-container *ngSwitchCase="'checkbox'">
        <mat-checkbox [formControlName]="element.Name"
          [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''"
          [style]="element.Style" color="primary">
          {{ element.Label ? (element.Label | translate) : (element.Name | translate) }}
        </mat-checkbox>
        <mat-hint *ngIf="element.Hint" class="check-box-hint">{{ element.Hint | translate }}</mat-hint>
      </ng-container>

      <!-- RADIO -->
      <div class="radio" *ngSwitchCase="'radio'" [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style">
        <mat-label>
          {{ element.Label ? (element.Label | translate) + ':' : (element.Name | translate) + ':' }}
          <span *ngIf="element.Validators">*</span>
        </mat-label>
        <mat-hint *ngIf="element.Hint" class="radio-box-hint">{{ element.Hint | translate }}</mat-hint>
        <mat-radio-group [required]="element.Validators" [formControlName]="element.Name">
          <mat-radio-button *ngFor="let option of element.Options" [value]="option">
            {{ option | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- GEO-LOCATION -->
      <ng-container *ngSwitchCase="'geolocation'">
        <mat-form-field [appearance]="appearance" [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style">
          <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
          <input
            matInput
            [placeholder]="element.Placeholder ? (element.Placeholder | translate) : ''"
            [formControlName]="element.Name"
            [attr.autocomplete]="element.AutoFill === false ? 'off' : ''"
            [id]="element.AutoFill === false ? element.Name + '-' + form_Id : ''"
            (focus)="focusedElementId = element.Name"
            (blur)="focusedElementId = undefined"/>
          <mat-icon matSuffix *ngIf="element.Icon && !element.Clear">{{ element.Icon }}</mat-icon>
          <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
          <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
          <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
        </mat-form-field>
        <geo-location
          *ngIf="element.Value && element.Value.toString().length > 15"
          address="{{ element.Value }}" width="100%" height="150px"
        ></geo-location>
      </ng-container>

      <!-- COLOR PICKER -->
      <mat-form-field [appearance]="appearance" *ngSwitchCase="'color-picker'"
        [ngClass]="formDisabled || element.Disabled ? 'disabled' : ''" [style]="element.Style">
        <mat-label>{{ element.Label ? (element.Label | translate) : (element.Name | translate) }}</mat-label>
        <input
          matInput
          type="color"
          [placeholder]="element.Placeholder ? (element.Placeholder | translate) : ''"
          [formControlName]="element.Name"
          [attr.autocomplete]="element.AutoFill === false ? 'off' : ''"
          [id]="element.AutoFill === false ? element.Name + '--' + form_Id : ''"
          (focus)="focusedElementId = element.Name"/>
        <mat-icon matSuffix *ngIf="element.Icon && !element.Clear">{{ element.Icon }}</mat-icon>
        <mat-icon matSuffix *ngIf="element.Clear" class="icon-clear" (click)="clearField(element.Name)">backspace</mat-icon>
        <mat-hint *ngIf="element.Hint && focusedElementId === element.Name" class="fade-in">{{ element.Hint | translate }}</mat-hint>
        <mat-error><error-handler [group]="formGroup" [control]="element.Name"></error-handler></mat-error>
      </mat-form-field>

      <!-- TITLE -->
      <h3 *ngSwitchCase="'title'" class="title" [style]="element.Style">
        <span *ngIf="element.Prefix">{{ element.Prefix }}&nbsp;</span>
        {{ element.Label ? (element.Label | translate) : (element.Name | translate) }}
        <span *ngIf="element.Suffix">{{ element.Suffix }}</span>
      </h3>

      <!-- HTML Element -->
      <div *ngSwitchCase="'html'" class="html" [innerHTML]="element.Name" [style]="element.Style"></div>

      <!-- SPACE -->
      <div *ngSwitchCase="'space'" class="space" [style]="element.Style"></div>

      <!-- DIVIDER -->
      <mat-divider *ngSwitchCase="'divider'" [style]="element.Style"></mat-divider>
      
      </ng-container>
    </ng-container>
  </ng-container>
  <button type="submit" hidden></button>
</form>
