import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  public transform(value: any, searchValue: string, replaceValue: string): string {
    if (!value || !searchValue || !replaceValue) {
      return value;
    }

    // eslint-disable-next-line no-param-reassign
    searchValue = searchValue.replace(/([{}])/g, '\\$1');

    return value.toString().replace(new RegExp(searchValue, 'gi'), replaceValue);
  }
}
