import { CloudinaryWidgetService } from '@app/core/extensions/cloudinary/cloudinary-widget';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GetMedia, DeleteMedia, UploadMediaReorder } from './store/media-upload.actions';
import { CloudinaryConfig } from '@extensions/cloudinary/cloudinary-config';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IMediaUpload } from './interface/media-upload.interface';
import { UsersState } from '../account/store-user/users.state';
import { MediaUploadState } from './store/media-upload.state';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { IMedia } from '../account/models/media.interface';
import { IUser } from '@app/shared/models/user.interface';
import { Select, Store } from '@ngxs/store';
import {
  CloudinaryResourceType,
  IUploadStatus,
  MediaResourceType,
} from '@app/core/extensions/cloudinary/cloudinary-response.interface';

@Component({
  selector: 'media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
})
export class MediaUploadComponent implements OnInit, OnDestroy {
  @Input() config: IMediaUpload = {} as IMediaUpload;
  @Input() touch: boolean | undefined;
  @Input() disabled: boolean = false;

  @Output() upload: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() validation: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Select(MediaUploadState.uploadedMedia) uploadedMedia$!: Observable<IMedia>;
  @Select(MediaUploadState.status) uploadStatus$!: Observable<IUploadStatus>;
  @Select(MediaUploadState.removedMedia) removedMedia$!: Observable<IMedia>;
  @Select(MediaUploadState.mediaList) mediaList$!: Observable<IMedia[]>;

  constructor(private _store: Store, private _widget: CloudinaryWidgetService) {}
  private destroy$: Subject<void> = new Subject<void>();

  public media: IMedia[] = [];
  public uploadId: string = crypto.randomUUID();
  public selectedMedia: IMedia | undefined;
  public fullScreen: boolean = false;
  public deleteConfirm: boolean = false;
  public mediaReorder: boolean = false;
  public valid: boolean = false;
  public mediaType: CloudinaryResourceType = 'image';

  public ngOnInit(): void {
    if (this.config) {
      this.mediaType = this.config.Type === MediaResourceType.Video ? 'video' : 'image';
      this.config.Cropping = this.config.Cropping ? this.config.Cropping : CloudinaryConfig.cropping;
      this.config.MaxFiles = this.config.MaxFiles ? this.config.MaxFiles : CloudinaryConfig.maxFiles;
      this.config.MinFiles = this.config.MinFiles ? this.config.MinFiles : 0;
      this.valid = this.config.MinFiles > 0 ? false : true;

      this.mediaList$.pipe(take(2)).subscribe((media: IMedia[]) => {
        if (media?.length > 0) {
          this.media = media
            .filter((x: IMedia) => x.Type === this.config.Type && x.Section === this.config.Section)
            .sort((a: IMedia, b: IMedia) => {
              const orderA: number = a.Order || Number.MAX_SAFE_INTEGER * -1;
              const orderB: number = b.Order || Number.MAX_SAFE_INTEGER * -1;

              return orderA - orderB;
            });
          this.mainMedia();
        } else {
          const user: IUser | null = this._store.selectSnapshot(UsersState.selectUser);

          if (user) {
            this._store.dispatch(new GetMedia(user.Id));
          }
        }
      });

      this.uploadedMedia$.pipe(takeUntil(this.destroy$)).subscribe((x: IMedia) => {
        if (this.uploadId === this._store.selectSnapshot(MediaUploadState.uploadId)) {
          if (this.config.MinFiles && this.config.MaxFiles) {
            const valid: boolean =
              this.config.MinFiles && this.config.MinFiles && this.media.length <= this.config.MaxFiles ? true : false;

            if (valid !== this.valid) {
              this.valid = valid;
              this.validation.emit(this.valid);
            }
          }
          this.media.push(x);
          this.mainMedia();
        }
      });

      this.uploadStatus$.pipe(takeUntil(this.destroy$)).subscribe((x: IUploadStatus) => {
        if (this.uploadId === this._store.selectSnapshot(MediaUploadState.uploadId)) {
          if (x === IUploadStatus.Finish) {
            const urls: string[] = [];
            this.media.forEach((x: IMedia) => {
              urls.push(x.Url);
            });
            this.upload.emit(urls);
          } else if (x === IUploadStatus.Ready) {
            this.touch = this.touch === undefined ? false : true;

            if (
              this.touch === false &&
              this.config.MinFiles &&
              this.config.MinFiles > 0 &&
              this.media.length < this.config.MinFiles
            ) {
              this.touch = true;
            }
          }
        }
      });
    } else console.error('Media upload has no config!');
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public showWidget(): void {
    if (this.config.MaxFiles) {
      const cropping: boolean = this.config.Cropping ? this.config.Cropping : CloudinaryConfig.cropping;
      const maxFiles: number = this.config.MaxFiles - this.media.length;
      const multiple: boolean = this.config.MaxFiles > 1 ? true : false;

      if (
        maxFiles !== this._widget.maxFiles ||
        this._store.selectSnapshot(MediaUploadState.uploadId) !== this.uploadId
      ) {
        this._widget.loadWidget(true, this.mediaType, this.uploadId, this.config.Section, multiple, cropping, maxFiles);
      } else {
        this._widget.openWidget(this.uploadId, this.config.Section);
      }
    }
  }

  public deleteMedia(): void {
    if (this.selectedMedia) {
      this._store.dispatch(new DeleteMedia(this.selectedMedia));
      this.media = this.media.filter((image: IMedia) => {
        return image !== this.selectedMedia;
      });
      this.mainMedia();
      this.deleteConfirm = false;
    }
  }

  public dropMedia(event: CdkDragDrop<string[]>): void {
    this.mediaReorder = true;
    moveItemInArray(this.media, event.previousIndex, event.currentIndex);
  }

  public mainMedia(): void {
    if (this.config.MainImage && this.media.length) {
      this.selectedMedia = this.media[0];
    } else this.selectedMedia = undefined;
  }

  public saveReordering(): void {
    this.mediaReorder = false;
    this._store.dispatch(new UploadMediaReorder(this.media));
  }
}
