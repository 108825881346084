import { IToken } from '@app/shared/components/account/store-auth/auth.state';
import { EnvironmentService } from './environment.service';
import { IUser } from '@app/shared/models/user.interface';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class SecurityService {
  private static tokenLoc: string = 'token';
 
  public static getToken(): IToken | null {
    const auth: string | null = localStorage.getItem(SecurityService.tokenLoc);

    if (auth) {
      const token: IToken = JSON.parse(atob(auth)) as IToken;
      const valid: string | null = token.token ? CryptoJS.MD5(token.token).toString() : null;
      if(token.valid === valid){
        return token;
      }
      else {
        localStorage.clear();
        return null;
      }
    }
    return null;
  }

  public static getUserFromToken(): IUser | null {
    const token: IToken | null = SecurityService.getToken();
    if(token){
      const prefix: string = EnvironmentService.getProp('token|prefix');
      const suffix: string = EnvironmentService.getProp('token|suffix');
      const middle: string = EnvironmentService.getProp('token|middle');

      const userToken: string = token.token!.replace(prefix, '').replace(suffix, '').replace(middle, '');
      try{
        const user = JSON.parse(atob(userToken)) as unknown as IUser;
        return user;
      }
      catch {
        localStorage.clear();
        return null;
      }
    }
    else return null;
  }

  public static setTokenFromUser(user: IUser): IToken {
    const prefix: string = EnvironmentService.getProp('token|prefix');
    const suffix: string = EnvironmentService.getProp('token|suffix');
    const middle: string = EnvironmentService.getProp('token|middle');

    const token: string = `${btoa(JSON.stringify(user))}`;
    const processedToken = `${prefix}${this.insert(token, middle, 10)}${suffix}`;

    const auth: IToken = {
      token: processedToken,
      valid: CryptoJS.MD5(processedToken).toString(),
    }

    localStorage.setItem(SecurityService.tokenLoc, btoa(JSON.stringify(auth)));
    return auth;
  }

  private static insert(text: string, inject: string, index: number) {
    const bagBegin: string = text.substring(0,index);
    const bagEnd: string = text.substring(index);
    return bagBegin + inject + bagEnd;
  }
}