<div
  *ngIf="(enabled$ | async) && ((!showChat && chatMode < 3) || (!showChat && isConnectionSecure && config?.Enabled))"
  class="start-chat fade-in"
  [style.background-color]="theme?.ChatButtonBg"
  [style.border-color]="theme?.ChatButtonBorder"
  (mouseenter)="preload()"
  (click)="startConversation()"
>
  <mat-icon [style.color]="theme?.ChatButtonIcon">message</mat-icon>
</div>

<main class="slide-in-fwd-bottom" *ngIf="showChat && support && config?.Enabled">
  <div class="center">
    <div
      class="chat"
      [style.background-color]="theme?.HeaderFooterBg"
      [style.border]="theme?.ChatBorder ? '1px solid' : ''"
      [style.border-radius]="theme?.ChatBorderRadius ? theme?.ChatBorderRadius + 'px' : defaultChatBorderRadius + 'px'"
      [style.border-color]="theme?.ChatBorder"
    >
      <div class="contact bar">
        <img *ngIf="config?.CompanyLogo" [src]="config?.CompanyLogo" class="pic fit" />
        <ng-container *ngIf="!config?.CompanyLogo">
          <img *ngIf="config?.BotAvatar" [src]="config?.BotAvatar" class="pic" />
          <img *ngIf="!config?.BotAvatar" src="../../../../assets/avatars/{{ support.Image }}" class="pic" />
        </ng-container>

        <div class="name" [style.color]="theme?.HeaderText">
          <span *ngIf="!config?.BotName">{{ 'Chat_TechnicalSupport' | translate }}</span>
          <span *ngIf="config?.BotName">{{ config?.BotName }}</span>
        </div>
        <div class="seen" *ngIf="support.Started" [style.color]="theme?.HeaderSubText">
          {{ support.Started | date : _units.dateFormat }}
        </div>
        <div class="close" (click)="closeConversation()">
          <mat-icon [style.color]="theme?.CloseButton">cancel</mat-icon>
        </div>
      </div>

      <div class="progress-bar" *ngIf="showProgress && support.Usage" [style.background-color]="theme?.ProgressBarBg">
        <span class="progress" [style.width]="support.Usage + '%'" [style.background-color]="theme?.Progress"></span>
      </div>

      <div
        #messages
        id="chat"
        class="messages"
        [style.background-color]="theme?.MessagesBg"
        [style.border]="theme?.MessagesBorder ? '1px solid ' + theme?.MessagesBorder : 'none'"
      >
        <div class="scale-in-center-delay" *ngFor="let msg of support.Messages">
          <div
            class="message user"
            *ngIf="msg.role === 'user'"
            [style.background-color]="theme?.MessageUserBg"
            [style.color]="theme?.MessageUserText"
            [style.border-color]="theme?.MessageUserBorder"
            [innerHTML]="msg.content | emoji"
          ></div>
          <div
            class="message support"
            *ngIf="msg.role === 'assistant'"
            [style.background-color]="theme?.MessageAIBg"
            [style.color]="theme?.MessageAIText"
            [style.border-color]="theme?.MessageAIBorder"
            [innerHTML]="msg.content | emoji"
          ></div>
          <div
            class="intro"
            *ngIf="msg.role === 'break'"
            [style.background-color]="theme?.MessageSeparatorBg"
            [style.color]="theme?.MessageSeparatorText"
          >
            <span *ngIf="msg.content === support.Started">{{ 'Chat_SupportIntro' | translate }}</span>
            <span *ngIf="msg.content !== support.Started">{{ msg.content | date : _units.dateFormat }}</span>
          </div>
        </div>

        <div
          class="message support fade-in"
          *ngIf="typing"
          [style.background-color]="theme?.MessageAIBg"
          [style.color]="theme?.MessageAIText"
          [style.border-color]="theme?.MessageAIBorder"
        >
          <div class="typing typing-1" [style.background-color]="theme?.MessageAITypingDots"></div>
          <div class="typing typing-2" [style.background-color]="theme?.MessageAITypingDots"></div>
          <div class="typing typing-3" [style.background-color]="theme?.MessageAITypingDots"></div>
        </div>
      </div>
      <div class="input">
        <mat-icon [style.color]="theme?.FooterIcons" (click)="prompt.focus()">mail</mat-icon>
        <input
          #prompt
          type="text"
          [maxlength]="limitations.maxLengthUser"
          [placeholder]="!typing ? ('Chat_EnterYourMessage' | translate) : ('PleaseWait' | translate)"
          [disabled]="typing"
          [style.background-color]="theme?.InputBg"
          [style.color]="theme?.InputText"
          [style.--placeholder-color]="theme?.InputPlaceholder"
          [(ngModel)]="promptText"
          (keyup.enter)="sendMessage()"
        />
        <mat-icon [style.color]="theme?.FooterIcons" (click)="sendMessage()">send</mat-icon>
      </div>
    </div>
  </div>
</main>
