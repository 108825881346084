import { IRouteConfig } from '../../shared/models/route-config.interface';

export const RouteConfig: IRouteConfig = {
  es: {
    Public: {
      Blank: 'chat',
      Login: 'acceso',
      Home: 'casa',
      NotFound: 'extraviado',
      Subscription: 'suscripción',
      Contact: 'contacto',
      Demo: 'demo',
      SocialNetworks: 'redes-sociales'
    },
    Private: {
      BackOffice: 'centro-de-usuario',
      HeadOffice: 'el-centro-principal',
      Profile: 'perfil'
    },
  },

  en: {
    Public: {
      Blank: 'chat',
      Login: 'login',
      Home: 'home',
      NotFound: 'not-found',
      Subscription: 'subscription',
      Contact: 'contact',
      Demo: 'demo',
      SocialNetworks: 'social-networks'
    },
    Private: {
      BackOffice: 'back-office',
      HeadOffice: 'head-office',
      Profile: 'profile'
    },
  },

  sr: {
    Public: {
      Blank: 'cet',
      Login: 'prijava',
      Home: 'pocetna',
      NotFound: 'nije-pronadjeno',
      Subscription: 'predplata',
      Contact: 'kontakt',
      Demo: 'demo',
      SocialNetworks: 'socijalne-mreze'
    },
    Private: {
      BackOffice: 'korisnicki-centar',
      HeadOffice: 'glavni-centar',
      Profile: 'profil'
    },
  },
};
