import { DeleteMedia, GetMedia, UploadId, UploadMedia, UploadMediaReorder, UploadStatus } from './media-upload.actions';
import { IUploadStatus } from '@app/core/extensions/cloudinary/cloudinary-response.interface';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ApiMediaService } from '../../account/api/api.images.account.service';
import { SnackService } from '@app/shared/services/snack.service';
import { IMedia } from '../../account/models/media.interface';
import { concatMap, from, map, take } from 'rxjs';
import { Injectable } from '@angular/core';

export class MediaUploadStateModel {
  uploadId: string | undefined; // UploadId for current upload
  media: IMedia = {} as IMedia; // Last uploaded image
  status: IUploadStatus = IUploadStatus.Ready; // Widget upload status
  removedMedia: IMedia | undefined; // Image that need to be removed
  mediaList: IMedia[] = [];
}

@State<MediaUploadStateModel>({
  name: 'mediaUploadState',
  defaults: {
    media: {} as IMedia,
    status: IUploadStatus.Ready,
    uploadId: undefined,
    removedMedia: undefined,
    mediaList: [],
  },
})
@Injectable()
export class MediaUploadState {
  constructor(private _store: Store, private _mediaApi: ApiMediaService, private _snack: SnackService) {}

  @Selector()
  public static mediaList(state: MediaUploadStateModel): IMedia[] | undefined {
    return state.mediaList;
  }

  @Selector()
  public static uploadId(state: MediaUploadStateModel): string | undefined {
    return state.uploadId;
  }

  @Selector()
  public static status(state: MediaUploadStateModel): IUploadStatus {
    return state.status;
  }

  @Selector()
  public static uploadedMedia(state: MediaUploadStateModel): IMedia {
    return state.media;
  }

  @Selector()
  public static removedMedia(state: MediaUploadStateModel): IMedia | undefined {
    return state.removedMedia;
  }

  @Action(GetMedia)
  public getMediaList(ctx: StateContext<MediaUploadStateModel>, { userId }: GetMedia): void {
    const images: IMedia[] | null = this.getSessionMediaList();

    if (images) {
      ctx.patchState({ mediaList: images });
    } else {
      this._mediaApi
        .getMediaList(userId)
        .pipe(take(1))
        .subscribe((x: IMedia[] | null) => {
          if (x) {
            ctx.patchState({ mediaList: x });
            this.setSessionMedia(x);
          } else ctx.patchState({ status: IUploadStatus.Ready });
        });
    }
  }

  @Action(UploadId)
  public uploadId(ctx: StateContext<MediaUploadStateModel>, { uploadId }: UploadId): void {
    ctx.patchState({ uploadId: uploadId });
  }

  @Action(UploadStatus)
  public uploadStatus(ctx: StateContext<MediaUploadStateModel>, { status }: UploadStatus): void {
    ctx.patchState({ status: status });
  }

  @Action(UploadMedia)
  public uploadMedia(ctx: StateContext<MediaUploadStateModel>, { media: image }: UploadMedia): void {
    this._mediaApi
      .uploadMedia(image)
      .pipe(take(1))
      .subscribe((x: boolean) => {
        if (x) {
          ctx.patchState({ media: image });
          let sessionImages: IMedia[] | undefined = this._store.selectSnapshot(MediaUploadState.mediaList);

          if (sessionImages) {
            sessionImages.push(image);
          } else {
            sessionImages = [];
            sessionImages.push(image);
          }

          ctx.patchState({ mediaList: sessionImages });
          this.setSessionMedia(sessionImages);
        } else {
          this._snack.error('ImageUploadFailed');
          ctx.patchState({ status: IUploadStatus.Ready });
        }
      });
  }

  @Action(UploadMediaReorder)
  public uploadMediaReorder(ctx: StateContext<MediaUploadStateModel>, { reorderMedia }: UploadMediaReorder): void {
    const allMedia: IMedia[] | undefined = this._store.selectSnapshot(MediaUploadState.mediaList);

    if (allMedia) {
      for (let i: number = 0; i < reorderMedia.length; i++) {
        reorderMedia[i].Order = i;

        const orgMediaIndex: number = allMedia.findIndex((x: IMedia) => x.Id === reorderMedia[i].Id);
        allMedia[orgMediaIndex].Order = i;
      }

      ctx.patchState({ mediaList: allMedia });
      this.setSessionMedia(allMedia);

      from(reorderMedia)
        .pipe(
          map((img: IMedia, index: number) => ({ img, index })),
          concatMap(({ img, index }: { img: IMedia; index: number }) =>
            this._mediaApi.updateMediaOrder(img, index).pipe(map((response: boolean) => ({ response, index }))),
          ),
        )
        .subscribe();
    }
  }

  @Action(DeleteMedia)
  public deleteMedia(ctx: StateContext<MediaUploadStateModel>, { media: image }: DeleteMedia): void {
    this._mediaApi
      .deleteMedia(image.Id)
      .pipe(take(1))
      .subscribe((deleted: boolean) => {
        if (deleted) {
          ctx.patchState({ removedMedia: image });

          let sessionMedia: IMedia[] | undefined = this._store.selectSnapshot(MediaUploadState.mediaList);

          if (sessionMedia) {
            sessionMedia = sessionMedia.filter((x: IMedia) => x.Id !== image.Id);
            ctx.patchState({ mediaList: sessionMedia });
            this.setSessionMedia(sessionMedia);
          }
        } else {
          this._snack.error('MediaUnsuccessfullyDeleted');
        }
      });
  }

  private setSessionMedia(media: IMedia[]): void {
    sessionStorage.setItem('MediaList', JSON.stringify(media));
  }

  private getSessionMediaList(): IMedia[] | null {
    const session: string | null = sessionStorage.getItem('MediaList');

    return session ? (JSON.parse(session) as IMedia[]) : null;
  }
}
