import { ISubscription } from '../interfaces/subscription.interface';

export class GetSubscriptionPackage {
  public static readonly type: string = '[GetSubscriptionPackage] Get user subscription';
  constructor(public userId: string) {}
}

export class GetSubDomainSubscription {
  public static readonly type: string = '[GetSubDomainSubscription] Get is SubDomain subscription';
  constructor(public subDomain: string) {}
}

export class AddSubscription {
  public static readonly type: string = '[AddSubscription] User bay subscription package';
  constructor(public subscription: ISubscription) {}
}

export class UpdateSubscription {
  public static readonly type: string = '[UpdateSubscription] User subscription updated';
  constructor(public subscription: ISubscription) {}
}
